import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios"



const Myprofile = () => {
  const initialFormData = {
    userName: "",
    gender: "",
    email: "",
    mobileNumber: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

//!Tokens and Headers
const user = JSON.parse(sessionStorage.getItem("user"));
const headers = {
  "Content-type": "application/json",
  Authorization: "Bearer " + user.accessToken,
};



const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  // Define the API endpoint URL
  const apiUrl = "https://virtullearning.cloudjiffy.net/ecommercecustomer/login/v1/queryMobileUserByUserName/8904000887";

  // Use Axios to fetch data from the API
  axios
    .get(apiUrl, { headers })
    .then((response) => {
      // Extract the user data from the API response
      const userData = response.data;

      // Update the form data with the retrieved user data
      setFormData({
        userName: userData.userName,
        gender: userData.gender,
        email: userData.email,
        mobileNumber: userData.mobileNumber,
      });

      setIsLoading(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      // Handle any errors here
      console.error("Error fetching user data:", error);
      setIsLoading(false); // Set loading to false in case of an error
    });
}, []); // Empty dependency array ensures this runs once when the component mounts


  const handleChange = (e) => {
    const { name, value } = e.target;
    // Clear the error for the input field when it becomes valid
    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate name
    if (!formData.userName.trim()) {
      newErrors.userName = "Name is required";
    }

    // Validate gender
    if (!formData.gender) {
      newErrors.gender = "Gender is required";
    }

    // Validate email
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    }

    // Validate mobile number
    if (!formData.mobileNumber.trim()) {
      newErrors.mobileNumber = "Mobile number is required";
    }

    // If there are validation errors, update the state and don't submit the form
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Submit the form or perform further actions here
      console.log("Form submitted:", formData);
      // Clear the form and errors after submission
      setFormData(initialFormData);
      setErrors({});
    }
  };

  

  return (
    <Row>
      <Col sm={8}>
      {isLoading ? (<p>Loading...</p>) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Personal Information</Form.Label>
            <Form.Control
              type="text"
              name="userName"
              placeholder="Enter full name"
              value={formData.userName}
              onChange={handleChange}
              isInvalid={!!errors.userName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.userName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicGender">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              name="gender"
              aria-label="Default select example"
              value={formData.gender}
              onChange={handleChange}
              isInvalid={!!errors.gender}
            >
              <option>Open this select menu</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.gender}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicMobile">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="number"
              name="mobileNumber"
              placeholder="Enter Number"
              value={formData.mobileNumber}
              onChange={handleChange}
              isInvalid={!!errors.mobileNumber}
            />
            <Form.Control.Feedback type="invalid">
              {errors.mobileNumber}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      )}
      </Col>
      <Col sm={4}>
        {/* "Edit" button */}
        <Button variant="secondary">Edit</Button>
      </Col>
    </Row>
  );
};

export default Myprofile;
