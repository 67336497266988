import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import logo from "../images/ASHVA_LOGO_HEADER.png";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import './Signup.css';




const Signup = () => {
  
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    otp: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputFocus = (inputName) => {
    setErrors({
      ...errors,
      [inputName]: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear validation error for the corresponding input field
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const generateOTP = () => {
    const randomOTP = Math.floor(1000 + Math.random() * 9000);
    setFormData({
      ...formData,
      otp: randomOTP.toString(),
    });
  };

  const nav = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate(formData);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      // submit the form data to the server
      console.log(formData);
      setFormData({username: "",
      email: "",
      password: "",
      otp: "",})
    }
  };

  const senddata = (e) => {
    e.preventDefault();
    nav("/login");
  };

  const validate = (data) => {
    let errors = {};

    if (!data.username) {
      errors.username = "Username is required";
    }

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Invalid email address";
    }

    if (!data.password) {
      errors.password = "Password is required";
    } else if (data.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    if (!data.otp) {
      errors.otp = "OTP is required";
    } else if (data.otp.length !== 4 || !/^\d+$/.test(data.otp)) {
      errors.otp = "Invalid OTP";
    }

    return errors;
  };

  return (
    <React.Fragment>

    
    {/* ##################### navbar #################### */}
    <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/about">About Us</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/products">Products</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/plan">Plans</Link>
              </Nav.Link>
              {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
              <Nav.Link>
                <Link to="/contact">Contact Us</Link>
              </Nav.Link>
            </Nav>
           
          </Navbar.Collapse>
        </Container>
      </Navbar>

{/* ##################### Signup #################### */}
      {/* <div className="container mt-2">
      <div className="row">
        <div className="col-md-6 offset-md-3">
        <div className="border border-3 border-primary"></div>
          <div className="card">
            <div className="card-header">
              <h3>Ashva Expert</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    className={`form-control ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className={`form-control ${
                      errors.confirmPassword ? "is-invalid" : ""
                    }`}
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword && (
                    <div className="invalid-feedback">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
              <div className="mt-3">
                      <p className="mb-0  text-center">
                        Already have an account??{' '}
                        <a href="{''}" className="text-primary fw-bold" onClick={senddata}>
                          Sign In
                        </a>
                      </p>
                    </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    <div className="container mt-2" style={{ backgroundColor: "#E8EAF6",width: "100%" }}>
        <div className="row">
          <div className="col-md-6 offset-md-3">
          <div className="border border-3 border-primary"></div>
            <div className="card">
              <div className="card-header">
                <h3>Ashva Expert</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="username">Mobile Number</label>
                    <input
                      type="text"
                      name="username"
                      className={`form-control ${
                        errors.username ? "is-invalid" : ""
                      }`}
                      value={formData.username}
                      onChange={handleChange}
                      onFocus={() => handleInputFocus("username")}
                    />
                    {errors.username && (
                      <div className="invalid-feedback">{errors.username}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      value={formData.email}
                      onChange={handleChange}
                      onFocus={() => handleInputFocus("email")}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      value={formData.password}
                      onChange={handleChange}
                      onFocus={() => handleInputFocus("password")}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="otp">OTP</label>
                    <div className="otp-field">
                      <input
                        type="text"
                        name="otp"
                        className={`form-control ${
                          errors.otp ? "is-invalid" : ""
                        }`}
                        value={formData.otp}
                        onChange={handleChange}
                        onFocus={() => handleInputFocus("otp")}
                      />
                    </div>
                    {errors.otp && (
                      <div className="invalid-feedback">{errors.otp}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    {/*- ############ Footer Starts Here ################## */}

<footer>
        <div>
          <a href="tel:++91 8884567518" class="callme_float">
            <FaPhone />
          </a>
          <a
            href="https://wa.me/918495998156"
            class="message_float"
            target="_blank"
          >
            <FaWhatsapp />
          </a>
        </div>
        <div
          className="inner "
          style={{ backgroundColor: "#1E88E5", color: "#000000" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Contact Details
                </h6>
                <div>
                  <ul style={{ textAlign: "center" }}>
                    <li>220, 6TH CROSS, GANAKALLU,</li>
                    <li>SRINIVASAPURA COLONY KENGERI, HOBLI,</li>
                    <li>BANGALORE, KARNATAKA, INDIA -560060.</li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Phone</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>87687587588</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Email</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>
                      ASHVAEXPERTS.TEAM@GMAIL.COM
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Office timings
                </h6>
                <div>
                  <ul className="row">
                    <li style={{ textAlign: "center" }}>
                      OPEN 9AM-9PM:MONDAY - SATURDAY
                    </li>

                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-facebook" />
                    </li>
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-twitter" />
                    </li>
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-instagram" />
                    </li>
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-linkedin" />
                    </li>
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-youtube" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy">
        <div className="container" style={{ textAlign: "center" }}>
          <a
            href="https://www.walkinsoftwares.com/"
            style={{ textDecorationLine: "none" }}
          >
            2022 © All Rights Reserved | Designed and Developed by Walkin
            Software Technologies
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signup;
