import React from 'react';
import {
    Navbar,
    Nav,
    NavDropdown,
    Container,
    Button,
    Card,
    Table,
    Image,
    Col,
    Row,
  } from "react-bootstrap";
  import { Link, useNavigate } from "react-router-dom";
  import logo from "../images/ASHVA_LOGO_HEADER.png";
  import {
    FaFacebook,
    FaTwitter,
    FaGoogle,
    FaInstagram,
    FaLinkedin,
    FaYoutube,
  } from "react-icons/fa";
  import { FaPhone, FaWhatsapp, FaRegWindowClose } from "react-icons/fa";

const Terms = () => {
    const googlePlayUrl = "https://play.google.com/store/apps";

  const nav = useNavigate();

  const login = (e) => {
    e.preventDefault();
    nav("/login");
  };

  return (
    <>
    <Navbar bg="light" expand="lg">
    <Container>
      <Navbar.Brand href="#">
        <img src={logo} alt="" width="200px" height="100px" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link>
            <Link to="/">Home</Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/about">About Us</Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/products">Products</Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/plan">Plans</Link>
          </Nav.Link>
          {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
          <Nav.Link>
            <Link to="/contact">Contact Us</Link>
          </Nav.Link>
          <Nav.Link href={googlePlayUrl}>Google Playstore</Nav.Link>
        </Nav>
        
      </Navbar.Collapse>
    </Container>
  </Navbar>
  <div style={{ backgroundColor: "#EBE3D5" }}>
    <p>
      <h4 style={{ color: "blueviolet", textAlign: "center" }}>
        TERMS AND CONDITIONS
      </h4>
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>1.DEFINITIONS:</h6>
    <p>
      Certain words in this Subscription Contract have a specific meaning
      and are explained below:
      <br />
      a. Account Balance: the current available amount in Customer Account.
      <br />
      b. Activation: means activation of the Service under the terms of this
      contract, after Installation of the Unit including provision for
      service for upgrade at the Address.
      <br />
      c. The balance units left in the Customer Account carry forward onto
      the next month only upon subsequent subscription renewal of limited
      plans. However, such carry forward of balance units of water can only
      occur once after the initial subscription fee is paid. The maximum
      upper limit for carrying forward the balance units left in the
      Customer Account shall be 5,000 litres in all limited plans in a year.
      We may change the maximum upper limit without any prior intimation.
      <br />
      d. Grace Period: the period starting from the notified due date i.e.
      the date on which Customer Account is due for recharge until the date
      the service of customer is deactivated for failure to maintain the
      Minimum Account Balance.
      <br />
      e. Helpline:Ashva Experts Call Centre.
      <br />
      f. Installation: set-up of the Unit at the Address.
      <br />
      g. Minimum Account Balance: the Subscription Fee required to cover at
      least 1 day of subscription of the costumer chosen Service (in case of
      monthly subscriptions) or total value of the customer chosen Service
      (in case of Long Duration Subscriptions or showcase events)
      <br />
      h. Package: any package for providing specific units of Water which
      may be offered byAshva Experts , and selected by Customer from time to
      time as part of the Service.
      <br />
      i. Premises: Address provided by Customer for the Installation of the
      Unit.
      <br />
      j. Registration: by paying registration fee for subscription on Ashva
      Experts website or any other mode authorised by Ashva Experts .<br />
      k. Customer: shall mean a person (You) who agrees to these Terms and
      Conditions for availingAshva Experts Services but excludes Commercial
      Establishments and referred to as Customer, in this Contract and
      includes any person/persons who uses services under the Customer)
      <br />
      l. Customer Account: the personal subscription account of a customer
      withLivpure Smart for carrying out all transactions withAshva Experts
      .<br />
      m. Customer ID: the unique Customer identification code issued to
      customer for purposes of accessing Customer’s Account and availing of
      the Service at the specified Address.
      <br />
      n. Subscription Contract: this contract together with its Terms and
      Conditions stated on{" "}
      <span style={{ color: "orange" }}>www.ashvaexperts.com</span>and the
      Installation Work Order and Relocation Work Order as modified and
      amended byAshva Experts from time to time.
      <br />
      o. Subscription Fee: the amount payable by customer to receive the
      Service. p. RO/Unit/Product: Livpure RO Water Purifier. Model No.Ashva
      Experts.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>2. CONTRACT:</h6>
    <p>
      These terms and conditions mentioned below shall form the Subscription
      contract between the parties, i.e.Ashva Experts Private Limited and
      the Customer and shall be governing their mutual relationship and
      shall be binding on both parties, on their representatives, assigns,
      successors in interests of parties and shall also be equally
      applicable on all persons/entities who are using the services ofAshva
      Experts under the customers.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>3.DECLARATION:</h6>
    <p>
      By agreeing to these terms and conditions, the customer declares that:
      <br />
      a. He/she is an individual residing in India, above 18 years of age,
      fully competent under law to enter into the Contract or;
      <br />
      b. AAshva Experts /entity having its place of business in India,
      represented by an individual having appropriate authority to accept
      the Contract;
      <br />
      c. That all information provided toAshva Experts and KYC submitted are
      accurate, true and correct.;
      <br />
      d. Customer has subscribed the chosen plan after fully understanding
      all commitments, obligations and its suitability;
      <br />
      e. Before agreeing to the said terms and conditions, the customer has
      fully understood and accepted the present terms and conditions;
      <br />
      f. The customer has been notified that no salesperson or agent ofAshva
      Experts is authorised to change any term of this Agreement or to make
      any warranties or representations about it, oral or otherwise.
      <br />
      g. He/she acknowledges that the Agreement is not an offer byAshva
      Experts to sell the RO to Customer andAshva Experts are not obligated
      to accept any offer made.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>4.TERM:</h6>
    <p>
      The Contract will commence upon on the date of Online Payment
      (“Registration”) and acceptance of Terms and Conditions contained
      herein and, will remain valid, subject to applicable Terms and
      Conditions till the validity of the said plan and/or continued usage
      of the services ofAshva Experts under the subscribed plan, unless
      terminated prior in accordance of this contract.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>5. KYC:</h6>
    <p>
      KYC Documents which must include Valid Photo Identity Card &amp; Valid
      Address Proof and such other documents as may be called and/or
      required by Ashva Experts and/or mandated by law. The customer shall
      be eligible to take the equipment/product under the lease product
      offer/scheme provided by the Ashva Experts only once the KYC of the
      lessee/customer has been approved from the office of the Ashva Experts
      .Ashva Experts and/or its authorized agents shall be entitled to visit
      the customer and the scheduled premises for the purpose of
      verification. The KYC of the lessee/customer shall be reviewed by
      theAshva Experts within 5 working days.Ashva Experts will not be
      liable for any incorrect information and/or document provided by the
      Customer, whether obtained illegally, or by means of fraud or forgery,
      or otherwise. TheAshva Experts reserves the right to reject, in whole
      or in part, and without any liability whatsoever, any subscription
      offer, without assigning any reason, including for incomplete or
      incorrect information/documents.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>6. LIMITED ACCESS:</h6>
    <p>
      Livpure Smart grants the Customer, subject to payments, permission and
      limited access to RO and to have RO water, from the said RO for
      personal and non-commercial use, limited as per plan subscribed, in
      accordance with these terms and conditions. RO shall be commissioned
      at the address as shall be instructed by the Customer, which
      hereinafter shall be called and referred as ‘Scheduled Premises’.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>7.INCIDENTAL PROVISIONS:</h6>
    <p>
      The customer shall be responsible for arranging provisions incidental
      to said RO such as, but not limited to having continued, internet
      connections, uninterrupted raw water supply, electricity supply and
      drainage for waste water at its own costs, without any right of
      reimbursement fromAshva Experts .Ashva Experts will not be liable if
      any water and/or power supplies are faulty or incompatible and the
      loss or inability to use due to any defect and/or deficiency in raw
      water and power supply shall not absolve the Customer from the
      Customer’s liability to pay charges on account of the Service and/or
      the use of the Product. The Customer is solely responsible to ensure
      that the Customer has an internet connection to access and/or use the
      Product and Ashva Experts App.
    </p>
    <h6 style={{ color: "blueviolet" }}>8.INSTALLATION:</h6>
    <p>
      Livpure Smart or its affiliates shall install the Product at the
      location of the Customer’s premises (“Premises”) upon receipt of
      confirmation of the order for installation from the Customer. The
      Customer acknowledges that up to 7 business days may be required from
      the date of receipt of confirmation order for Engineer to install the
      said Unit at the Premises. Upon installation of the said Product, the
      7 (seven) day Free Trial shall commence and the Customer has the right
      to return the said Product within the Free Trial Period by
      notifyingAshva Experts through its registered email ID at the
      registered email address whereuponAshva Experts shall take custody of
      said Product without any hindrance or obstruction from the Customer
      and credit payment to Customer’s Account. The Customer is entitled to
      verify the identity of the agent/ engineer sent byAshva Experts for
      installation of the Unit at Premises. The Customer acknowledges that
      any dispute between Customer and 3rd party upon whose premises such
      services are availed, will not involveAshva Experts and the duty to
      ensure necessary permissions and dispute resolution belongs solely to
      Customer and the third party.Ashva Experts reserve the right to charge
      Customer and debit Customer’s Account, if the Engineer has visited and
      the Installation has not taken place because: (i) any necessary
      permission(s) /authorisation(s) have not been obtained or are
      withdrawn; or (ii) Customer or Customer’s Authorised Representative
      are not present at the appointed time; or (iii) Customer has failed to
      furnish a valid age and address photo identification as required; or
      (iv) of a lack of authorised third-party services and materials
      required for Installation; or (v) of any other force majeure event.
      Orders placed on Sunday and after working hours 9:00 AM to 7:00 PM
      will be considered under the next working day order.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>9. PLAN:</h6>
    <p>
      As the subscription plans of RO are based on monthly basis, with
      supply of specific units of water (in Litres) corresponding to the
      package purchased by the Customer upon registration, on &#39;renewal
      of subscription therefore the customer has to pay immediately either
      on the event of reaching the maximum consumption fixed or on the
      expiry of month term, whichever happened earlier. Also, when we talk
      about our UNLIMITED Plan, there is a limit of 999 litres of water
      usage. This limit is applied keeping in mind that if an individual
      drinks 4 litres of water a day, 999 litres of water per month is more
      than enough for a mid-sized family of eight people. In case a customer
      subscribes to holiday plan, the customer cannot use the water/units in
      this period These services shall continue/ be determined earlier upon
      recharge of said package or upon non-recharge respectively, as
      provided by the terms and conditions set forth herein. If the customer
      has an excess requirement of the water consumption, then the customer
      can recharge /top up the equipment/product as per the said requirement
      from the app of Ashva Experts . The liability of the consumer to pay
      the monthly fees as per the agreed and subscribed plan is absolute,
      notwithstanding that the customer was not using the said RO or the
      services were suspended due to failure of customer in recharging
      and/or topping up of the RO.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>10.CHANGE OF PLAN:</h6>
    <p>
      Livpure Smart provide the Customer to subscribe to any one ofAshva
      Experts service packages, for details of the service packages kindly
      refer toAshva Experts website <span>www.ashvaexperts.com.</span>{" "}
      Customers may migrate from a particular package to any other existing
      package offered byAshva Experts with a request in writing by way of
      registered email and thereupon registration to a new package, as per
      the relevant terms and conditions prescribed byAshva Experts from time
      to time. In order to change the Package, Customer must have a Minimum
      Account Balance and be authorised to receive the Service. The
      requested Package will be effective within 3 working days from the
      date of request. Customers will be liable to pay the applicable
      charges for the requested Package and such charges shall be effective
      from the date of migration to the said Package. Carry-forward of the
      balance units shall not be transferred in case customer shifts from
      limited plans to unlimited plans or vice versa. In this case, the
      balance of units shall be considered zero.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>
      11.PRICING, SECURITY &amp; LOCK-IN:
    </h6>
    <p>
      The Subscription Fee shall be non-refundable and in accordance with
      the subscription plans as displayed on the company website and may be
      changed from time to time, without any prior notice. TheAshva Experts
      reserves the right to change the prices, including increase the prices
      or modify the plan and offer in accordance with Applicable Law.
      Customers must visit theAshva Experts Website for the updated prices,
      tariff plans and offers. Customer may be called to maintain interest
      free security deposit (“Security Deposit&#39;&#39;), if required in
      accordance with the terms of the package or the offer that may be
      subscribed by the Customer.Ashva Experts reserves the right to charge
      a Security Deposit and make reasonable adjustments to the said
      Security Deposit in full or in part satisfaction of any sum due from
      the Customer to theAshva Experts at any time. The Security Deposit
      will be refundable subject to lock-in upon expiration or termination
      of the Subscription herein after adjustment of any damage or sum due
      from the Customer to Ashva Experts . There shall be a lock-in period
      of three months and Company reserves the right to modify such lock-in
      without prior intimation hence it is advisable to visit the website
      from time to time to avoid confusion. Further, the security deposit
      will not be refunded in case a customer leaves the platform before the
      lock-in.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}> 12.CUSTOMER ACCOUNT:</h6>
    <p>
      If the Customer has not utilised entire units of water per package,
      such balance units shall be carry forwarded (“Carry Forward”) unto the
      next month upon subsequent prepaid recharge. However, such carry
      forward of balance units of water can only occur once after the
      initial subscription fee is paid.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}> 13.PAYMENTS:</h6>
    <p>
      Livpure Smart reserves the right to charge the Customer additional
      amounts, including for relocation services, repair services, or such
      other offers, packages and additional services provided or associated
      with the Product. All payments, once paid, are not refundable unless
      the payment has been incorrectly drawn byAshva Experts , The customers
      are unconditionally bound to pay all amounts due under this Agreement
      for the minimum period of commitment in full without set-off, or
      counterclaim, and without any deduction in respect of taxes, unless
      prohibited by law or any account whatsoever. Without prejudice toAshva
      Experts right to deactivate and cancel the Service,Ashva Experts
      reserve the right to charge interest at 18% per annum or the maximum
      rate permitted by Law on any past / current due amounts. Customers
      shall be entitled to only those discounts, credits or refunds as
      expressly authorised byAshva Experts .Ashva Experts reserve the right
      to vary the prices for the Service from time to time. The Service will
      be made available to Customers during the Grace Period. Prior to the
      expiry of any applicable Grace Period, Customers will be required to
      maintain a Minimum Account Balance in Customer Account.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}> 14. TAXES:</h6>
    <p>
      Unless otherwise specified by theAshva Experts , the Customer is
      solely responsible and liable for payment of all taxes, duties, levies
      and charges imposed by or under any applicable law in connection with
      the Service including ROs or any associated services. Taxes collected
      by theAshva Experts from the Customer will not be refunded under any
      circumstances including cancellation or termination or deactivation of
      the Subscription for any reasons by the Ashva Experts or at a request
      of the Customer.
    </p>{" "}
    <br />
    <h6 style={{ color: "blueviolet" }}> 15. PAYMENT TERMS:</h6>
    <p>
      Livpure Smart never asks for any payments in Cash. The mode of payment
      shall be only digital and as available on the website. All payments
      shall be made through banking either by online transfers and/or
      credit- debit card and/or any other mode, in the designated account of
      theAshva Experts as shall be communicated on the registered mobile
      number of the customer and/or displayed/notified on the website of
      theAshva Experts , but never in cash. All Payments due to theAshva
      Experts will be debited through the Customer Account including without
      limitation Installation Fee, Activation Fee and Reactivation Fee.
      Monthly Subscription Fee towards the Selected Packages will be debited
      through the Customer Account on a monthly basis, unless otherwise
      informed by theAshva Experts . Any Month-Parts will be treated as a
      full Month for purposes of Subscription Fee. Customers must maintain a
      Minimum Account Balance to ensure uninterrupted services from theAshva
      Experts . All payments shall be made in full by the due date. Any
      delay in payment beyond the due date will result in suspension or
      disconnection of the RO service as deemed fit by theAshva Experts . If
      the Customer fails to recharge his account on time or his account
      balance turns negative for any other reason,Ashva Experts shall have
      the right to stop the Product to function as desired and shall also
      have the right to repossess the Product.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}> 16. OWNERSHIP:</h6>
    <p>
      RO installed at the scheduled premises is the property of theAshva
      Experts and is under the full, legal and perpetual ownership and
      effective control of theAshva Experts . RO has been installed by way
      of entrustment for the purpose of enabling customers to have RO Water
      and Services till the validity of subscription contract. No right
      whatsoever shall be deemed to have been transferred in the said RO and
      the said RO shall always remain the sole and exclusive property
      ofAshva Experts . The Customer must keep the Product safely and must
      not remove any marking that identifies the Product as belonging to
      theAshva Experts . Customers must not allow anyone else to tamper with
      or use the Product in any manner not specifically authorised by
      theAshva Experts . The Customer shall indemnify theAshva Experts
      against all loss and damage caused by such action against its lease
      Equipment/Product.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}> 17. CUSTOMER OBLIGATIONS:</h6>
    <p>
      By entering into this contract, the Customer is obligated;
      <br />
      a. To honour the payment schedule of Subscription Fee as per the plan
      subscribed offer and without any reference, hindrance, delay,
      deduction, set off, for any reason in any manner whatsoever and shall
      not be connected with the state of use of Product, or any claim for
      service, quality, etc.;
      <br />
      b. Not to sell, offer for sale, mortgage, pledge, sub-lease, create
      lien or any third party interest, or otherwise deal in any manner,
      with OR detrimental to the interest ofLivpure Smart , and shall always
      protect the Product, against distress, execution or seizure same being
      the property ofLivpure Smart ;<br />
      c. To take such care of the RO as a prudent person shall take care of
      his own property; to indemnifyLivpure Smart to the extent of written
      down value of the Product in case of loss or damage to the Product
      during the term of lease;
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}> 18. PROOF OF VIOLATION:</h6>
    <p>
      In the event thatLivpure Smart detect any violation of this
      Subscription Contract or any intellectual property rights utilising
      fingerprinting or any other mechanism or system deployed byLivpure
      Smart or by the authorised representatives ofLivpure Smart , then such
      fingerprinting or other mechanism or system shall be acceptable to
      Customer and any findings of contractual or intellectual property
      violations and / or violations of any other law/s through the use of
      fingerprinting or other method presented before the relevant authority
      shall be treated as conclusive proof of any such violation.
    </p>{" "}
    <br />
    <h6 style={{ color: "blueviolet" }}>19. USAGE:</h6>
    <p>
      Upon delivery of the RO, the customer must inspect it and satisfy
      itself that it is in good operating order and condition. The customer
      must rely on his/her/its own judgement as to the quality and condition
      of the Goods and its fitness and suitability for any particular
      purpose. The customer shall use the RO in strict compliance with all
      instructions manuals and any other reasonable instructions as may be
      provided from time to time byLivpure Smart . Customers must always
      maintain and keep the Product in good and usable condition (normal
      wear and tear excepted) until returned to or collected by theLivpure
      Smart .
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>20. INSPECTION:</h6>
    <p>
      Livpure Smart , without any prior notification and during reasonable
      hours permitted under the law any time during the Term of the
      Contract, may enter the scheduled premises and/or Address, or such
      other locations where the Product is installed and Services are
      availed by the Customer, to inspect and verify if the Customer is in
      compliance with the Contract. If customer fail to provide access
      toLivpure Smart and/or its authorised agents,Livpure Smart have the
      right, subject to compliance with any applicable law, to enter the
      premises, or authorise its agents to enter the premises, whereLivpure
      Smart believe RO is located in order to confirm its existence,
      condition and proper maintenance.Ashva Experts shall be entitled to
      take photographs and collect documents, materials, equipment or other
      information necessary as proof of any violation of the Contract.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>21. SERVICE &amp; MAINTENANCE:</h6>
    <p>
      Livpure Smart shall use reasonable endeavours to provide free of cost
      all routine maintenance and repair of the Product that may be required
      from time to time.Ashva Experts , however, cannot guarantee the same
      and there might be delay in the same.Ashva Experts shall not be liable
      to refund any amount to the Customer nor compensate the Customer for
      any such disruption of Services. The Quality, functionality, and/or
      availability of the Services may be affected and theAshva Experts ,
      without any liability whatsoever, is entitled to refuse, limit,
      suspend, modify or disconnect the Services or any Packages or any
      Offer (in part or whole) at any time, for any cause, subject to
      Applicable Law, including, but not limited to, the following: d) due
      to change in business e) any legal, commercial or other reason, which
      theAshva Experts finds as reasonable warranting a limitation/
      disconnection of the Services.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>22. REPAIR:</h6>
    <p>
      At any given point of time, theAshva Experts at its sole discretion,
      may replace and/or change and/or substitute and/or modify and/or the
      RO and/or any of its parts. The customer is not permitted, in any
      manner whatsoever, to open, modify, repair, damage and/or remove RO or
      any of its parts and/or change any part of the said RO. If any repairs
      are required due to the Customer&#39;s negligence in operations, or
      unsafe operations, the cost for repair of the Product would be borne
      by the Customer. In case of knowing of any such unauthorised opening
      and/or modification and/or repairing and/or removal of RO and/or any
      of its part and/or any damage to said RO and/or to any of its
      Part,Ashva Experts shall be entitled to immediately terminate the
      contract, suspend all services and to get its RO back in its custody
      and/or to seek appropriate damage from the customer.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>23. REPLACEMENT:</h6>
    <p>
      In case of any fault in RO being brought to the notice ofAshva Experts
      , by the Customer, which is being confirmed by technical support team
      ofAshva Experts and same being not caused by the Customer, theAshva
      Experts , at the request of the said customer,Ashva Experts at its
      sole discretion either replace any defective part of RO and/or RO
      itself, free of charge. However, for any other change or replacement
      the standard charges shall apply.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>24. RELOCATION:</h6>
    <p>
      Livpure Smart allows its Customer to change the Installation Address
      for provision of the Services to another residence, office or location
      of the Customer within the selected geographical area or locations
      where the Services are provided by theAshva Experts . If the Customer
      is interested to change the Address, the Customer must inform theAshva
      Experts by contacting the Helpline and booking a Relocation by giving
      the details of the new Address. Customer will be informed about the
      procedure and the applicable charges, as fixed by theAshva Experts ,
      from time to time.Ashva Experts shall not refund any amount to the
      Customer if he/she wishes to relocate or shift to locations where the
      Services are not provided by theAshva Experts . Additionally, theAshva
      Experts does not guarantee that the Service can be provided at the new
      Address or that the Customer will receive the Service at the new
      Address, even if such Services are provided in that location and/or
      area. Installation at the new Address will be subject to the terms and
      conditions applicable in connection with the Installation Services,
      including submission of valid proof of the new Address and/or such
      other information and documents that may be required by theAshva
      Experts . In case the Customer relocates on his / her own without
      using the services of theAshva Experts , then theAshva Experts shall
      have the right to suspend the maintenance privileges of the Customer.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>26. CANCELLATION:</h6>
    <p>
      Customer may deactivate or cancel or completely discontinue availing
      all the Services provided by theAshva Experts by providing a 30
      (thirty) days prior request in writing onAshva Experts registered
      email address by way of email through Customer’s registered email
      address. However, theAshva Experts reserves the right to deduct or
      adjust/set off any amount or refuse to pay security deposit of lock-in
      due to early discontinuation or cancellation/ deactivation of account
      or termination of the services before the minimum period of commitment
      or on account of any damage caused to the material ofAshva Experts by
      the customer or any charge applicable against deactivation. It is
      further clarified that in no circumstancesAshva Experts right to
      reject or approve the claim shall be impaired on account any reason
      andAshva Experts decision with respect to any subject including but
      not limited to deciding the commencement and end date of lock-in or
      withholding the security deposit shall be final and binding upon the
      customer. In case of a complete discontinuation of the Service, the
      Subscription Contract shall automatically stand terminated from the
      date of cancellation or discontinuation of that Services and Customer
      shall immediately return to theAshva Experts the RO that the Customer
      may have in its possession. In the event, the Customer does not wish
      to extend the minimum period of commitment on/before any time beyond
      the Agreement date, the customer shall terminate the agreement by
      raising a dismantle and return request of the RO with theAshva Experts
      . Upon such deactivation / cancellation of Subscription,Ashva Experts
      shall take custody and uninstall the Product and forthwith Customer
      shall return toAshva Experts the Product and every part thereof, which
      may be in or under the Customer&#39;s control. In case of
      cancellation,Ashva Experts will forfeit any available balance in the
      Customer&#39;s Account. In case of a deactivation,Ashva Experts
      reserve the right to cancel the Service, In case of a cancellation,
      the Subscription Contract shall stand automatically terminated from
      the date of cancellation.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>27. RETURN:</h6>
    <p>
      In the event of a) end of subscription plan; or b) failure to make
      recharge; or c) failure to subscribe new plan; or d) temporary
      suspension and/or disconnection of services for any reason; or e)
      prior termination of the Contract in accordance of the present
      contract Customer undertakes to return the Product immediately. It is
      the customer who shall be responsible for returning of the Product to
      the authorised representative ofAshva Experts against the written
      acknowledgment byAshva Experts . Customer shall not claim any
      interest, charge or lien on the Product, even if any dispute is
      pending for resolution between the Customer and theAshva Experts .
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>28. REPOSSESSION:</h6>
    <p>
      Notwithstanding the foregoing,Ashva Experts reserves the right to
      repossess the Product and/or claim damages from Customer if the
      Product is (i) stolen or lost (ii) damaged or (iii) used unauthorised
      (iv) not returned by the Customer upon cancellation, deactivation or
      completion of the Term or at the instruction of theAshva Experts .
      Non-exercise by theAshva Experts , of this right of repossession, does
      not operate as a waiver on the part of theAshva Experts , from
      exercising this right. In such event the customer hereby gives
      unconditional and irrevocable licence/permissions/authority to the
      representative ofAshva Experts to enter into the Scheduled Premises
      and/or any other premises where the said RO is kept for taking
      immediate custody and repossession of the said RO. It is made clear
      that repossession of said RO would be without prejudice to the rights
      ofAshva Experts to seek other remedies, reliefs whichAshva Experts
      otherwise would be entitled for in law.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>29. LIABILITY FOR DAMAGE:</h6>
    <p>
      The customer shall be liable to pay toAshva Experts for any damage
      (including but not limited to dent, scratches, chipping, cracked,
      machine, exposure to hardware and software tampering, including
      bending of frame, modification, unauthorised repairs, tampering of
      product serial number, malware installation or any other cause, loss
      or damage, temporary or permanent, not being the manufacturing defect)
      of the product, upto existing market value of the product, at the time
      of such damage. In case RO is lost/misplaced (including but not
      limited to robbery or theft or misappropriation) and/or disposed of by
      the Customer or by anyone else, the customer shall intimateAshva
      Experts and shall file a FIR with police having jurisdiction. The
      customer shall be liable for and shall be liable to pay the market
      value of the RO lost, stolen, robbed etc. whatever the case may be.
      The Customer confirms and agrees that Ashva Experts shall have sole
      authority to decide on the extent of damage caused to the Product and
      Ashva Experts reserves the right to update the damage schedule from
      time to time. The value of damage for any loss or damage caused to the
      RO (save normal wear and tear), whichLivpure Smart shall be entitled
      to claim damage as per the following schedule:
      <br />
      a. Front cover damage: Rs.1,000/- <br />
      b. Main body damage: Rs.1,700 /-
      <br />
      c. Missing or malfunctioning Pump: Rs.2,500 /-
      <br />
      d. Missing or malfunctioning Membrane: Rs.2,500 /-
      <br />
      e. Missing or malfunctioning Filter: Rs.500 per piece
      <br />
      f. Missing or malfunctioning SMPS (Power supply): Rs.700 /-
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>30. TERMINATION:</h6>
    <p>
      Livpure Smart reserves the right, without notice or any liability
      whatsoever to the Customer, to interrupt, deactivate, suspend, cancel,
      modify, or terminate any Services supplied to the Customer or
      deactivate the Customer Account if such action is deemed necessary by
      theAshva Experts (i) under any law or is requested by any competent
      authority; or (ii) non-payment by the Customer or failure by the
      Customer to pay the advance subscription fees or to maintain the
      Minimum Account Balance or to make any payment which is due; or (iii)
      for unauthorised use of the Services by the Customer; or (iv) for any
      breach or default on the part of customer of any of the terms and/or
      condition of the present contract; or (v) for any technical,
      maintenance, security or failure or degradation of any facilities,
      equipment or systems used to provide the Service; or (vi) forAshva
      Experts is lost/misplaced/damaged/disposed of by the lessee/customer
      intentionally or unintentionally; or (vii) due to any act beyond the
      control of theAshva Experts ; (vii) or in the event of emergency, such
      as, war or similar situation. Notwithstanding any other terms of this
      Agreement,Ashva Experts shall have the right to terminate the
      Agreement in case of any default on the part of theAshva Experts by
      providing 15 days’ notice to the Customer. The lease may be terminated
      by the Customer, any time during the lease period by serving a notice
      in writing, from the registered email id, subject to fulfilment of
      minimum period of commitment or on making payment for the remaining
      period of minimum commitment in lieu thereof.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>31. EFFECTS OF TERMINATION:</h6>
    <p>
      On termination, theAshva Experts shall have the right to take
      possession of RO delivered to the Customer immediately; and the
      Customer shall be liable for: a) any payment or arrear of rent pending
      from the Customer shall become payable immediately to theAshva Experts
      ; b) any penalty or damages payable to theAshva Experts in case of any
      damage/loss/misplacement/disposition of the Lessee/customer. In the
      event of termination, the Customer shall pay for the RO usage charges
      so long as the RO remains in the custody of the Customer. The Customer
      shall allowAshva Experts to uninstall the Product and forthwith return
      toAshva Experts , the Product and every part thereof, which may be in
      or under the Customer&#39;s control, without demur or protest and
      without making any claims or demands whatsoever. Notwithstanding the
      termination of the present contract, the liabilities incurred by
      parties prior to the termination shall remain valid, binding and
      enforceable.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>32. REACTIVATION:</h6>
    <p>
      In the event of an interruption, suspension or deactivation of the
      Service, theAshva Experts may, at its sole discretion, reactivate the
      Service, subject to any conditions that theAshva Experts may impose
      from time to time subject to applicable law. Upon reactivation,
      theAshva Experts may in addition to charging reactivation fees, deduct
      the expenses, charges and/or penalties that theAshva Experts may deem
      fit, subject to Applicable Law. These additional charges shall be
      debited from the Customer Account or separately recovered from the
      Customer at the option of theAshva Experts . Deactivation, suspension
      or termination shall be without prejudice to any other rights or
      remedies a Party may be entitled to in law or under the Contract and
      shall not affect any accrued rights or liabilities of either Party nor
      the coming into force or the continuance in force of any provision of
      the Contract, which is expressly or by implication intended to come
      into or continue in force on or after such termination.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>33. WARRANTIES:</h6>
    <p>
      TheAshva Experts makes no express or implied warranties, guarantees,
      representations or undertakings whatsoever regarding the Services/
      equipment etc., unless expressly mentioned in the Contract. TheAshva
      Experts specifically disclaims any statements indicating that the RO
      shall be without any fault or damage, including faults or damages
      resulting from exposure to adverse environmental conditions, including
      excessive moisture and excessive temperatures or damage to the RO on
      account of Force Majeure.
    </p>{" "}
    <br />
    <h6 style={{ color: "blueviolet" }}>34. LIABILITIES:</h6>
    <p>
      The liability of theAshva Experts will be limited to only proven
      direct damages or losses incurred by the Customer or any third party.
      The Customer shall indemnify, defend and holdAshva Experts harmless
      from and against any claim, demand, cause of action or loss or
      liability (including, but not limited to, attorneys’ fees and costs)
      for any RO damage or personal injury arising from the Customer’s use
      of the RO by any cause, except to the extent such is caused by
      theAshva Experts negligence or willful misconduct. TheAshva Experts ’s
      liability shall not exceed the Subscription Fee paid by the Customer
      for the Service in the 3 (three) months immediately preceding the
      specific event that gave rise to such damage or loss. The provisions
      of this clause shall survive the termination of this Agreement with
      respect to any claim or liability accruing before such termination. In
      no event shallAshva Experts be liable for any indirect, special or
      consequential loss or damage arising out of Customer’s use of the
      Products.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>35. COMMUNICATION:</h6>
    <p>
      The customer shall communicate withAshva Experts through registered
      mobile No. and/or through registered email ID.Ashva Experts shall also
      communicate a) all payment reminders and/or confirmations b) all plan
      related communications etc. on the registered mobile and/or mail id of
      the customer. The customer shall take all reasonable precautions to
      ensure the safety and privacy of Customer Account. Customer login IDs,
      Password, Security Password shall not be shared by the Customer with
      any third party. The customer shall immediately notify toAshva Experts
      about the breach and/or violation of any security or of any
      unauthorised use of customer ID and/or account and/or
      information.Ashva Experts shall not be responsible for loss, damage,
      fraud being committed by misuse, unauthorised use of customer account,
      login IDs, passwords registered mobile nos. and registered mail ids
      etc. and only customer shall be will be liable for all losses incurred
      by theAshva Experts or others due to such unauthorised use.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>36.PERMISSIONS:</h6>
    <p>
      By subscribing to and activating theAshva Experts services or
      possessing the RO, the Customer(s) grants theAshva Experts (or its
      subcontractors or business associates) the permission to contact the
      Customer(s) through Call, SMS, Social Media, Mobile Applications or
      any other means for any reasons (including for reasons connected to
      the service or otherwise). The Customer specifically agrees that in
      order to facilitate theAshva Experts to provide Services, theAshva
      Experts may be required to disclose any information or particulars
      pertaining to the Customer to any authority, including, but not
      limited to, any debt collection agency, credit reference agency,
      security agency, and reserves the right to comply with the direction
      of such authorities at its discretion and without intimating the
      Customer. The Customer acknowledges and agrees that the foregoing
      consent given to theAshva Experts (or its subcontractors or business
      associates) shall be notwithstanding the Customer being registered
      under the National Do Not Disturb Registry (or any such records). Any
      information collected from a Customer or its Authorised Representative
      is subject to the terms of the Privacy Policy on theAshva Experts
      Website, and Applicable Law. Customer (or Authorised Representative,
      as the case may be) grants its express consent to theAshva Experts to
      collect, and use information (including sensitive personal
      information) of the Customer and/or Authorised Representative, and
      contact the Customer and/or Authorised Representative using their
      information for the following purposes: (a) share information with
      regard to the subscription account such as alerts and account
      information. (b) fulfil the Customer’s or Authorised Representative’s
      requests regarding the Services; (c) respond to inquiries; (d) conduct
      market research; (e) enforce the legal terms or for other legal
      purposes; (f) prevent fraud or potentially illegal activities; (g)
      perform analyses; (h) provide technical support; (i) improve its
      products and/or Services; (j) share marketing materials (k) contact
      Customer or Authorised Representative for surveys or feedback; (l)
      execute other activities such as marketing campaigns. (m) to recommend
      products and services (n) to provide ads, offers and other sponsored
      content (o) to perform its obligations and duties as required by
      customary business practices.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>37. REVISIONS:</h6>
    <p>
      These terms and conditions are subject to modification and revision at
      the sole discretion of theAshva Experts and shall be deemed to be
      effective as soon as the revised, modified terms and conditions are
      notified on the website ofAshva Experts . The customer should be
      checking for any latest version of the terms and conditions. The use
      of the services under the subscription plan is deemed to be acceptance
      of such revised terms and conditions.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>38. NOTIFICATIONS</h6>
    <p>
      All notifications, complaints and notices must be in writing and
      addressed to Grievance Officer and/or to Nodal Officer, at its
      registered office or otherwise as provided on the website of theAshva
      Experts . The Customer agrees that notifications provided by theAshva
      Experts in such form as prescribed by theAshva Experts from time to
      time, including notifications displayed on theAshva Experts Website or
      the Customer care service or the notification provided by Alert, SMS,
      Email or any other method specified by theAshva Experts , will be
      sufficient and adequate notice to the Customer.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>39. ASSIGNMENT:</h6>
    <p>
      The Customer under any circumstances shall not assign or transfer or
      create any third party interest in this Agreement or RO without the
      consent of theAshva Experts . Any such transfer of assignment shall be
      considered as illegal and hence a violation of the terms of this
      Agreement. TheAshva Experts reserves its right to assign this
      Agreement, to any third party without prior notice to the Customer.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>40. ENTIRE AGREEMENT:</h6>
    <p>
      This Agreement constitutes the entire agreement betweenAshva Experts
      and the Customer. The acceptance of this Agreement also signifies the
      acceptance of the Customer, to the terms and conditions on theAshva
      Experts website. In the event of any conflict between the terms and
      conditions on theAshva Experts website and this Agreement, the terms
      and conditions on theAshva Experts website shall supersede. TheAshva
      Experts reserves the right to amend the terms and condition of this
      Agreement and on the website from time to time, the customer is
      requested to check the website for update of terms and conditions.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>41. SEVERABILITY:</h6>
    <p>
      If any law would otherwise make a provision of this Agreement illegal,
      void or unenforceable in any jurisdiction; or If a provision of the
      Agreement would otherwise contravene any law or regulation or rule
      applicable to the transection envisaged herein or impose an obligation
      or liability which is prohibited by the act or any other law, this
      Agreement is to be read as if that provision was varied to the extent
      necessary to comply with that law or, if necessary, omitted, without
      affecting the continued operation of the rest of this Agreement in
      that jurisdiction or any other jurisdiction.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>42. WAIVER:</h6>
    <p>
      Neither Party shall lose any right under this Subscription Contract if
      it fails to use that right, or delays in using it. For a waiver of a
      right to be valid, it must be written and will not give rise to an
      ongoing waiver of that right unless it is expressly stated to do so.
    </p>
    <br />
    <h6 style={{ color: "blueviolet" }}>
      43. GOVERNING LAW, DISPUTE RESOLUTION &amp; ARBITRATION:
    </h6>
    <p>
      This Agreement is governed by Indian law.Any disputes, differences or
      questions, which may arise at any time hereafter between theAshva
      Experts and the Customer touching the true construction of this
      Agreement or performance of the obligations or enforcing any rights
      and/or liabilities of the Parties hereunder, shall be first amicably
      resolved between the Parties within 30 (thirty) days from the date on
      which such dispute was raised by a Party and communicated to the other
      Party in writing failing which the dispute shall be referred to a sole
      arbitrator who shall be appointed by Ashva Experts . Both Customer and
      Ashva Experts , agrees and undertakes that it would be in the
      interests of both parties, if the Arbitration is conducted through
      Fast Track Mode as provided under Section 29-B of the Arbitration Act,
      as amended upto date. The arbitration shall be subject to the
      provisions of the Arbitration and Conciliation Act, 1996, as amended
      or any statutory modifications or re-enactment thereof for the time
      being in force. The venue of such arbitration shall be at Delhi and
      the Courts at Delhi alone shall have exclusive jurisdiction to deal
      with the arbitration proceedings and the awards in accordance with
      law. The arbitration proceedings shall be conducted in English
      language. The award passed by the arbitrator shall be final and
      binding upon the Parties.
    </p>
    <br />
  </div>
  <div>
  <Container fluid className="my-5">
    <footer
      className="text-center text-lg-start text-dark"
      style={{ backgroundColor: "#ECEFF1" }}
    >
      <div>
        <a href="tel:++91 8884567518" class="callme_float">
          <FaPhone />
        </a>
        <a
          href="https://wa.me/918495998156"
          class="message_float"
          target="_blank"
        >
          <FaWhatsapp />
        </a>
      </div>
      {/* Section: Social media */}
      <section
        className="d-flex justify-content-between p-4 text-white"
        style={{ backgroundColor: "#5CD2E6" }}
      >
        {/* Left */}
        <div className="me-5">
          
          <span ><Link to="/terms" >Terms and Conditions,</Link></span>
          <span ><Link to="/privacy" >Privacy Policy,</Link></span>
          <span ><Link to="/termuse" >Terms of use,</Link></span>
          <span ><Link to="/return" >Return & Refund Policy</Link></span>
        </div>
        {/* Left */}
        {/* Right */}
        <div>
        {/* <span>Get connected with us on social networks:</span> */}

          <a href="https://www.facebook.com/ashvaexpert" className="text-white me-4">
            <FaFacebook />
          </a>
          <a href="/" className="text-white me-4">
            <FaTwitter />
          </a>
          <a href="https://ashvaexperts.com/" className="text-white me-4">
            <FaGoogle />
          </a>
          <a href="https://www.instagram.com/ashvaexperts" className="text-white me-4">
            <FaInstagram />
          </a>
          <a href="/" className="text-white me-4">
            <FaLinkedin />
          </a>
          <a href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww" className="text-white me-4">
            <FaYoutube />
          </a>
        </div>
        {/* Right */}
      </section>
      {/* Section: Social media */}
      {/* Section: Links */}
      <section>
        <Container className="text-center text-md-start mt-5">
          <Row className="mt-3">
            {/* Grid column */}
            <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold">Contact</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                }}
              />
              <p>
                220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI,
                HOBLI, BANGALORE, KARNATAKA, INDIA -560060.
              </p>
            </Col>
            {/* Grid column */}
            {/* Grid column */}
            <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold">Office timings</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                }}
              />
              <p>
                <a href="#!" className="text-dark">
                  8AM-8PM MON-SAT
                </a>
              </p>
            </Col>
            {/* Grid column */}
            {/* Grid column */}
            <Col md={3} lg={2} xl={2} className="mx-auto mb-4 mr-2">
              <h6 className="text-uppercase fw-bold">Email</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                }}
              />
              <p>
                <a href="#!" className="text-dark">
                  <small>ASHVAEXPERTS.TEAM@GMAIL.COM</small>
                </a>
              </p>
            </Col>

            <Col
              md={4}
              lg={3}
              xl={3}
              className="mx-auto mb-md-0 mb-4 phone-column mr-10"
              style={{ marginRight: "50px" }}
            >
              <h6 className="text-uppercase fw-bold">Phone</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                }}
              />
              <p>8495998156</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section: Links */}
      {/* Copyright */}
      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2" }}
      >
        © 2023 Copyright:
        <a className="text-dark" href="https://www.walkinsoftwares.com/">
          Designed and Developed by Walkin Software Technologies
        </a>
      </div>
      {/* Copyright */}
    </footer>
    {/* Footer */}
  </Container>
  </div>
    </>
  )
}

export default Terms