import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Contact.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaTimesCircle } from "react-icons/fa"; // Import the cancel icon
import {
  deleteAddressById,
  getAddressById,
  updateAddress,
} from "./AddressHistoryApi";

const Contact = ({
  contact,
  onEditClick,
  onDeleteClick,
  updateFormData,
  customerId,
  addressId,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedAddress, setEditedAddress] = useState({ ...contact });
  const [isNewContact, setIsNewContact] = useState(!contact);

  //!Tokens and Headers
  const user = JSON.parse(sessionStorage.getItem("user"));
  const headers = {
    "Content-type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleEditClick = async (addressId) => {
    console.log("hi edit");
    console.log(addressId);

    var res = await getAddressById(addressId, headers);
    console.log(res.data);

    let det = res.data;

    setEditedAddress({
      fullName: det.fullName,
      address: det.address,
      city: det.city,
      pincode: det.pincode,
      state: det.state,
    });
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  //!updated data by PUT method
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Create a new formData object with the updated data
    const formData = {
      fullName: editedAddress.fullName,
      address: editedAddress.address,
      city: editedAddress.city,
      pincode: editedAddress.pincode,
      state: editedAddress.state,
      addressId: addressId, // Set the addressId from the prop
      customerDto: {
        customerId: customerId, // Set the customerId from the prop
      },
    };

    try {
      // Send the update request
      const response = await updateAddress(headers, formData);
      console.log(response);

      // After a successful update, fetch the updated data for this address
      const updatedDataResponse = await getAddressById(addressId, headers);
      const updatedData = updatedDataResponse.data;

      // Update the parent component's state with the updated data
      updateFormData(updatedData); // This will trigger a re-render of the Contact component
      setEditMode(false); // Exit edit mode
    } catch (error) {
      console.error("Error updating address:", error);
      // Handle error scenarios here
    }
  };

  //!delete method
  const handleDeleteClick = async (addressId, fetchDataFunction) => {
    console.log(addressId);
    await deleteAddressById(addressId, headers);
    // Call the fetchDataFunction to refresh data after deletion
    fetchDataFunction();
  };

  return (
    <div className="shadow p-3 mb-5 bg-white rounded">
      <Card>
        <Card.Body>
          {editMode ? (
            <Form onSubmit={handleFormSubmit}>
              <div className="d-flex justify-content-between align-items-center">
                <h4>{isNewContact ? "Add a New Address" : "Edit Contact"}</h4>
                <FaTimesCircle
                  className="cancel-icon"
                  onClick={handleCancelEdit}
                />
              </div>
              {/* Form fields for editing */}
              <Form.Group controlId="fullName">
                <Form.Label>Full Name:</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAddress.fullName}
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      fullName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="address">
                <Form.Label>Address:</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAddress.address}
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      address: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="city">
                <Form.Label>City:</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAddress.city}
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      city: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="pincode">
                <Form.Label>Pincode:</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAddress.pincode}
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      pincode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label>State:</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAddress.state}
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      state: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Button type="submit">Save</Button>
            </Form>
          ) : contact ? (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <p>
                  <span>Full Name: {contact.fullName}</span>
                </p>
                <p>
                  <span>Address: {contact.address}</span>
                </p>
                <p>
                  <span>City: {contact.city}</span>
                </p>
                <p>
                  <span>Pincode: {contact.pincode}</span>
                </p>
                <p>
                  <span>State: {contact.state}</span>
                </p>
              </div>
              <div
                className="d-flex flex-column align-items-end"
                onMouseEnter={toggleOptions}
                onMouseLeave={toggleOptions}
              >
                <BsThreeDotsVertical className="vertical-dots-icon" />
                {showOptions && (
                  <div className="dropdown-content">
                    <div onClick={() => handleEditClick(contact.addressId)}>
                      Edit
                    </div>
                    <div
                      onClick={() => {
                        handleDeleteClick(contact.addressId);
                      }}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Form>
              <div className="d-flex justify-content-between align-items-center">
                <h4>Add a New Address</h4>
                <FaTimesCircle
                  className="cancel-icon"
                  onClick={handleCancelEdit}
                />
              </div>
              {/* Form fields for adding a new address */}
              <Form.Group controlId="fullName">
                <Form.Label>Full Name:</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      fullName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="address">
                <Form.Label>Address:</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      address: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="city">
                <Form.Label>City:</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      city: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="pincode">
                <Form.Label>Pincode:</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      pincode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label>State:</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setEditedAddress({
                      ...editedAddress,
                      state: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Button type="submit">Add</Button>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Contact;
