import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Card,
  Table,
  Col,
  Row,
} from "react-bootstrap";
import logo from  '../images/ASHVA_LOGO_HEADER.png';
import{ FaPhone,
  FaWhatsapp,FaRegWindowClose} from 'react-icons/fa';
import './Product.css';
import image1 from '../images/ROUF-360x360.png';
import image2 from '../images/ROUVUF-360x360.png';
import image3 from  '../images/ROUVUFALKALINE-360x360.png';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import {
  FaFacebook,
  FaTwitter,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

  



const Products = () => {

  const [showModal, setShowModal] = useState(false);
  const googlePlayUrl="https://play.google.com/store/apps";

  function handleClick() {
    setShowModal(!showModal);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'honeydew'
    
    },
  };


  const nav=useNavigate();
  
  const login=(e)=>{
    e.preventDefault();
    nav("/login")
  }



  return (
    <React.Fragment>

      {/*  ************************* navbar ************************** */}
     <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link ><Link to='/'>Home</Link></Nav.Link>
              <Nav.Link ><Link to='/about'>About Us</Link></Nav.Link>
              <Nav.Link ><Link to='/products'>Products</Link></Nav.Link>
              <Nav.Link ><Link to='/plan'>Plans</Link></Nav.Link>
              {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
              <Nav.Link ><Link to='/contact'>Contact Us</Link></Nav.Link>
              <Nav.Link href={googlePlayUrl}>
               Google Playstore
              </Nav.Link>
            </Nav>
            {/* <Nav className="justify-content-end">
              <Button variant="primary" onClick={login}>Sign In</Button>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
       
        
        {/*  ************************* Page Title Starts Here ************************** */}
        <div className="page-nav no-margin row" style={{paddingTop:"0px"}}>
          <div className="container">
            <div className="row">
              <h2 className="text-start"> Our Products</h2>
              <ul>
                <li> <a href="#"><i className="bi bi-house-door" /> Home</a></li>
                <li><i className="bi bi-chevron-double-right pe-2" /> Products</li>
              </ul>
            </div>
          </div>
        </div>
        {/* ##################### Our Products Starts Here #################### */}
        <div className="container-fluid products big-padding px-3 bg-gray">
          <div className="container-xl">
            <div className="section-title row">
              <h2 className="fs-1 fw-bold">Our Products</h2>
              <p> We provide the all products for Rent</p>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 mb-4">
            
                  <div className="product bg-white p-4 text-center shadow-md">
                    <img className="mah-150" src={image1} alt="" />
                    <div className="d-inline-block">
                      <h4 className="fw-bolder fs-5 mt-4 ms-3">SMART RO+UF</h4>
                      <span className="fw-bolder fs-4"> &#8377;399/-</span> 
                      
                    </div>
                    {/* <div className="d-inline-block mt-3">
                      <button className="btn btn-primary px-5" onClick={handleClick}>Rent Now</button>
                    </div> */}
                  </div>
                
              </div>
              <div className="col-12 col-md-4 mb-4">
          
                  <div className="product bg-white p-4 text-center shadow-md">
                    <img className="mah-150" src={image2} alt="" />
                    <div className="d-inline-block">
                      <h4 className="fw-bolder fs-5 mt-4">SMART RO+UV+UF</h4>
                      <span className="fw-bolder fs-4">&#8377;499/-</span> 
                     
                    </div>
                    {/* <div className="d-inline-block mt-3">
                      <button className="btn btn-primary px-5" onClick={handleClick}>Rent Now</button>
                    </div> */}
                  </div>
                
              </div>
              <div className="col-12 col-md-4 mb-4">
           
                  <div className="product bg-white p-4 text-center shadow-md">
                    <img className="mah-150" src={image3} alt="" />
                    <div className="d-inline-block">
                      <h4 className="fw-bolder fs-5 mt-4">SMART RO+UV+UF +ALKALINE</h4>
                      <span className="fw-bolder fs-4"> &#8377;599/-</span> 
                     
                    </div>
                    {/* <div className="d-inline-block mt-3">
                      <button className="btn btn-primary px-5" onClick={handleClick}>Rent Now</button>
                    </div> */}
                  </div>
                
              </div>
              {/* <div className="col-lg-3 col-md-4 mb-4">
                <a href="detail.html">
                  <div className="product bg-white p-4 text-center shadow-md">
                    <img className="mah-150" src="assets/images/product/p3.webp" alt="" />
                    <div className="d-inline-block">
                      <h4 className="fw-bolder fs-5 mt-4">Alkaline Ultra</h4>
                      <span className="fw-bolder fs-4">85$</span> <span className="text-muted text-decoration-line-through">105$</span>
                    </div>
                    <div className="d-inline-block mt-3">
                      <button className="btn btn-primary px-5">Buy Now</button>
                    </div>
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <Modal isOpen={showModal}style={customStyles} >
      <FaRegWindowClose onClick={handleClick} style={{float:"right"}}/>
      <h3>Complete the form below to get a free quote/estimate</h3>

        <form>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label htmlFor="name">NAME :</label>
        <input type="text" id="name" name="name" />
        <label htmlFor="name">MOBILE :</label>
        <input type="text" id="name" name="name" />

        <label htmlFor="email">EMAIL :</label>
        <input type="email" id="email" name="email" />
        <label htmlFor="email">SERVICE :</label>
        <input type="email" id="email" name="email" />

        <label htmlFor="message">HELP :</label>
        <textarea id="message" name="message"></textarea> <br></br>

        <button type="submit" style={{width:"100px"}}>Submit</button>
      </div>
    </form> 
    <h4 style={{fontSize:"20px",marginTop:"10px"}}><b>If this is an urgent service request, please call +91 888-4567-518</b></h4>


      
      </Modal>


        {/*- ############ Footer Starts Here ################## */}
        

        <Container fluid className="my-5">
        <footer
          className="text-center text-lg-start text-dark"
          style={{ backgroundColor: "#ECEFF1" }}
        >
          <div>
            <a href="tel:++91 8884567518" class="callme_float">
              <FaPhone />
            </a>
            <a
              href="https://wa.me/918495998156"
              class="message_float"
              target="_blank"
            >
              <FaWhatsapp />
            </a>
          </div>
          {/* Section: Social media */}
          <section
            className="d-flex justify-content-between p-4 text-white"
            style={{ backgroundColor: "#5CD2E6" }}
          >
            {/* Left */}
            <div className="me-5">
              
              <span ><Link to="/terms" >Terms and Conditions,</Link></span>
              <span ><Link to="/privacy" >Privacy Policy,</Link></span>
              <span ><Link to="/termuse" >Terms of use,</Link></span>
              <span ><Link to="/return" >Return & Refund Policy</Link></span>
            </div>
            {/* Left */}
            {/* Right */}
            <div>
            {/* <span>Get connected with us on social networks:</span> */}

              <a href="https://www.facebook.com/ashvaexpert" className="text-white me-4">
                <FaFacebook />
              </a>
              <a href="/" className="text-white me-4">
                <FaTwitter />
              </a>
              <a href="https://ashvaexperts.com/" className="text-white me-4">
                <FaGoogle />
              </a>
              <a href="https://www.instagram.com/ashvaexperts" className="text-white me-4">
                <FaInstagram />
              </a>
              <a href="/" className="text-white me-4">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww" className="text-white me-4">
                <FaYoutube />
              </a>
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
          {/* Section: Links */}
          <section>
            <Container className="text-center text-md-start mt-5">
              <Row className="mt-3">
                {/* Grid column */}
                <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI,
                    HOBLI, BANGALORE, KARNATAKA, INDIA -560060.
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Office timings</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      8AM-8PM MON-SAT
                    </a>
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={3} lg={2} xl={2} className="mx-auto mb-4 mr-2">
                  <h6 className="text-uppercase fw-bold">Email</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      <small>ASHVAEXPERTS.TEAM@GMAIL.COM</small>
                    </a>
                  </p>
                </Col>

                <Col
                  md={4}
                  lg={3}
                  xl={3}
                  className="mx-auto mb-md-0 mb-4 phone-column mr-10"
                  style={{ marginRight: "50px" }}
                >
                  <h6 className="text-uppercase fw-bold">Phone</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>8495998156</p>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Section: Links */}
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2" }}
          >
            © 2023 Copyright:
            <a className="text-dark" href="https://www.walkinsoftwares.com/">
              Designed and Developed by Walkin Software Technologies
            </a>
          </div>
          {/* Copyright */}
        </footer>
        {/* Footer */}
      </Container>
      
    </React.Fragment>
  )
}

export default Products