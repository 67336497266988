import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import "./Login.css";
import logo from "../images/ASHVA_LOGO_HEADER.png";
import axios from "axios";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({ userName: "", password: "" }); // Add formData state

  const nav = useNavigate();

  const validate = () => {
    const errors = {};

    if (!userName) {
      errors.userName = "Email is required";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    return errors;
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
    clearError("userName"); // Clear error when user starts typing
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    clearError("password"); // Clear error when user starts typing
  };

  const clearError = (field) => {
    if (errors[field]) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }
  };

  const signup = (e) => {
    e.preventDefault();
    nav("/signup");
  };

  //  const handleSubmit = (e)=>{

  //  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();

    if (Object.keys(errors).length === 0) {
      const updatedFormData = { userName, password };
      try {
        const response = await axios.post(
          "https://virtullearning.cloudjiffy.net/ecommercecustomer/login/v1/userLogin",
          updatedFormData
        );

        if (response.status === 200) {
          // Store user information in sessionStorage
          sessionStorage.setItem("user", JSON.stringify(response.data));
          console.log(updatedFormData);
          console.log("Login success:", response.data);
          // Navigate to dashboard upon successful login
          nav("/homedash");
          setFormData({ userName: "", password: "" }); // Clear the form
          // Handle successful login
        } else {
          console.error("Login failed:", response.data);
          // Handle login failure
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          console.error("Bad Request:", error.response.data);
          // Handle specific error case for 400 response
          // For example, you could update state to display an error message to the user
        } else {
          console.error("Login error:", error);
          // Handle other errors
        }
      }
    } else {
      setErrors(errors);
    }
  };

  // to integrate axios  with it

  //   if (Object.keys(errors).length === 0) {
  //     axios
  //       .post("/api/login", { userName, password })
  //       .then((response) => {
  //         console.log(response.data);
  //         setUserName("");
  //         setPassword("");
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setErrors({ server: "Error logging in. Please try again." });
  //       });
  //   } else {
  //     setErrors(errors);
  //   }
  // };

  return (
    <React.Fragment>
      {/* ##################### navbar #################### */}
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/about">About Us</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/products">Products</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/plan">Plans</Link>
              </Nav.Link>
              {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
              <Nav.Link>
                <Link to="/contact">Contact Us</Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* ##################### login #################### */}
      <div
        className="container mt-2"
        style={{ backgroundColor: "#E8EAF6", width: "100%" }}
      >
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="border border-3 border-primary"></div>
            <div className="card">
              <div className="card-header">
                <h3> Ashva Experts</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="userName">Mobile Number</label>
                    <input
                      type="text"
                      name="userName"
                      value={userName}
                      onChange={handleUserNameChange}
                      onFocus={() => clearError("userName")} // Clear error on focus
                      className={`form-control ${
                        errors.userName ? "is-invalid" : ""
                      }`}
                    />
                    {errors.userName && (
                      <div className="invalid-feedback">{errors.userName}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                      onFocus={() => clearError("password")} // Clear error on focus
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">
                    Login
                  </button>
                </form>
                <div className="mt-3">
                  <p className="mb-0  text-center">
                    Don't have an account?{" "}
                    <a
                      href="{''}"
                      className="text-primary fw-bold"
                      onClick={signup}
                    >
                      Sign Up
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*- ############ Footer Starts Here ################## */}

      <footer>
        <div>
          <a href="tel:++91 8884567518" class="callme_float">
            <FaPhone />
          </a>
          <a
            href="https://wa.me/918495998156"
            class="message_float"
            target="_blank"
          >
            <FaWhatsapp />
          </a>
        </div>
        <div
          className="inner "
          style={{ backgroundColor: "#1E88E5", color: "#000000" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Contact Details
                </h6>
                <div>
                  <ul style={{ textAlign: "center" }}>
                    <li>220, 6TH CROSS, GANAKALLU,</li>
                    <li>SRINIVASAPURA COLONY KENGERI, HOBLI,</li>
                    <li>BANGALORE, KARNATAKA, INDIA -560060.</li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Phone</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>87687587588</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Email</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>
                      ASHVAEXPERTS.TEAM@GMAIL.COM
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Office timings
                </h6>
                <div>
                  <ul className="row">
                    <li style={{ textAlign: "center" }}>
                      OPEN 9AM-9PM:MONDAY - SATURDAY
                    </li>

                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-facebook" />
                    </li>
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-twitter" />
                    </li>
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-instagram" />
                    </li>
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-linkedin" />
                    </li>
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-youtube" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy">
        <div className="container" style={{ textAlign: "center" }}>
          <a
            href="https://www.walkinsoftwares.com/"
            style={{ textDecorationLine: "none" }}
          >
            2022 © All Rights Reserved | Designed and Developed by Walkin
            Software Technologies
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
