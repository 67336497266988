import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import axios from "axios";
import { Row, Col, Form } from "react-bootstrap";
import "./Dashboard.css";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import YouTube from "react-youtube";
import logo from "../images/ASHVA_LOGO_HEADER.png";
import { FaPhone, FaWhatsapp, FaRegWindowClose } from "react-icons/fa";
import image1 from "../images/ROUF-360x360.png";
import image2 from "../images/ROUVUF-360x360.png";
import image3 from "../images/ROUVUFALKALINE-360x360.png";
// import background1 from "../images/sp1.jpg";
// import background2 from "../images/sp2.png";
import slider1 from "../images/s1.jpg";
import slider2 from "../images/s2.jpg";
import ashva from "../images/ashva.png";
import filter from "../images/water-filtration-icon-water-filtration-equipment-vector-icon-design-white-179312692.jpg";
import repair from "..//images/water reapir .jpg";
import install from "../images/basic purifier repair.jpg";
import uninstall from "../images/uninstall water filter.jpg";
import { Link } from "react-router-dom";
import portfolio1 from "../images/portfolio pic-1.jpeg";
import portfolio2 from "../images/portfolio pic-2.jpeg";
import portfolio3 from "../images/portfolio pic-3.jpeg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaFacebook,
  FaTwitter,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";





function Dashboard() {
  const [showModal, setShowModal] = useState(false);
  const [displayModal, setDispalyModal] = useState(false);

  const googlePlayUrl = "https://play.google.com/store/apps";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    selectValue: "",
    mobile: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    selectValue: "",
    mobile: "",
    message: "",
  });

  const [formData1, setFormData1] = useState({
    name1: "",
    email1: "",
    selectValue1: "",
    mobile1: "",
    message1: "",
  });

  const [errors1, setErrors1] = useState({
    name1: "",
    email1: "",
    selectValue1: "",
    mobile1: "",
    message1: "",
  });

  //what app
  function generateWhatsAppLink(formData) {
    const phoneNumber = "918495998156";
    const message = `Name: ${formData.name}\nEmail: ${formData.email}\nService: ${formData.selectValue}\nMobile: ${formData.mobile}\nMessage: ${formData.message}`;
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  }

  function generateWhatsAppLink1(formData1) {
    const phoneNumber = "918495998156";
    const message = `Name: ${formData1.name1}\nEmail: ${formData1.email1}\nService: ${formData1.selectValue1}\nMobile: ${formData1.mobile1}\nMessage: ${formData1.message1}`;
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  }

  const nav = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(formData);
    // Form validation
    let errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.selectValue) {
      errors.selectValue = "Please select an option";
    }

    if (!formData.mobile) {
      errors.mobile = "Mobile number is required";
    } else if (!/^[0-9]+$/.test(formData.mobile)) {
      errors.mobile = "Mobile number is invalid";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

   // Reset errors before opening the link
  setErrors({});
  
    const whatsappLink = generateWhatsAppLink(formData);

    window.open(whatsappLink, "_blank");

    setFormData({
      name: "",
      email: "",
      selectValue: "",
      mobile: "",
      message: "",
    });

    // POST request using Axios for Rent Now
    // axios
    //   .post(
    //     "https://rowaterplant.cloudjiffy.net/PSANDSMSCUSTOMER/rentnow/v1/createRentNow",
    //     formData
    //   )
    //   .then((response) => {
    //     console.log(response);
    //     toast.success("RentNow Successfully Created", {
    //       position: "top-center",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //     });
    //     //  alert("RentNow Successfully Created");
    //     setFormData({
    //       name: "",
    //       email: "",
    //       mobile: "",
    //       message: "",
    //       selectValue: "",
    //     });
    //     setErrors({
    //       name: "",
    //       email: "",
    //       mobile: "",
    //       message: "",
    //       selectValue: "",
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     alert("Error sending message");
    //   });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    // Reset the error for the current input field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();

    console.log(formData1);
    // Form validation
    let errors1 = {};
    if (!formData1.name1) {
      errors1.name1 = "Name is required";
    }

    if (!formData1.email1) {
      errors1.email1 = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData1.email1)) {
      errors1.email1 = "Email is invalid";
    }

    if (!formData1.selectValue1) {
      errors1.selectValue1 = "Please select an option";
    }

    if (!formData1.mobile1) {
      errors1.mobile1 = "Mobile number is required";
    } else if (!/^[0-9]+$/.test(formData1.mobile1)) {
      errors1.mobile1 = "Mobile number is invalid";
    }

    if (!formData1.message1) {
      errors1.message1 = "Message is required";
    }

    if (Object.keys(errors1).length > 0) {
      setErrors1(errors1);
      return;
    }

    // Reset errors before opening the link
  setErrors1({});
    const whatsappLink = generateWhatsAppLink1(formData1);

    window.open(whatsappLink, "_blank");

    setFormData1({
      name1: "",
      email1: "",
      selectValue1: "",
      mobile1: "",
      message1: "",
    });
    // POST request using Axios Service Now
    // axios
    //   .post(
    //     "https://rowaterplant.cloudjiffy.net/PSANDSMSCUSTOMER/servicenow/v1/createServiceNow",
    //     formData1
    //   )
    //   .then((response) => {
    //     console.log(response);
    //     toast.success("ServiceNow Successfully Created", {
    //       position: "top-center",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //     });

    //     //  alert("ServiceNow Successfully Created");
    //     setFormData1({
    //       name1: "",
    //       email1: "",
    //       mobile1: "",
    //       message1: "",
    //       selectValue1: "",
    //     });
    //     setErrors1({
    //       name: "",
    //       email: "",
    //       mobile: "",
    //       message: "",
    //       selectValue1: "",
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     alert("Error sending message");
    //   });
  };

  const handleInputChange1 = (event) => {
    const { name, value } = event.target;
  
    // Reset the error for the current input field
    setErrors1((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  
    setFormData1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //rent modal
  const [formData2, setFormData2] = useState({
    name2: "",
    email2: "",
    selectValue2: "",
    mobile2: "",
    message2: "",
  });

  // const [errors2, setErrors2] = useState({
  //   name2: "",
  //   email2: "",
  //   selectValue2: "",
  //   mobile2: "",
  //   message2: "",
  // });

  const handleSubmit2 = (event) => {
    event.preventDefault();

    console.log(formData2);

    // POST request using Axios Rent Now for modal
    axios
      .post(
        "https://rowaterplant.cloudjiffy.net/PSANDSMSCUSTOMER/rentnow/v1/createRentNow",
        formData2
      )
      .then((response) => {
        console.log(response);
        toast.success("RentNow Successfully Created", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        //  alert("ServiceNow Successfully Created");
        setFormData2({
          name2: "",
          email2: "",
          mobile2: "",
          message2: "",
          selectValue2: "",
        });
        setShowModal(!showModal);
      })
      .catch((error) => {
        console.log(error);
        alert("Error sending message");
      });
  };

  const handleInputChange2 = (event) => {
    const { name, value } = event.target;
    setFormData2((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //service modal
  const [formData3, setFormData3] = useState({
    name3: "",
    email3: "",
    selectValue3: "",
    mobile3: "",
    message3: "",
  });

  const handleSubmit3 = (event) => {
    event.preventDefault();

    console.log(formData3);

    // POST request using Axios Rent Now for modal
    axios
      .post(
        "https://rowaterplant.cloudjiffy.net/PSANDSMSCUSTOMER/servicenow/v1/createServiceNow",
        formData3
      )
      .then((response) => {
        console.log(response);
        toast.success("ServiceNow Successfully Created", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        //  alert("ServiceNow Successfully Created");
        setFormData2({
          name2: "",
          email2: "",
          mobile2: "",
          message2: "",
          selectValue2: "",
        });
        setShowModal(!showModal);
      })
      .catch((error) => {
        console.log(error);
        alert("Error sending message");
      });
  };

  const handleInputChange3 = (event) => {
    const { name, value } = event.target;
    setFormData3((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleClick() {
    setShowModal(!showModal);
  }
  function handleClickservice() {
    setDispalyModal(!displayModal);
  }
  const customStyles = {
    content: {
      top: "50%",
      // zIndex:10,
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "honeydew",
      height: "80%",
    },
  };

  const login = (e) => {
    e.preventDefault();
    nav("/login");
  };

  const videoOptions = {
    height: "250",
    width: "100%",
  };

  return (
    <React.Fragment>
      {/* ##################### navbar #################### */}
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/about">About Us</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/products">Products</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/plan">Plans</Link>
              </Nav.Link>
              {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
              <Nav.Link>
                <Link to="/contact">Contact Us</Link>
              </Nav.Link>
              <Nav.Link href={googlePlayUrl}>Google Playstore</Nav.Link>
            </Nav>
            {/* <Nav className="justify-content-end">
              <Button variant="primary" onClick={login}>
                Sign In
              </Button>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* ##################### carousel #################### */}
      <Carousel style={{ paddingTop: "0px" }}>
        <Carousel.Item className="car-h">
          <img
            className="d-block w-100 car-image"
            src={slider1}
            alt="First slide"
          />
          <Carousel.Caption>
            <div className="row">
              <div className="col-lg-6 animate__animated  animate__bounceInLeft text-start ">
                <h6 className="fs-11 fw-bolder text-start " id="text-size">
                  Ashva Experts <br />
                  <span className="text-primary">Digital Platform</span>
                </h6>
                <p className="text-dark d-none d-md-block text-start">
                  SMART RO+UF
                </p>
                <div className="buttonstyle">
                  <button
                    class="btn btn-primary m-5 button-style"
                    type="button"
                    onClick={handleClick}
                  >
                    Rent Now
                  </button>
                  <button
                    class="btn btn-secondary button-style"
                    type="button"
                    onClick={handleClickservice}
                  >
                    Service Now
                  </button>
                </div>

                <div className="d-inline-block pt-5 text-start d-none d-lg-block">
                  <div class="d-grid gap-2 d-md-block">
                    <button
                      class="btn btn-primary m-5 button-style"
                      type="button"
                      onClick={handleClick}
                    >
                      Rent Now
                    </button>
                    <button
                      class="btn btn-secondary button-style"
                      type="button"
                      onClick={handleClickservice}
                    >
                      Service Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 animate__animated animate__bounceInRight d-none d-lg-block">
                <img className="w-100" src={ashva} alt="" />

                <img />
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="car-h">
          <img
            className="d-block w-100 car-image"
            src={slider2}
            alt="Second slide"
          />

          <Carousel.Caption>
            <div className="row">
              <div className="col-lg-6 animate__animated  animate__bounceInLeft text-start ">
                <h6 className="fs-11 fw-bolder text-start " id="text-size2">
                  Ashva Experts <br />
                  <span className="text-primary"> Platform</span>{" "}
                </h6>
                <p className="text-dark d-none d-md-block text-start">
                  SMART RO+UV+UF
                </p>

                <div className="buttonstyle">
                  <button
                    class="btn btn-primary m-5 button-style"
                    type="button"
                    onClick={handleClick}
                  >
                    Rent Now
                  </button>
                  <button
                    class="btn btn-secondary button-style"
                    type="button"
                    onClick={handleClickservice}
                  >
                    Service Now
                  </button>
                </div>
                <div className="d-inline-block pt-5 text-start d-none d-lg-block">
                  <div class="d-grid gap-2 d-md-block">
                    <button
                      class="btn btn-primary m-5 button-style"
                      type="button"
                      onClick={handleClick}
                    >
                      Rent Now
                    </button>
                    <button
                      class="btn btn-secondary button-style"
                      type="button"
                      onClick={handleClickservice}
                    >
                      Service Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 animate__animated animate__bounceInRight d-none d-lg-block">
                <img className="w-100" src={ashva} alt="" />
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="car-h">
          <img
            className="d-block w-100 car-image"
            src={slider1}
            alt="Third slide"
          />

          <Carousel.Caption>
            <div className="row">
              <div className="col-lg-6 animate__animated  animate__bounceInLeft text-start ">
                <h6 className="fs-11 fw-bolder text-start " id="text-size3">
                  Ashva Experts <br />
                  <span className="text-primary">Digital Platform</span>{" "}
                </h6>
                <p className="text-dark d-none d-md-block text-start">
                  SMART RO+UV+ALKALINE
                </p>
                <div className="buttonstyle">
                  <button
                    class="btn btn-primary m-5 button-style"
                    type="button"
                    onClick={handleClick}
                  >
                    Rent Now
                  </button>
                  <button
                    class="btn btn-secondary button-style"
                    type="button"
                    onClick={handleClickservice}
                  >
                    Service Now
                  </button>
                </div>
                <div className="d-inline-block pt-5 text-start d-none d-lg-block">
                  <div class="d-grid gap-2 d-md-block">
                    <button
                      class="btn btn-primary m-5"
                      type="button"
                      onClick={handleClick}
                    >
                      Rent Now
                    </button>
                    <button
                      class="btn btn-secondary"
                      type="button"
                      onClick={handleClickservice}
                    >
                      Service Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 animate__animated animate__bounceInRight d-none d-lg-block">
                <img className="w-100" src={ashva} alt="" />

                <img />
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* <div>
        <Modal isOpen={showModal} style={customStyles}>
          <FaRegWindowClose onClick={handleClick} style={{ float: "right" }} />
          <h3>Complete the form below to get a free quote/estimate</h3>

          <form>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="name">NAME :</label>
              <input type="text" id="name" name="name" />
              <label htmlFor="name">MOBILE :</label>
              <input type="text" id="name" name="name" />
              <label htmlFor="email">EMAIL :</label>
              <input type="email" id="email" name="email" />
              <label htmlFor="email">SERVICE :</label>
              <input type="email" id="email" name="email" />
              <label htmlFor="message">MESSAGE :</label>
              <textarea id="message" name="message"></textarea> <br></br>
              <button type="submit" style={{ width: "100px" }}>
                Submit
              </button>
            </div>
          </form>
          <h4 style={{ fontSize: "20px", marginTop: "10px" }}>
            <b>
              If this is an urgent service request, please call +91 8495998156
            </b>
          </h4>
        </Modal>
      </div> */}
      {/* ##################### form #################### */}

      <div className="out-team container-fluid big-padding bg-gray">
        <div className="container">
          <div className="row section-title">
            <h2>Lets Start</h2>
          </div>
          <div className="row contact-rooo big-padding no-margin">
            <div className="container">
              <div className="row">
                <div className="col-sm-7">
                  <h2 className="fs-4 fw-bold">Rent Now</h2> <br />
                  <div className="row cont-row">
                    <div className="col-sm-3">
                      <label>Name:</label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        placeholder="Enter Name"
                        name="name"
                        className="form-control input-sm"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      {errors.name && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.name}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row cont-row">
                    <div className="col-sm-3">
                      <label>Email:</label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="email"
                        placeholder="Enter Email Address"
                        className="form-control input-sm"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {errors.email && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.email}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row cont-row">
                    <div className="col-sm-3">
                      <label>Products:</label>
                    </div>
                    <div className="col-sm-6">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="selectValue"
                        value={formData.selectValue}
                        onChange={handleInputChange}
                      >
                        <option selected>select Product Types</option>
                        <option value="SMART RO+UF">SMART RO+UF</option>
                        <option value="SMART RO+UV+UF">SMART RO+UV+UF</option>
                        <option value="SMART RO+UV+ALKALINE">
                          SMART RO+UV+ALKALINE
                        </option>
                      </select>
                      {errors.selectValue && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.selectValue}
                        </span>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="row cont-row">
                    <div className="col-sm-3">
                      <label>Mobile:</label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Enter Mobile Number"
                        className="form-control input-sm"
                        value={formData.mobile}
                        onChange={handleInputChange}
                      />
                      {errors.mobile && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.mobile}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row cont-row">
                    <div className="col-sm-3">
                      <label> Message:</label>
                    </div>
                    <div className="col-sm-6">
                      <textarea
                        rows={5}
                        placeholder="Enter Your Message"
                        className="form-control input-sm"
                        defaultValue={""}
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                      />
                      {errors.message && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <label />
                    </div>
                    <div className="col-sm-8">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmit}
                      >
                        Send Message
                      </button>
                      <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="col-sm-5">
                  <div className="serv">
                    <h2 className="fs-4 fw-bold" style={{ padding: "2px" }}>
                      Service Now
                    </h2>
                    <br />
                    <div className="row cont-row">
                      <div className="col-sm-4">
                        <label>Name:</label>
                      </div>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          placeholder="Enter Name"
                          name="name1"
                          className="form-control input-sm"
                          value={formData1.name1}
                          onChange={handleInputChange1}
                        />
                        {errors1.name1 && (
                          <span className="error" style={{ color: "red" }}>
                            {errors1.name1}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row cont-row">
                      <div className="col-sm-4">
                        <label>Email:</label>
                      </div>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          name="email1"
                          placeholder="Enter Email Address"
                          className="form-control input-sm"
                          value={formData1.email1}
                          onChange={handleInputChange1}
                        />
                        {errors1.email1 && (
                          <span className="error" style={{ color: "red" }}>
                            {errors1.email1}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row cont-row">
                      <div className="col-sm-4">
                        <label>Service: </label>
                      </div>
                      <div className="col-sm-8">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          name="selectValue1"
                          value={formData1.selectValue1}
                          onChange={handleInputChange1}
                        >
                          <option selected> select Service Types</option>
                          <option value="Rental">Rental</option>
                          <option value="Purifier">Purifier</option>
                          <option value="Softner">Softner</option>
                        </select>
                        {errors1.selectValue1 && (
                          <span className="error" style={{ color: "red" }}>
                            {errors1.selectValue1}
                          </span>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="row cont-row">
                      <div className="col-sm-4">
                        <label>Mobile:</label>
                      </div>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          name="mobile1"
                          placeholder="Enter Mobile Number"
                          className="form-control input-sm"
                          value={formData1.mobile1}
                          onChange={handleInputChange1}
                        />
                        {errors1.mobile1 && (
                          <span className="error" style={{ color: "red" }}>
                            {errors1.mobile1}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row cont-row">
                      <div className="col-sm-4">
                        <label> Message:</label>
                      </div>
                      <div className="col-sm-8">
                        <textarea
                          rows={5}
                          placeholder="Enter Your Message"
                          className="form-control input-sm"
                          defaultValue={""}
                          name="message1"
                          value={formData1.message1}
                          onChange={handleInputChange1}
                        />
                        {errors1.message1 && (
                          <span className="error" style={{ color: "red" }}>
                            {errors1.message1}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <label />
                      </div>
                      <div className="col-sm-8">
                        <button
                          className="btn btn-primary btn-sm"
                          style={{ marginLeft: "40px" }}
                          onClick={handleSubmit1}
                        >
                          Send Message
                        </button>
                        <ToastContainer
                          position="top-center"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="light"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ******************** About US Starts Here ******************* */}
      <div className="about-us big-padding">
        <div className="container-lg">
          <div className="section-title">
            <p>Help us to Achieve our Goal</p>
            <h2>About Our Company</h2>
          </div>
          <div className="about-row row">
            <div className="col-md-7">
              <div className="abut-detail fs-6">
                <p className="mb-3">
                  We Ashvaexperts are a group of technicians who joined hands
                  together in a passion for providing quality and best home
                  services in Bangalore, suchas water purification services,
                  Plumbing services, Home appliances services, Home Cleaning
                  Services, Packers & Movers Services, Home Electrical
                  Services/Repairs, Home Salon Service and much more.
                </p>

                <p className="mb-4">
                  We at Ashvaexperts work together to provide excellence in all
                  home services.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-5 p-4 " style={{ marginTop: "-40px" }}>
              <img src={ashva} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* ##################### Our Products Starts Here #################### */}
      <div className="container-fluid products big-padding px-3 bg-gray">
        <div className="container-xl">
          <div className="section-title row">
            <h2 className="fs-1 fw-bold">Our Products</h2>
            <p>We provide the all products for Rent</p>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 mb-4">
              <div className="product bg-white p-4 text-center shadow-md">
                <img className="mah-150" src={image1} alt="" />
                <div className="d-inline-block">
                  <h4 className="fw-bolder fs-5 mt-4 ms-3">SMART RO+UF</h4>
                  <span className="fw-bolder fs-4"> &#8377;399/-</span>
                </div>
                <div className="d-inline-block mt-3">
                  <button
                    className="btn btn-primary px-5"
                    onClick={handleClick}
                  >
                    Rent Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <div className="product bg-white p-4 text-center shadow-md">
                <img className="mah-150" src={image2} alt="" />
                <div className="d-inline-block">
                  <h4 className="fw-bolder fs-5 mt-4">SMART RO+UV+UF</h4>
                  <span className="fw-bolder fs-4">&#8377;499/-</span>
                </div>
                <div className="d-inline-block mt-3">
                  <button
                    className="btn btn-primary px-5"
                    onClick={handleClick}
                  >
                    Rent Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <div className="product bg-white p-4 text-center shadow-md">
                <img className="mah-150" src={image3} alt="" />
                <div className="d-inline-block">
                  <h4 className="fw-bolder fs-5 mt-4">SMART RO+UV+ALKALINE</h4>
                  <span className="fw-bolder fs-4"> &#8377;599/-</span>
                </div>
                <div className="d-inline-block mt-3">
                  <button
                    className="btn btn-primary px-5"
                    onClick={handleClick}
                  >
                    Rent Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ##################### Our Services #################### */}
      <div className="container-fluid products bg-gray big-padding pt-3">
        <div className="container-xl">
          <div className="section-title row">
            <h2 className="fs-1 fw-bold">Our Services</h2>
            <p>We provide the all products service</p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 mb-4 col-md-6 ch">
              <div className="cover text-center p-4 shadow-md bg-white">
                <img
                  className="w-125"
                  src="assets/images/services/flood.png"
                  alt=""
                />
                <h2 className="fs-4 fw-bolder mt-4">
                  WATER PURIFIER FULL SERVICE@ ₹2,999
                </h2>
                <ul class="list-group list-group-numbered">
                  <li class="list-group-item">
                    All filters and membranes will be replaced with Ashvaexperts
                    branded parts.
                  </li>
                  <li class="list-group-item">
                    Full RO checkup & Water quality will be tested.
                  </li>
                  <li class="list-group-item">
                    Recommended if the last service was done before one year.
                  </li>
                </ul>
                <p>
                  note: We don’t serve RO brands with chips/sensor-based filters
                  at the same cost.
                </p>
                {/* <p>Water Purifier Full Service @ ₹2,999</p> */}

                <div className="d-flex justify-content-center align-items-center">
                  <Button variant="primary" onClick={handleClickservice}>
                    Service Now
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 col-md-6 ch">
              <div className="cover text-center p-4 shadow-md bg-white">
                <img
                  className="w-125"
                  src="assets/images/services/002-drink-water.png"
                  alt=""
                />
                <h2 className="fs-4 fw-bolder mt-4">
                  WATER PURIFIER REGULAR SERVICE@ ₹1,499
                </h2>

                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">
                    All filters and membranes will be replaced with Ashvaexperts
                    branded parts.
                  </li>
                  <li class="list-group-item">
                    Full RO checkup & Water quality will be tested.
                  </li>
                  <li class="list-group-item">
                    Recommended if the last service was done six months back.
                  </li>
                </ol>
                <p>
                  *note: We don’t service RO brands with chips/sensor-based
                  filters at the same cost.
                </p>
                {/* <p>Water Purifier Regular Service @ ₹1,499</p> */}
                <div className="d-flex justify-content-center align-items-center">
                  <Button variant="primary" onClick={handleClickservice}>
                    Service Now
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 col-md-6 ch">
              <div className="cover text-center p-4 shadow-md bg-white">
                <img
                  className="w-125"
                  src="assets/images/services/water-heater.png"
                  alt=""
                />
                <h2 className="fs-4 fw-bolder mt-4">
                  WATER PURIFIER BASIC SERVICE @ ₹549
                </h2>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">
                    The Prefilter outside the filter will be changed
                  </li>
                  <li class="list-group-item">
                    Full RO checkup & Water quality will be tested.
                  </li>
                  <li class="list-group-item">
                    Recommended if the last service was done three months back
                  </li>
                </ol>

                <p>
                  *note: We don’t service RO brands with chips/sensor-based
                  filters at the same cost
                </p>
                {/* <p>Water Purifier Basic Service @ ₹549</p> */}
                <br />
                <div className="d-flex justify-content-center align-items-center">
                  <Button variant="primary" onClick={handleClickservice}>
                    Service Now
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4 col-md-6 ch">
              <div className="cover text-center p-4 shadow-md bg-white">
                <img className="w-125" src={filter} alt="" />
                <h2 className="fs-4 fw-bolder mt-4">
                  WATER PURIFIER GENERAL CHECKUP@ ₹349
                </h2>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">
                    Cleaning the machine and prefilter
                  </li>
                  <li class="list-group-item">RO and water testing</li>
                  <li class="list-group-item">
                    If needed for a filter change, will be suggested.
                  </li>
                </ol>
                <br />
                <p>
                  *Note: Repair and filter costs will be extra after a
                  suggestion.
                </p>
                {/* <p>Water Purifier General Check-Up @ ₹349</p> */}
                <br />
                <div className="d-flex justify-content-center align-items-center">
                  <Button variant="primary" onClick={handleClickservice}>
                    Service Now
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4 col-md-6 ch">
              <div className="cover text-center p-4 shadow-md bg-white">
                <img className="w-125" src={repair} alt="" />
                <h2 className="fs-4 fw-bolder mt-4">
                  {" "}
                  WATER PURIFIER REPAIR @₹249
                </h2>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">RO Not Working</li>
                  <li class="list-group-item">Water Leakage</li>
                  <li class="list-group-item">Low Water Flow.</li>
                  <li class="list-group-item">Foul Taste/smell</li>
                  {/* <li class="list-group-item">Any other repairs</li> */}
                </ol>
                <p>
                  *Note: In case of replacement of any spares to fix the Water
                  Purifier, additional charges will be added
                </p>
                {/* <p>Water Purifier Repair @ ₹249</p> */}
                <div className="d-flex justify-content-center align-items-center">
                  <Button variant="primary" onClick={handleClickservice}>
                    Service Now
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4 col-md-6 ch">
              <div className="cover text-center p-4 shadow-md bg-white">
                <img className="w-125" src={install} alt="" />
                <h2 className="fs-4 fw-bolder mt-4">
                  WATER PURIFIER INSTALLATION@ ₹399
                </h2>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">
                    We install your Water purifier
                  </li>
                </ol>
                <p>*Note:Spare costs if involved will be charged .</p>
                {/* <p>Water Purifier Installation @ ₹399</p> */}
                <div className="d-flex justify-content-center align-items-center">
                  <Button variant="primary" onClick={handleClickservice}>
                    Service Now
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4 col-md-6 ch">
              <div className="cover text-center p-4 shadow-md bg-white">
                <img className="w-125" src={uninstall} alt="" />
                <h2 className="fs-4 fw-bolder mt-4">
                  WATER PURIFIER UNINSTALLATION @₹300
                </h2>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">
                    We un-install your water purifier.
                  </li>
                </ol>
                <p>*Note:Spare costs if involved will be charged .</p>
                {/* <p>Water Purifier Uninstallation @ ₹300</p> */}
                <div className="d-flex justify-content-center align-items-center">
                  <Button variant="primary" onClick={handleClickservice}>
                    Service Now
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4 col-md-6 ch">
              <div className="cover text-center p-4 shadow-md bg-white">
                <img
                  className="w-125"
                  src="assets/images/services/001-save-water.png"
                  alt=""
                />
                <h2 className="fs-4 fw-bolder mt-4">
                  WATER PURIFIER RELOCATION
                </h2>
                <ol class="list-group list-group-numbered">
                  <li class="list-group-item">
                    We uninstall and reinstall the water purifier in a new
                    location.
                  </li>
                </ol>
                <p>
                  *Note: Spare costs & Transportation costs if involved, will be
                  charged .
                </p>
                <div className="d-flex justify-content-center align-items-center">
                  <Button variant="primary" onClick={handleClickservice}>
                    Service Now
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 col-md-6 ch">
              <div className="cover text-center p-4 shadow-md bg-white">
                <img
                  className="w-125"
                  src="assets/images/services/ph.png"
                  alt=""
                />
                <h2 className="fs-4 fw-bolder mt-4">OUR RATE CARD</h2>
                <div
                  className="scrollable-div"
                  style={{ overflowY: "auto", maxHeight: "200px" }}
                >
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>product</th>
                        <th>price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Spun</td>
                        <td>₹250</td>
                      </tr>
                      <tr>
                        <td>Spun(Threaded)</td>
                        <td>₹350</td>
                      </tr>
                      <tr>
                        <td>RO Membrane</td>
                        <td>₹1799</td>
                      </tr>
                      <tr>
                        <td>Pre Carbon</td>
                        <td>₹349</td>
                      </tr>
                      <tr>
                        <td>Sediment</td>
                        <td>₹349</td>
                      </tr>
                      <tr>
                        <td>Post Carbon</td>
                        <td>₹349</td>
                      </tr>
                      <tr>
                        <td>Booster Pump(100 GPD)</td>
                        <td>₹1999</td>
                      </tr>
                      <tr>
                        <td>Pipe Connecter</td>
                        <td>₹20</td>
                      </tr>
                      <tr>
                        <td>Pipe/Meter</td>
                        <td>₹30</td>
                      </tr>
                      <tr>
                        <td>Diverter</td>
                        <td>₹249</td>
                      </tr>
                      <tr>
                        <td>SV Coil</td>
                        <td>₹349</td>
                      </tr>
                      <tr>
                        <td>Float Valve</td>
                        <td>₹249</td>
                      </tr>
                      <tr>
                        <td>SMPS(1.5A)</td>
                        <td>₹999</td>
                      </tr>
                      <tr>
                        <td>Tap (normal)</td>
                        <td>₹149</td>
                      </tr>
                      <tr>
                        <td>FR</td>
                        <td>₹149</td>
                      </tr>
                      <tr>
                        <td>Cabinet</td>
                        <td>₹2999</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <Button variant="primary" onClick={handleClickservice}>
                    Service Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={showModal} style={customStyles}>
          <FaRegWindowClose onClick={handleClick} style={{ float: "right" }} />
          <h3>Complete the form below to get a free quote/estimate</h3>

          <form>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="name">NAME :</label>
              <input
                type="text"
                id="name"
                name="name2"
                value={formData2.name2}
                onChange={handleInputChange2}
              />
              <label htmlFor="name">MOBILE :</label>
              <input
                type="text"
                id="name"
                name="mobile2"
                value={formData2.mobile2}
                onChange={handleInputChange2}
              />
              <label htmlFor="email">EMAIL :</label>
              <input
                type="email"
                id="email"
                name="email2"
                value={formData2.email2}
                onChange={handleInputChange2}
              />
              <label htmlFor="email">RENT :</label>
              <input
                type="email"
                id="email"
                name="selectValue2"
                value={formData2.selectValue2}
                onChange={handleInputChange2}
              />
              <label htmlFor="message">MESSAGE:</label>
              <textarea
                id="message"
                name="message2"
                value={formData2.message2}
                onChange={handleInputChange2}
              ></textarea>{" "}
              <br></br>
              <button
                type="submit"
                style={{ width: "100px" }}
                onClick={handleSubmit2}
              >
                Submit
              </button>
            </div>
          </form>
          <h4 style={{ fontSize: "20px", marginTop: "10px" }}>
            <b>
              If this is an urgent service request, please call +91 8495998156
            </b>
          </h4>
        </Modal>
      </div>
      <div>
        <Modal isOpen={displayModal} style={customStyles}>
          <FaRegWindowClose
            onClick={handleClickservice}
            style={{ float: "right" }}
          />
          <h3>Complete the form below to get a free quote/estimate</h3>
          <h6>This is Service</h6>
          <form>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="name">NAME :</label>
              <input
                type="text"
                id="name"
                name="name3"
                value={formData3.name3}
                onChange={handleInputChange3}
              />
              <label htmlFor="name">MOBILE :</label>
              <input
                type="text"
                id="name"
                name="mobile3"
                value={formData3.mobile3}
                onChange={handleInputChange3}
              />
              <label htmlFor="email">EMAIL :</label>
              <input
                type="email"
                id="email"
                name="email3"
                value={formData3.email3}
                onChange={handleInputChange3}
              />
              <label htmlFor="email">SERVICE :</label>
              <input
                type="email"
                id="email"
                name="selectValue3"
                value={formData3.selectValue3}
                onChange={handleInputChange3}
              />
              <label htmlFor="message">MESSAGE:</label>
              <textarea
                id="message"
                name="message3"
                value={formData3.message3}
                onChange={handleInputChange3}
              ></textarea>{" "}
              <br></br>
              <button
                type="submit"
                style={{ width: "100px" }}
                onClick={handleSubmit3}
              >
                Submit
              </button>
            </div>
          </form>
          <h4 style={{ fontSize: "20px", marginTop: "10px" }}>
            <b>
              If this is an urgent service request, please call +91 8495998156
            </b>
          </h4>
        </Modal>
      </div>

      {/* ##################### youtube #################### */}

      <Container>
        <Row>
          <Col className="text-center">
            <h1>Youtube Videos</h1>
            <p>Review Videos</p>
          </Col>
        </Row>
      </Container>

      <Row style={{ width: "100%" }}>
        <Col md={4} className="mb-3" style={{ padding: "20px" }}>
          <Card>
            <YouTube
              videoId="03lJnuPVncc"
              opts={videoOptions}
              style={{ padding: "32px 16px" }}
            />
            <Card.Body>{/* <Card.Title>Card 1 Title</Card.Title> */}</Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3" style={{ padding: "20px" }}>
          <Card>
            <YouTube
              videoId="kZH6rQ39eow"
              opts={videoOptions}
              style={{ padding: "32px 16px" }}
            />
            <Card.Body>{/* <Card.Title>Card 2 Title</Card.Title> */}</Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3" style={{ padding: "20px" }}>
          <Card>
            <YouTube
              videoId="_Ix3Mbd4ZAY"
              opts={videoOptions}
              style={{ padding: "32px 16px" }}
            />
            <Card.Body>{/* <Card.Title>Card 3 Title</Card.Title> */}</Card.Body>
          </Card>
          <Button
            className="btn btn-success "
            style={{ display: "flex", float: "right", marginTop: "5px" }}
            variant="outlined"
          >
            <Link to="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww">
              show more
            </Link>
          </Button>
        </Col>
      </Row>

      {/* ##################### portfolio #################### */}

      <Container>
        <Row>
          <Col className="text-center">
            <h1>Portfolio</h1>
            {/* <p>Review Videos</p> */}
          </Col>
        </Row>
      </Container>

      <Row className="mx-0">
        <Col md={4}>
          <Card>
            <Card.Img
              variant="top"
              src={portfolio1}
              height="400px"
              style={{ padding: "20px" }}
            />
            <Card.Body>
              {/* <Card.Title>Card 1</Card.Title> */}
              {/* <Card.Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ultrices leo, vitae tristique orci. Sed ac ultrices leo, vitae tristique orci.
            </Card.Text> */}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Img
              variant="top"
              src={portfolio2}
              height="400px"
              style={{ padding: "20px" }}
            />
            <Card.Body>
              {/* <Card.Title>Card 2</Card.Title> */}
              {/* <Card.Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ultrices leo, vitae tristique orci. Sed ac ultrices leo, vitae tristique orci.
            </Card.Text> */}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Img
              variant="top"
              src={portfolio3}
              height="400px"
              style={{ padding: "20px" }}
            />
            <Card.Body>
              {/* <Card.Title>Card 3</Card.Title> */}
              {/* <Card.Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ultrices leo, vitae tristique orci. Sed ac ultrices leo, vitae tristique orci.
            </Card.Text> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/*- ############ Footer Starts Here ################## */}

      <Container fluid className="my-5">
        <footer
          className="text-center text-lg-start text-dark"
          style={{ backgroundColor: "#ECEFF1" }}
        >
          <div>
            <a href="tel:++91 8884567518" class="callme_float">
              <FaPhone />
            </a>
            <a
              href="https://wa.me/918495998156"
              class="message_float"
              target="_blank"
            >
              <FaWhatsapp />
            </a>
          </div>
          {/* Section: Social media */}
          <section
            className="d-flex justify-content-between p-4 text-white"
            style={{ backgroundColor: "#5CD2E6" }}
          >
            {/* Left */}
            <div className="me-5">
              
              <span ><Link to="/terms" >Terms and Conditions,</Link></span>
              <span ><Link to="/privacy" >Privacy Policy,</Link></span>
              <span ><Link to="/termuse" >Terms of use,</Link></span>
              <span ><Link to="/return" >Return & Refund Policy</Link></span>
            </div>
            {/* Left */}
            {/* Right */}
            <div>
            {/* <span>Get connected with us on social networks:</span> */}

              <a href="https://www.facebook.com/ashvaexpert" className="text-white me-4">
                <FaFacebook />
              </a>
              <a href="/" className="text-white me-4">
                <FaTwitter />
              </a>
              <a href="https://ashvaexperts.com/" className="text-white me-4">
                <FaGoogle />
              </a>
              <a href="https://www.instagram.com/ashvaexperts" className="text-white me-4">
                <FaInstagram />
              </a>
              <a href="/" className="text-white me-4">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww" className="text-white me-4">
                <FaYoutube />
              </a>
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
          {/* Section: Links */}
          <section>
            <Container className="text-center text-md-start mt-5">
              <Row className="mt-3">
                {/* Grid column */}
                <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI,
                    HOBLI, BANGALORE, KARNATAKA, INDIA -560060.
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Office timings</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      8AM-8PM MON-SAT
                    </a>
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={3} lg={2} xl={2} className="mx-auto mb-4 mr-2">
                  <h6 className="text-uppercase fw-bold">Email</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      <small>ASHVAEXPERTS.TEAM@GMAIL.COM</small>
                    </a>
                  </p>
                </Col>

                <Col
                  md={4}
                  lg={3}
                  xl={3}
                  className="mx-auto mb-md-0 mb-4 phone-column mr-10"
                  style={{ marginRight: "50px" }}
                >
                  <h6 className="text-uppercase fw-bold">Phone</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>8495998156</p>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Section: Links */}
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2" }}
          >
            © 2023 Copyright:
            <a className="text-dark" href="https://www.walkinsoftwares.com/">
              Designed and Developed by Walkin Software Technologies
            </a>
          </div>
          {/* Copyright */}
        </footer>
        {/* Footer */}
      </Container>
    </React.Fragment>
  );
}

export default Dashboard;
