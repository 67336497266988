import React from 'react'
import {
    Navbar,
    Nav,
    NavDropdown,
    Container,
    Button,
    Card,
    Table,
    Image,
    Col,
    Row,
  } from "react-bootstrap";
  import { Link, useNavigate } from "react-router-dom";
  import logo from "../images/ASHVA_LOGO_HEADER.png";
  import {
      FaFacebook,
      FaTwitter,
      FaGoogle,
      FaInstagram,
      FaLinkedin,
      FaYoutube,
    } from "react-icons/fa";
    import { FaPhone, FaWhatsapp, FaRegWindowClose } from "react-icons/fa";

const Privacy = () => {
    const googlePlayUrl = "https://play.google.com/store/apps";

  const nav = useNavigate();

  const login = (e) => {
    e.preventDefault();
    nav("/login");
  };

  return (
    <>
<Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/about">About Us</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/products">Products</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/plan">Plans</Link>
              </Nav.Link>
              {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
              <Nav.Link>
                <Link to="/contact">Contact Us</Link>
              </Nav.Link>
              <Nav.Link href={googlePlayUrl}>Google Playstore</Nav.Link>
            </Nav>
            
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ backgroundColor: "#EBE3D5" }}>
        <p>
          <h4 style={{ color: "blueviolet", textAlign: "center" }}>
            PRIVACY POLICY
          </h4>
        </p>
        <br />
        <p>Updated on 03-Oct-2023</p>
        <br />
        <h6 style={{ color: "blueviolet" }}>
          1. PRIVACY POLICY – INTRODUCTION:
        </h6>
        <p>
          1.1 Ashva Experts is committed to ensuring privacy of the Users on its
          website (“Website”) and/or mobile application (“Mobile App”). In
          furtherance of the same, Ashva Experts has framed this Privacy Policy
          in accordance with the Information Technology Act, 2000 (“Act”) read
          with the applicable Rules thereunder.
          <br />
          1.2 The Privacy Policy is designed to familiarise Users of this
          Website / Mobile App with (a) the type of information that Users may
          share with Ashva Experts, or that Ashva Experts will collect from the
          Users; (b) practices and policies of Ashva Experts relating to
          collection, storage, dealing, transfer, disclosure, transfer etc. of
          information pertaining to Users; (c) purpose of collection and usage
          of such information, and related matters.
          <br />
          1.3 This Privacy Policy forms part of the Terms of Use of the Website
          / Mobile App.
          <br />
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>2. IMPORTANT TERMS</h6>
        <p>
          2.1 Please read the terms of this Privacy Policy carefully. This
          Privacy Policy, along with the “Terms of Use” constitute an
          “electronic record” in the form of an “electronic contract” as defined
          under the Information Technology Act, 2000 between Ashva Experts and
          the User of this Website / Mobile App. This Privacy Policy does not
          require any physical, electronic or digital signature. <br />
          2.2 This Privacy Policy forms an integral part of the “Terms of Use”
          of the Website / Mobile App. If You do not agree with the terms of
          this Privacy Policy, please do not use this Website / Mobile App. By
          visiting this Website / using the Mobile App, you (a) unconditionally
          accept, and agree to be bound by the “Terms of Use” of the Website/
          Mobile App, read with this Privacy Policy; and (b) expressly consent
          to the collection, receipt, possession, storage, usage, dealing,
          handling or transfer of Your personal information by Ashva Experts in
          accordance with the terms of this Privacy Policy. <br />
          2.3 The Privacy Policy can undergo a change at any time at the
          discretion of Ashva Experts . Therefore, it is suggested that You
          regularly check the Website / Mobile App to be apprised of the latest
          changes made to the Privacy Policy.
          <br />
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>3.TYPES OF USER INFORMATION</h6>
        <p>
          3.1 Collection of “Information”: Ashva Experts may request for,
          collect or gather such “Information” (which shall include data) as it
          deems necessary, relevant or incidental for rendering an improved
          shopping experience to Users and for other purposes as specified in
          Paragraph 6 below.
          <br />
          3.2 Personal Information: Such “Information” may include Personal
          Information relating to Users such as (a) User’s name, address,
          telephone number, e-mail address, postal address, delivery address (if
          different), gender, purchase history etc. (b) financial information
          such as bank account or credit or debit card information; (c) physical
          condition of User; (d) a description of the item requested or
          purchased; (e) the internet protocol (“IP”) address of User’s
          computer; (f) any other “sensitive personal data or information”
          relating to the User as defined under the applicable Rules under the
          Act.
          <br />
          3.3 Non-personal Information: Ashva Experts may also collect certain
          non-personal Information from the User, when the User visits and / or
          uses the Website / Mobile App. Such non-personal Information would
          include but not be limited to information such as geographical
          location of User, IP address, type of browser, operating system of
          User’s device, and details of usage of Website / Mobile App etc. Such
          non-personal Information is collected through various ways including
          “cookies”. Cookies are text files placed on Your computer, to help the
          Website / Mobile App analyse how You use the Website / Mobile App.
          Such information is not used to track information about individuals,
          but is used on an aggregate level to customise our site, improve the
          online experience of the User and plan advertising and promotions.
          <br />
          3.4 Cookies, Web Beacons and Tracking Codes: Cookies are small text
          files that a website downloads onto your computer or other
          internet-enabled devices (such as mobile phones and tablets) when you
          visit a website. The cookie will help the Website / Mobile App
          recognise your device the next time you visit. We may use both session
          cookies (disappear after you close your browser) and persistent
          cookies (remain after you close your browser and may be accessed every
          time you use or connect to our Website / Mobile App). We may collect
          information like user’s IP address, geographical location,
          browser/device type and version, operating system, referral source,
          device, length of visit, page views and website navigation paths, as
          well as information about the timing, frequency and pattern of user
          website use through &quot;cookies&quot;.
          <br />
          You can block cookies by activating the setting on your browser that
          allows you to refuse the setting of all or some cookies. However, if
          you use your browser settings to block all cookies (including
          essential cookies), you may not be able to access all or parts of our
          site, or they may not function properly.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>
          4. MODES OF COLLECTION OF USER INFORMATION
        </h6>
        <p>
          4.1 The information referred to above may be collected or received by
          Ashva Experts (a) directly from the User, when the User either
          provides any specific information on the Website / Mobile App; or (b)
          from use of the Website / Mobile App by the User (including through
          IoT device placed in the Ashva Experts product purchased by the User);
          or (c) from third parties who have collected any Information relating
          to the User, and who have shared it with Ashva Experts .<br />
          4.2 There are times when You may provide information to us
          voluntarily, otherwise than through use of the Website / Mobile App.
          This occurs when You provide Your feedback to us through emails,
          return forms, letters or telephone calls. We may use this information
          to respond to Your query and to keep track of feedback received from
          You.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>
          5. PURPOSE OF COLLECTION AND USAGE OF INFORMATION
        </h6>
        <p>
          Ashva Experts may collect, store and use Information for any purpose
          as may be permissible under Applicable Laws, including but limited to
          the following:
          <br />
          (a) To display the User’s business listing or Product offerings across
          the Website / Mobile App to fetch maximum business opportunities for
          the User;
          <br />
          (b) Facilitation of Your use of the Website / Mobile App, handling and
          execution of orders placed on the Website / Mobile App by the User,
          process payments, communication with User about orders etc.;
          <br />
          (c) Respond to any inquiries posed by the User;
          <br />
          (d) Provide User with details of important schemes, offers, Product
          details, new Product launches, changes to terms of use of the Website
          / Mobile App, order details, delivery details and make other important
          announcements etc. ;<br />
          (e) Maintain accounts of the User, display contents inputted by User
          such as User profile, User wishlist etc.;
          <br />
          (f) Help recommend to the User merchandise and services that may be of
          interest to him / her;
          <br />
          (g) Personalise User’s experience on the Website / Mobile App by
          presenting advertisements, Products and offers tailored to the User’s
          preferences;
          <br />
          (h) Customization, administration etc. of the Website / Mobile App,
          location of errors, Website / Mobile App testing, data analysis for
          the Website etc;
          <br />
          (i) Provision of various services on the Website / Mobile App;
          <br />
          (j) To protect integrity of the Website / Mobile App, improve our
          platform, prevent or detect fraud or abuse of our Website / Mobile
          App;
          <br />
          (k) To conduct analytical studies on various aspects including User
          behaviour, User preferences etc.
          <br />
          (l) Enable third parties to carry out technical, logistical or other
          functions on our behalf;
          <br />
          (m) To permit employees of the Company to contact Users, and enable
          them to implement the orders placed by Users, resolve User queries,
          issues, grievances etc.;
          <br />
          (n) To further improve the product and/or services, to facilitate the
          servicing of User’s product and/or to give insight on User’s product
          experience.
          <br />
          (o) To trace computer resources of any person for the purposes of
          determining compliance with the provisions of the Information
          Technology Act, 2000 and / or any other law for the time being in
          force.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>
          6. DISCLOSURE AND RETENTION OF USER INFORMATION
        </h6>
        <p>
          6.1 Ashva Experts considers the Information about its customers as an
          important part of its business. Accordingly, Ashva Experts shall not
          engage in sale of Information relating to Users to third parties.
          However, Ashva Experts may share User information with third parties
          in the circumstances specified herein below, after reasonably assuring
          itself that such third parties have undertaken to maintain
          confidentiality of Personal Information relating to the Users: (a)
          Third Party Vendors / Service Providers etc.: Personal Information
          relating to Users may be made available to the third party Vendors,
          distributors etc. who sell their Products on the Website / Mobile App.
          Further, Ashva Experts may engage third party service providers to
          render various services, and perform various functions in relation to
          the business undertaken on the Website / Mobile App, and / or for the
          Purpose(s) discussed in Paragraph 6 above. For instance, Ashva Experts
          may engage third party service providers for maintenance of its
          Website / Mobile App, fulfilment of orders, delivery of packages,
          analysing data, providing marketing assistance, processing of credit
          card payments, provision of customer services etc. Such third party
          service providers / Vendors would have access to Personal Information
          of Users for the purpose of performing their functions / rendering
          their services etc. (b) Business Transfers: Ashva Experts may transfer
          or otherwise share some or all of its assets, including Your
          Information in connection with a merger, acquisition, reorganisation
          or sale of assets or business or in the event of bankruptcy. Should
          such a sale or transfer occur, Ashva Experts will reasonably ensure
          that the Information You have provided and which we have collected is
          stored and used by the transferee in a manner that is consistent with
          this Privacy Policy. Any third party to which Ashva Experts transfers
          or sells as aforesaid will have the right to continue to use the
          information that You provide to us or collected by us immediately
          prior to the transfer. (c) Government Agency: Ashva Experts may share
          any Information relating to Users (i) with Government agencies
          mandated under the law to obtain Information relating to Users from
          Ashva Experts ; (ii) any third party, when Ashva Experts is required
          to disclose the same under an order of a government or judicial
          authority under any law for the time being in force, or where such
          disclosure is necessary for the compliance of a legal obligation. (d)
          With User Consent: Without prejudice to the aforesaid, Ashva Experts
          may disclose Personal Information relating to the User with his / her
          / its consent. For this purpose, Ashva Experts may send a prior notice
          to the User before sharing Personal Information relating to the User
          with third parties. In case no objection or intimation is received
          from the User, Ashva Experts would presume that User has granted its
          consent for sharing of said Information with third parties. By using
          or visiting the Website / Mobile App and agreeing to the terms of this
          Privacy Policy, User shall be construed to have consented to, and
          accepted the disclosure of his Information to third parties as
          provided under this Privacy Policy.
          <br />
          6.2 Ashva Experts will share Your Personal Information internally with
          such persons who need it to complete Your purchase or carry out Your
          instructions regarding the receipt of marketing information.
          <br />
          6.3 Ashva Experts shall keep sensitive personal data or information
          for only as long as the purposes for which the information may
          lawfully be used or is otherwise required under any other law for the
          time being in force. For those customers who have registered for the
          mailing list, their personal information is kept until we are notified
          that they no longer want their information stored.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>
          7. SECURITY PRACTICES AND PROCEDURES FOR PROTECTION OF INFORMATION
        </h6>
        <p>
          In order to make every effort to ensure that Your experience on the
          Website / Mobile App is secure, we use encryption technology to
          protect You against the loss, misuse or alteration of Your personal
          information. When You fill out any contact forms or access Your
          account, a secure server encrypts all of Your information through the
          use of Secure Socket Layers (SSLs). To be sure You are browsing secure
          pages for transactions, check Your Web browser’s status bar (located
          at the bottom of the window) for the closed padlock icon. This icon
          appears in Your web browser to tell You that You are viewing a secure
          web page. Also, all browsers display an “s” after the “http”
          (https://) in the Web site address to indicate that You are in a
          secure environment.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>
          8. ACCURACY AND PROTECTION OF PERSONAL INFORMATION
        </h6>
        <p>
          Ashva Experts relies on Users to notify it of any changes in personal
          information. Should any inaccurate information come to Ashva
          Experts&#39;s attention, it will investigate and correct the
          information and, if necessary, apprise the User of the change. Only
          those staff members who need the User’s personal information in order
          to respond to the User’s requests are given access to it. Employees
          are provided with training and information regarding the proper
          handling of personal information. All information stored in Ashva
          Experts&#39;s computer system is protected from unauthorised access
          and information that is stored in document form is kept in secure
          locations to prevent access by unauthorised persons.
        </p>{" "}
        <br />
        <h6 style={{ color: "blueviolet" }}>
          9. USER DISCRETION AND THE CHOICE TO OPT OUT
        </h6>
        <p>
          You can block cookies by activating the setting on your browser that
          allow you to refuse the setting of all or some cookies. However, if
          you use your browser settings to block all cookies (including
          essential cookies), you may not be able to access all or parts of our
          site, or they may not function properly. The Users have a choice to
          opt-out of receiving non-essential (promotional, marketing related)
          communications from us, after setting up an account. In order to
          remove Your contact information, please visit Unsubscribe.
        </p>{" "}
        <br />
        <h6 style={{ color: "blueviolet" }}>10. GRIEVANCE OFFICER</h6>
        <p>
          In compliance with Information Technology Act, 2000 and rules made
          thereunder, the name and contact details of the Grievance Officer are
          provided below:
          <br />
          <p>Name: Ashva Experts</p>
          <br />
          <p>
            Address:
            <br />
            220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI, HOBLI,
            BANGALORE, KARNATAKA, INDIA -560060.
          </p>
          <br />
          <p>Phone: +91-8495998156</p>
          <br />
          <p>Email: support@ashvaexperts.com</p>
          <br />
          <p>Time: 9:00 AM to 6:00 PM</p>
          <br />
        </p>
        <h6 style={{ color: "blueviolet" }}>
          11. UPDATES TO THIS PRIVACY POLICY
        </h6>
        <p>
          We may periodically update the Privacy Policy. Please refer to the
          Update on legend at the top of this page to see when this Privacy
          Policy was last revised. Any changes to this Privacy Policy will
          become effective when we post the revised Privacy Policy on our
          Website / Mobile App. Your use of the Website / Mobile App following
          these changes means that you accept the revised Privacy Policy.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>GOVERNING LAW</h6>
        <p>
          The Website and Mobile App are designed and targeted to users who
          reside in the Republic of India and are governed by and operated in
          accordance with the laws of India. By accessing and/or providing
          personal information through the Website / Mobile App, you confirm
          that you meet the legal requirements for disclosure of personal
          information in your jurisdiction.
        </p>
      </div>
      <div>
      <Container fluid className="my-5">
        <footer
          className="text-center text-lg-start text-dark"
          style={{ backgroundColor: "#ECEFF1" }}
        >
          <div>
            <a href="tel:++91 8884567518" class="callme_float">
              <FaPhone />
            </a>
            <a
              href="https://wa.me/918495998156"
              class="message_float"
              target="_blank"
            >
              <FaWhatsapp />
            </a>
          </div>
          {/* Section: Social media */}
          <section
            className="d-flex justify-content-between p-4 text-white"
            style={{ backgroundColor: "#5CD2E6" }}
          >
            {/* Left */}
            <div className="me-5">
              
              <span ><Link to="/terms" >Terms and Conditions,</Link></span>
              <span ><Link to="/privacy" >Privacy Policy,</Link></span>
              <span ><Link to="/termuse" >Terms of use,</Link></span>
              <span ><Link to="/return" >Return & Refund Policy</Link></span>
            </div>
            {/* Left */}
            {/* Right */}
            <div>
            {/* <span>Get connected with us on social networks:</span> */}

              <a href="https://www.facebook.com/ashvaexpert" className="text-white me-4">
                <FaFacebook />
              </a>
              <a href="/" className="text-white me-4">
                <FaTwitter />
              </a>
              <a href="https://ashvaexperts.com/" className="text-white me-4">
                <FaGoogle />
              </a>
              <a href="https://www.instagram.com/ashvaexperts" className="text-white me-4">
                <FaInstagram />
              </a>
              <a href="/" className="text-white me-4">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww" className="text-white me-4">
                <FaYoutube />
              </a>
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
          {/* Section: Links */}
          <section>
            <Container className="text-center text-md-start mt-5">
              <Row className="mt-3">
                {/* Grid column */}
                <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI,
                    HOBLI, BANGALORE, KARNATAKA, INDIA -560060.
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Office timings</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      8AM-8PM MON-SAT
                    </a>
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={3} lg={2} xl={2} className="mx-auto mb-4 mr-2">
                  <h6 className="text-uppercase fw-bold">Email</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      <small>ASHVAEXPERTS.TEAM@GMAIL.COM</small>
                    </a>
                  </p>
                </Col>

                <Col
                  md={4}
                  lg={3}
                  xl={3}
                  className="mx-auto mb-md-0 mb-4 phone-column mr-10"
                  style={{ marginRight: "50px" }}
                >
                  <h6 className="text-uppercase fw-bold">Phone</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>8495998156</p>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Section: Links */}
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2" }}
          >
            © 2023 Copyright:
            <a className="text-dark" href="https://www.walkinsoftwares.com/">
              Designed and Developed by Walkin Software Technologies
            </a>
          </div>
          {/* Copyright */}
        </footer>
        {/* Footer */}
      </Container>
      </div>
    </>
  )
}

export default Privacy