import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { AiOutlineClose } from "react-icons/ai"; // Import the cancel (x) symbol icon
import { FaPlus } from "react-icons/fa";
import "./AddressHistory.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Contact from "./Contact";
import axios from "axios";
import { createAddress, fetchAddressesByCustomerId } from "./AddressHistoryApi";
import { useEffect } from "react";

const AddressHistory = () => {
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    address: "",
    city: "",
    pincode: "",
    state: "Select State",
  });

  const [formErrors, setFormErrors] = useState({
    fullName: "",
    address: "",
    city: "",
    pincode: "",
    state: "",
  });
  const [contacts, setContacts] = useState([]);
  const [editAddress, setEditAddress] = useState(null); // State to hold the address being edited
  const [customerId, setCustomerId] = useState("");

  //!Tokens and Headers
  const user = JSON.parse(sessionStorage.getItem("user"));
  const headers = {
    "Content-type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  //!USEEFFECT
  useEffect(() => {
    FetchData();
  }, [contacts]);

  //todo ==> GET  customer DATA by customer id

  const FetchData = async (customerId) => {
    let res = await fetchAddressesByCustomerId(headers);
    // console.log(res.data);
    var fetchedData = res.data;
    // console.log(fetchedData);

    var tabledata = [];
    fetchedData.map((a) => {
      tabledata.push({
        addressId: a.addressId,
        fullName: a.fullName,
        address: a.address,
        city: a.city,
        pincode: a.pincode,
        state: a.state,
      });
    });
    // console.log(tabledata);
    setContacts(tabledata);
  };

  // Function to update formData
  const updateFormData = (newData, newState, updatedData) => {
    setFormData({ ...formData, ...newData, state: newState });
    console.log("New State:", newState);
    // Find the index of the contact being updated in the state array
    const contactIndex = contacts.findIndex(
      (contact) => contact.addressId === newData.addressId
    );

    // If the contact is found, update it in the state
    if (contactIndex !== -1) {
      const updatedContacts = [...contacts];
      updatedContacts[contactIndex] = newData;
      setContacts(updatedContacts);
    }
  };

  const handleEditClick = (addressId) => {
    // Find the selected address based on addressId in contacts array
    const selectedAddress = contacts.find(
      (contact) => contact.addressId === addressId
    );

    // Set the selected address in the state for editing
    setEditAddress(selectedAddress);
    console.log(addressId);
    setShowAddressForm(!showAddressForm); // Show the address form
    setFormData(selectedAddress); // Populate the form with the selected address
  };

  const handleDeleteClick = (id) => {
    // Handle delete click logic
  };

  const stateOptions = [
    "Select State",
    "karnataka",

    // Add more state names here
  ];

  const toggleAddressForm = () => {
    setShowAddressForm(!showAddressForm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear the validation error for the field being edited
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.fullName) {
      errors.fullName = "Full Name is required.";
    }
    if (!formData.address) {
      errors.address = "Address is required.";
    }
    if (!formData.city) {
      errors.city = "City is required.";
    }
    if (!formData.pincode) {
      errors.pincode = "Pincode is required.";
    }
    if (formData.state === "Select State") {
      errors.state = "Please select a State.";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        // Add addressId and customerId to formData
        const dataToSend = {
          ...formData,
          addressId: editAddress ? editAddress.addressId : null, // Include addressId if in edit mode
          customerId: customerId, // Include the customerId
        };
        const response = await createAddress(formData, user.accessToken);

        // Handle the response from the API
        console.log("Address created successfully:", response);

        // Optionally, reset the form and update your address list with the new data
        setFormData({
          fullName: "",
          address: "",
          city: "",
          pincode: "",
          state: "Select State",
        });
        toggleAddressForm();
      } catch (error) {
        console.error("Error creating address:", error);
        // Handle error scenarios here
      }
    }
  };

  return (
    <>
      <Container>
        <strong>Manage Addresses</strong>
        <br />
        <br />
        {showAddressForm ? (
          <div className="add-address-box active">
            <Form onSubmit={handleSubmit}>
              <div className="form-header">
                <h5>
                  Add New Address
                  <AiOutlineClose
                    className="cancel-symbol"
                    onClick={toggleAddressForm}
                  />
                </h5>
              </div>

              <Form.Group controlId="formFullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  placeholder="Enter Full Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  isInvalid={!!formErrors.fullName}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.fullName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="Enter Address"
                  value={formData.address}
                  onChange={handleChange}
                  isInvalid={!!formErrors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.address}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="Enter City"
                  value={formData.city}
                  onChange={handleChange}
                  isInvalid={!!formErrors.city}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.city}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPincode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="text"
                  name="pincode"
                  placeholder="Enter Pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  isInvalid={!!formErrors.pincode}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.pincode}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formStates">
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  isInvalid={!!formErrors.state}
                >
                  {stateOptions.map((stateName, index) => (
                    <option key={index}>{stateName}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formErrors.state}
                </Form.Control.Feedback>
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        ) : (
          <div className="add-address-box">
            <span onClick={toggleAddressForm} style={{ color: "blue" }}>
              <FaPlus className="plus-icon" /> ADD A NEW ADDRESS
            </span>
          </div>
        )}
      </Container>
      <br />
      <div>
        <Container>
          {contacts.map((contact) => (
            <Contact
              key={contact.id}
              contact={contact}
              onEditClick={() => handleEditClick(contact.addressId)}
              onDeleteClick={() =>
                handleDeleteClick(contact.addressId, FetchData)
              }
              updateFormData={updateFormData} // Pass the callback function
              addressId={contact.addressId} // Pass the addressId as a prop
              customerId={customerId} // Pass the customerId as a prop
            />
          ))}
        </Container>
      </div>
    </>
  );
};

export default AddressHistory;
