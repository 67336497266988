import React from 'react';
import {
    Navbar,
    Nav,
    NavDropdown,
    Container,
    Button,
    Card,
    Table,
    Image,
    Dropdown,
  } from "react-bootstrap";
  import { Link } from "react-router-dom";
import logo from "../images/ASHVA_LOGO_HEADER.png";
import { FaPhone, FaWhatsapp, FaRegWindowClose } from "react-icons/fa";
import { BsFillPersonFill, BsGear, BsBoxArrowRight } from 'react-icons/bs'

const ServiceHistory = () => {
  return (
    <>
    {/*- ############ Navbar Starts Here ################## */}
    <Navbar bg="info" expand="lg" >
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/homedash">Dashboard</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/rental">Renatl history</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/service">Service history</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/payments">Payments</Link>
              </Nav.Link>

              <Nav.Link>
                <Link to="/address">Address</Link>
              </Nav.Link>

             
            </Nav>

            <Dropdown >
      <Dropdown.Toggle
        as="a"
        className="dropdown-toggle d-flex align-items-center hidden-arrow"
        href="#"
        id="navbarDropdownMenuAvatar"
        role="button"
        data-mdb-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
          className="rounded-circle"
          height="25"
          alt="Black and White Portrait of a Man"
          loading="lazy"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu dropdown-menu-end"
        aria-labelledby="navbarDropdownMenuAvatar"
      >
        <Dropdown.Item href="#">
          <BsFillPersonFill className="me-2" /> My Profile
        </Dropdown.Item>
        <Dropdown.Item href="#">
          <BsGear className="me-2" /> Settings
        </Dropdown.Item>
        <Dropdown.Item href="#">
          <BsBoxArrowRight className="me-2" /> Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
              
          </Navbar.Collapse>
        </Container>
      </Navbar>
       
       {/*- ############ content Starts Here ################## */}
<p>welcome to Service history</p>
      
      
      {/*- ############ Footer Starts Here ################## */}

      <footer>
        <div>
          <a href="tel:++91 8884567518" class="callme_float">
            <FaPhone />
          </a>
          <a
            href="https://wa.me/918495998156"
            class="message_float"
            target="_blank"
          >
            <FaWhatsapp />
          </a>
        </div>
        <div
          className="inner "
          style={{ backgroundColor: "#1E88E5", color: "#000000" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Contact Details
                </h6>
                <div>
                  <ul style={{ textAlign: "center" }}>
                    <li>220, 6TH CROSS, GANAKALLU,</li>
                    <li>SRINIVASAPURA COLONY KENGERI, HOBLI,</li>
                    <li>BANGALORE, KARNATAKA, INDIA -560060.</li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Phone</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>8495998156</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Email</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>
                      ASHVAEXPERTS.TEAM@GMAIL.COM
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Office timings
                </h6>
                <div>
                  <ul className="row">
                    <li style={{ textAlign: "center" }}>
                      OPEN 8AM-8PM:MONDAY - SATURDAY
                    </li>

                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <a
                        href="https://www.facebook.com/ashvaexpert"
                        style={{ color: "white" }}
                      >
                        <i className="bi bi-facebook" />
                      </a>
                    </li>
                    {/* <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-twitter" />
                    </li> */}
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <a
                        href="https://www.instagram.com/ashvaexperts/"
                        style={{ color: "white" }}
                      >
                        <i className="bi bi-instagram" />
                      </a>
                    </li>
                    {/* <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-linkedin" />
                    </li> */}
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <a
                        href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww"
                        style={{ color: "white" }}
                      >
                        <i className="bi bi-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy">
        <div className="container" style={{ textAlign: "center" }}>
          <a
            href="https://www.walkinsoftwares.com/"
            style={{ textDecorationLine: "none" }}
          >
            2022 © All Rights Reserved | Designed and Developed by Walkin
            Software Technologies
          </a>
        </div>
      </div>
    </>
  )
}

export default ServiceHistory