import React from 'react';
import {
    Navbar,
    Nav,
    NavDropdown,
    Container,
    Button,
    Card,
    Table,
    Image,
    Col,
    Row,
  } from "react-bootstrap";
  import { Link, useNavigate } from "react-router-dom";
  import logo from "../images/ASHVA_LOGO_HEADER.png";
  import {
      FaFacebook,
      FaTwitter,
      FaGoogle,
      FaInstagram,
      FaLinkedin,
      FaYoutube,
    } from "react-icons/fa";
    import { FaPhone, FaWhatsapp, FaRegWindowClose } from "react-icons/fa";

const Return = () => {
    const googlePlayUrl = "https://play.google.com/store/apps";

  const nav = useNavigate();

  const login = (e) => {
    e.preventDefault();
    nav("/login");
  };

  return (
    <>
   <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/about">About Us</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/products">Products</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/plan">Plans</Link>
              </Nav.Link>
              {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
              <Nav.Link>
                <Link to="/contact">Contact Us</Link>
              </Nav.Link>
              <Nav.Link href={googlePlayUrl}>Google Playstore</Nav.Link>
            </Nav>
            {/* <Nav className="justify-content-end">
              <Button variant="primary" onClick={login}>
                Sign In
              </Button>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ backgroundColor: "#EBE3D5" }}>
        <p>
          <h4 style={{ color: "blueviolet", textAlign: "center" }}>
            RETURN &amp; REFUND POLICY
          </h4>
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>What is your return policy?</h6>
        <p>
          We offer return/replacement of Products ordered on Livpure Smart
          within 7 days of delivery of the order. You are welcome to try a
          product but please take adequate measures to preserve its condition.
          All items to be returned or exchanged must be in their original
          condition with all original tags and packaging intact.
        </p>{" "}
        <br />
        <h6 style={{ color: "blueviolet" }}>What is your return policy?</h6>
        <p>
          If you have paid for the order using a bank account/credit card/debit
          card, then the refunded amount gets credited to your bank/credit card
          account within 7 business days from the date of by Ashva Experts,
          after the products clear the Q.C process. If you have paid for the
          order using LR Credits, then the amount gets refunded to your LR
          account within 5-10 business days from date of product clearing Q.C at
          our Warehouses. Please note that in case of any refund to your bank
          account you shall need to comply with the Company’s Policy in this
          regard which shall be a conclusive undertaking at your end in all
          aspects. Please further note that you shall be completely responsible
          for providing correct bank details and the company shall not be
          responsible in case wrong information has been provided to it.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>How do I return my order?</h6>
        <p>
          You can contact our customer care executive on +91 8495998156 <br/>
          Working Hours : 10AM-7PM, All Days or you can write to us on<br/>
          support@ashvaexperts.com You will get a refund once your order is<br/>
          picked up &amp; checked for quality subject to applicable exceptions.<br/>
        </p><br/>
      </div>
      <Container fluid className="my-5">
        <footer
          className="text-center text-lg-start text-dark"
          style={{ backgroundColor: "#ECEFF1" }}
        >
          <div>
            <a href="tel:++91 8884567518" class="callme_float">
              <FaPhone />
            </a>
            <a
              href="https://wa.me/918495998156"
              class="message_float"
              target="_blank"
            >
              <FaWhatsapp />
            </a>
          </div>
          {/* Section: Social media */}
          <section
            className="d-flex justify-content-between p-4 text-white"
            style={{ backgroundColor: "#5CD2E6" }}
          >
            {/* Left */}
            <div className="me-5">
              
              <span ><Link to="/terms" >Terms and Conditions,</Link></span>
              <span ><Link to="/privacy" >Privacy Policy,</Link></span>
              <span ><Link to="/termuse" >Terms of use,</Link></span>
              <span ><Link to="/return" >Return & Refund Policy</Link></span>
            </div>
            {/* Left */}
            {/* Right */}
            <div>
            {/* <span>Get connected with us on social networks:</span> */}

              <a href="https://www.facebook.com/ashvaexpert" className="text-white me-4">
                <FaFacebook />
              </a>
              <a href="/" className="text-white me-4">
                <FaTwitter />
              </a>
              <a href="https://ashvaexperts.com/" className="text-white me-4">
                <FaGoogle />
              </a>
              <a href="https://www.instagram.com/ashvaexperts" className="text-white me-4">
                <FaInstagram />
              </a>
              <a href="/" className="text-white me-4">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww" className="text-white me-4">
                <FaYoutube />
              </a>
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
          {/* Section: Links */}
          <section>
            <Container className="text-center text-md-start mt-5">
              <Row className="mt-3">
                {/* Grid column */}
                <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI,
                    HOBLI, BANGALORE, KARNATAKA, INDIA -560060.
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Office timings</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      8AM-8PM MON-SAT
                    </a>
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={3} lg={2} xl={2} className="mx-auto mb-4 mr-2">
                  <h6 className="text-uppercase fw-bold">Email</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      <small>ASHVAEXPERTS.TEAM@GMAIL.COM</small>
                    </a>
                  </p>
                </Col>

                <Col
                  md={4}
                  lg={3}
                  xl={3}
                  className="mx-auto mb-md-0 mb-4 phone-column mr-10"
                  style={{ marginRight: "50px" }}
                >
                  <h6 className="text-uppercase fw-bold">Phone</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>8495998156</p>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Section: Links */}
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2" }}
          >
            © 2023 Copyright:
            <a className="text-dark" href="https://www.walkinsoftwares.com/">
              Designed and Developed by Walkin Software Technologies
            </a>
          </div>
          {/* Copyright */}
        </footer>
        {/* Footer */}
      </Container>

    </>
  )
}

export default Return