import React from 'react'
import { Link } from 'react-router-dom';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Card,
  Table,
  Col,
  Row,
} from "react-bootstrap";
import logo from  '../images/ASHVA_LOGO_HEADER.png';
import './About.css';
import{ FaPhone,
  FaWhatsapp,FaRegWindowClose} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ashva from '../images/ashva.png';
import {
  FaFacebook,
  FaTwitter,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";




const About = () => {


  const googlePlayUrl="https://play.google.com/store/apps";
  
  const nav=useNavigate();

  const login=(e)=>{
    e.preventDefault();
    nav("/login")
  }



  return (
    <React.Fragment>
     {/*  ************************* navbar ************************** */}
   <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link ><Link to='/'>Home</Link></Nav.Link>
              <Nav.Link ><Link to='/about'>About Us</Link></Nav.Link>
              <Nav.Link ><Link to='/products'>Products</Link></Nav.Link>
              <Nav.Link ><Link to='/plan'>Plans</Link></Nav.Link>
              {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
              <Nav.Link ><Link to='/contact'>Contact Us</Link></Nav.Link>
              <Nav.Link href={googlePlayUrl}>
               Google Playstore
              </Nav.Link>
            </Nav>
            {/* <Nav className="justify-content-end">
              <Button variant="primary" onClick={login}>Sign In</Button>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
        
       
        {/*  ************************* Page Title Starts Here ************************** */}
        <div className="page-nav no-margin row" style={{paddingTop:"0px"}}>
          <div className="container">
            <div className="row">
              <h2 className="text-start">About Our Company</h2>
              <ul>
                <li> <a href="#"><i className="bi bi-house-door" /> Home</a></li>
                <li><i className="bi bi-chevron-double-right pe-2" /> About Us</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="about-us big-padding">
          <div className="container-lg">
            <div className="section-title">
              <p>Help us to Achieve our Goal</p>
              <h2>About Our Company</h2>
            </div>
            <div className="about-row row">
              <div className="col-md-7">
                <div className="abut-detail fs-6">
                <p className="mb-3">We Ashvaexperts are a group of technicians who joined hands together in a passion for providing quality and best home services in Bangalore, suchas water purification services, Plumbing services, Home appliances services, Home Cleaning Services, Packers & Movers Services, Home Electrical Services/Repairs, Home Salon Service and much more.</p>
                 
                  <p className="mb-4">We at Ashvaexperts work together to provide excellence in all home services. </p>
                </div>
              </div>
              <div className="col-md-5 p-4">
                <img src={ashva} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="out-team container-fluid big-padding bg-gray">
          <div className="container">
            <div className="row section-title">
              <h2>Our Team</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque  consectetur</p>
            </div>
            <div className="row contact-rooo big-padding no-margin">
          <div className="container">
            <div className="row">
              <div  className="col-sm-7">
                <h2 className="fs-4 fw-bold">Rent Now</h2> <br />
                <div className="row cont-row">
                  <div className="col-sm-3"><label>Enter Name </label><span>:</span></div>
                  <div className="col-sm-6"><input type="text" placeholder="Enter Name" name="name" className="form-control input-sm" /></div>
                </div>
                <div className="row cont-row">
                  <div className="col-sm-3"><label>Email Address </label><span>:</span></div>
                  <div className="col-sm-6"><input type="text" name="name" placeholder="Enter Email Address" className="form-control input-sm" /></div>
                </div>
                <div className="row cont-row">
                  <div className="col-sm-3"><label>Product Types </label><span>:</span></div>
                  <div className="col-sm-6">
                  <input type="text" name="name" placeholder="Type service" className="form-control input-sm" />
                  <select class="form-select" aria-label="Default select example">
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
                  </div>
              
                </div>
                <br/>
                <div className="row cont-row">
                  <div className="col-sm-3"><label>Mobile Number</label><span>:</span></div>
                  <div className="col-sm-6"><input type="text" name="name" placeholder="Enter Mobile Number" className="form-control input-sm" /></div>
                  
                </div>
                <div className="row cont-row">
                  <div className="col-sm-3"><label>Enter Message</label><span>:</span></div>
                  <div className="col-sm-6">
                    <textarea rows={5} placeholder="Enter Your Message" className="form-control input-sm" defaultValue={""} />
                  </div>
                </div>
                <div style={{marginTop: '10px'}} className="row">
                  <div style={{paddingTop: '10px'}} className="col-sm-3"><label /></div>
                  <div className="col-sm-8">
                    <button className="btn btn-primary btn-sm">Send Message</button>
                  </div>
                </div>
              </div>
              <div className="col-sm-5">    
                <div  className="serv"> 
                  <h2 className="fs-4 fw-bold" >Service Now</h2>
                  <br/>
                  <div className="row cont-row">
                  <div className="col-sm-4"><label>Enter Name </label><span>:</span></div>
                  <div className="col-sm-8"><input type="text" placeholder="Enter Name" name="name" className="form-control input-sm" /></div>
                </div>
                <div className="row cont-row">
                  <div className="col-sm-4"><label>Email Address </label><span>:</span></div>
                  <div className="col-sm-8"><input type="text" name="name" placeholder="Enter Email Address" className="form-control input-sm" /></div>
                </div>
                <div className="row cont-row">
                  <div className="col-sm-4"><label>Service Types </label><span>:</span></div>
                  <div className="col-sm-8">
                  <input type="text" name="name" placeholder="Type service" className="form-control input-sm" />
                  <select class="form-select" aria-label="Default select example">
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
                  </div>
              
                </div>
                <br/>
                <div className="row cont-row">
                  <div className="col-sm-4"><label>Mobile Number</label><span>:</span></div>
                  <div className="col-sm-8"><input type="text" name="name" placeholder="Enter Mobile Number" className="form-control input-sm" /></div>
                  
                </div>
                <div className="row cont-row">
                  <div className="col-sm-4"><label>Enter Message</label><span>:</span></div>
                  <div className="col-sm-8">
                    <textarea rows={5} placeholder="Enter Your Message" className="form-control input-sm" defaultValue={""} />
                  </div>
                </div>
                <div style={{marginTop: '10px'}} className="row">
                  <div style={{paddingTop: '10px'}} className="col-sm-3"><label /></div>
                  <div className="col-sm-8">
                    <button className="btn btn-primary btn-sm" style={{marginLeft:"40px"}}>Send Message</button>
                  </div>
                </div>
                </div>    
              </div>
            </div>
          </div>
        </div>
            <div className="team-row row">
              <div className="col-md-3 mb-4">
                <div className="teamc shadow-md text-center bg-white p-2">
                  <img src="assets/images/team/1.jpg" alt="" />
                  <h4 className="fs-5 mt-3 fw-bolder mb-0">Manoj Parikar</h4>
                  <span className="fs-8">CEO Shaw Analysis</span>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="teamc shadow-md text-center bg-white p-2">
                  <img src="assets/images/team/2.jpg" alt="" />
                  <h4 className="fs-5 mt-3 fw-bolder mb-0">James Anderson</h4>
                  <span className="fs-8">CEO Shaw Analysis</span>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="teamc shadow-md text-center bg-white p-2">
                  <img src="assets/images/team/3.jpg" alt="" />
                  <h4 className="fs-5 mt-3 fw-bolder mb-0">Pream Vikij</h4>
                  <span className="fs-8">CEO Shaw Analysis</span>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="teamc shadow-md text-center bg-white p-2">
                  <img src="assets/images/team/4.jpg" alt="" />
                  <h4 className="fs-5 mt-3 fw-bolder mb-0">Andrew Preson</h4>
                  <span className="fs-8">CEO Shaw Analysis</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*- ############ Footer Starts Here ################## */}
        <Container fluid className="my-5">
        <footer
          className="text-center text-lg-start text-dark"
          style={{ backgroundColor: "#ECEFF1" }}
        >
          <div>
            <a href="tel:++91 8884567518" class="callme_float">
              <FaPhone />
            </a>
            <a
              href="https://wa.me/918495998156"
              class="message_float"
              target="_blank"
            >
              <FaWhatsapp />
            </a>
          </div>
          {/* Section: Social media */}
          <section
            className="d-flex justify-content-between p-4 text-white"
            style={{ backgroundColor: "#5CD2E6" }}
          >
            {/* Left */}
            <div className="me-5">
              
              <span ><Link to="/terms" >Terms and Conditions,</Link></span>
              <span ><Link to="/privacy" >Privacy Policy,</Link></span>
              <span ><Link to="/termuse" >Terms of use,</Link></span>
              <span ><Link to="/return" >Return & Refund Policy</Link></span>
            </div>
            {/* Left */}
            {/* Right */}
            <div>
            {/* <span>Get connected with us on social networks:</span> */}

              <a href="https://www.facebook.com/ashvaexpert" className="text-white me-4">
                <FaFacebook />
              </a>
              <a href="/" className="text-white me-4">
                <FaTwitter />
              </a>
              <a href="https://ashvaexperts.com/" className="text-white me-4">
                <FaGoogle />
              </a>
              <a href="https://www.instagram.com/ashvaexperts" className="text-white me-4">
                <FaInstagram />
              </a>
              <a href="/" className="text-white me-4">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww" className="text-white me-4">
                <FaYoutube />
              </a>
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
          {/* Section: Links */}
          <section>
            <Container className="text-center text-md-start mt-5">
              <Row className="mt-3">
                {/* Grid column */}
                <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI,
                    HOBLI, BANGALORE, KARNATAKA, INDIA -560060.
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Office timings</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      8AM-8PM MON-SAT
                    </a>
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={3} lg={2} xl={2} className="mx-auto mb-4 mr-2">
                  <h6 className="text-uppercase fw-bold">Email</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      <small>ASHVAEXPERTS.TEAM@GMAIL.COM</small>
                    </a>
                  </p>
                </Col>

                <Col
                  md={4}
                  lg={3}
                  xl={3}
                  className="mx-auto mb-md-0 mb-4 phone-column mr-10"
                  style={{ marginRight: "50px" }}
                >
                  <h6 className="text-uppercase fw-bold">Phone</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>8495998156</p>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Section: Links */}
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2" }}
          >
            © 2023 Copyright:
            <a className="text-dark" href="https://www.walkinsoftwares.com/">
              Designed and Developed by Walkin Software Technologies
            </a>
          </div>
          {/* Copyright */}
        </footer>
        {/* Footer */}
      </Container>
      
    </React.Fragment>
  )
}

export default About