import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Dashborad from './pages/Dashborad';
import Products from './pages/Products';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import About from './pages/About';
import Plans from './pages/Plans';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Ashvaapp from './pages/Ashvaapp';
import HomeDash from './pages/HomeDash';
import RentalHistory from './pages/RentalHistory';
import ServiceHistory from './pages/ServiceHistory';
import Payments from './pages/Payments';
import Address from './pages/Address';
import Myprofile from './pages/profile/Myprofile';
import Notification from './pages/profile/Notification';
import OldProfile from './pages/OldProfile';
import Terms from './Policy/Terms';
import Privacy from './Policy/Privacy';
import Useage from './Policy/Useage';
import Return from './Policy/Return';
// import NewAddressForm from './pages/profile/NewAddressForm';


function App() {
  return (
    <div >
      <Router>
        <Routes>
          <Route path='/' element={<Dashborad/>}/>
          <Route path='/products' element={<Products/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/plan' element={<Plans/>}/>
          {/* <Route path='/blog' element={<Blog/>}/> */}
          <Route path='/about' element={<About/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/homedash' element={<HomeDash/>}/>
          <Route path='/rental' element={<RentalHistory/>}/>
          <Route path='/service' element={<ServiceHistory/>}/>
          <Route path='/payments' element={<Payments/>}/>
          <Route path='/address' element={<Address/>}/>
          <Route path='/myprofile' element={<Myprofile/>}/>
          <Route path='/notification' element={<Notification/>}/>
          <Route path='/oldprofile' element={<OldProfile/>}/>
          <Route path='/terms' element={<Terms/>}/>
          <Route path='/privacy' element={<Privacy/>}/>
          <Route path='/termuse' element={<Useage/>}/>
          <Route path='//return' element={<Return/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
