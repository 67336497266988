import React from 'react';
import { Link } from 'react-router-dom';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Card,
  Table,
  Col,
  Row,
} from "react-bootstrap";
import './Plans.css';
import logo from  '../images/ASHVA_LOGO_HEADER.png';
import{ FaPhone,FaWhatsapp,FaRegWindowClose} from 'react-icons/fa';
import{AiOutlineCheckCircle} from 'react-icons/ai';
  import image1 from "../images/ROUF-360x360.png";
import image2 from "../images/ROUVUF-360x360.png";
import image3 from "../images/ROUVUFALKALINE-360x360.png";
import img1 from "../images/watercan-1-148x150.png";
import img2 from "../images/regular purifier-2-148x150.png";
import img3 from "../images/otherrental-3-148x150.png";
import img4 from "../images/Ashva-rental-200x200.png";
import { useNavigate } from 'react-router-dom';
import {
  FaFacebook,
  FaTwitter,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";










const Plans = () => {

  const googlePlayUrl="https://play.google.com/store/apps";

  const nav=useNavigate();

  const login=(e)=>{
    e.preventDefault();
    nav("/login")
  }


  return (
    <React.Fragment>
      {/* ##################### navbar #################### */}
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand >
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link ><Link to='/'>Home</Link></Nav.Link>
              <Nav.Link ><Link to='/about'>About Us</Link></Nav.Link>
              <Nav.Link ><Link to='/products'>Products</Link></Nav.Link>
              <Nav.Link ><Link to='/plan'>Plans</Link></Nav.Link>
              {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
              <Nav.Link ><Link to='/contact'>Contact Us</Link></Nav.Link>
              <Nav.Link href={googlePlayUrl}>
               Google Playstore
              </Nav.Link>
            </Nav>
            {/* <Nav className="justify-content-end">
              <Button variant="primary" onClick={login}>Sign In</Button>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
  
        {/*  ************************* Page Title Starts Here ************************** */}
        <div className="page-nav no-margin row" style={{paddingTop:"0px"}}>
          <div className="container">
            <div className="row">
              <h2 className="text-start">Plans</h2>
              <ul>
                <li> <a href="#"><i className="bi bi-house-door" /> Home</a></li>
                <li><i className="bi bi-chevron-double-right pe-2" /> Plans</li>
              </ul>
            </div>
          </div>
        </div>

{/*- ############ Our Exclusive Packages ################## */}

        <div className="container-fluid products big-padding px-3 bg-gray">
          <div className="container-xl">
            <div className="section-title row">
              <h2 className="fs-1 fw-bold">Our Exclusive Packages</h2>
              
            </div>
            <div className="row">
              <div className="col-12 col-md-4 mb-4">
            
                  <div className="product bg-white p-4 text-center shadow-md">
                    <img className="mah-150" src={image1} alt="" />
                    <div className="d-inline-block">
                      <h4 className="fw-bolder fs-5 mt-4 ms-3 ">SMART RO+UF</h4>
                      <span className="fw-bolder fs-4"> &#8377;399/-</span> 
                    </div>
                    {/* <div className="d-inline-block mt-3">
                      <button className="btn btn-primary px-5" >Rent Now</button>
                    </div> */}
                  </div>
                
              </div>
              <div className="col-12 col-md-4 mb-4">
          
                  <div className="product bg-white p-4 text-center shadow-md">
                    <img className="mah-150" src={image2} alt="" />
                    <div className="d-inline-block">
                      <h4 className="fw-bolder fs-5 mt-4">SMART RO+UV+UF</h4>
                      <span className="fw-bolder fs-4">&#8377;499/-</span> 
                     
                    </div>
                    {/* <div className="d-inline-block mt-3">
                      <button className="btn btn-primary px-5" >Rent Now</button>
                    </div> */}
                  </div>
                
              </div>
              <div className="col-12 col-md-4 mb-4">
           
                  <div className="product bg-white p-4 text-center shadow-md">
                    <img className="mah-150" src={image3} alt="" />
                    <div className="d-inline-block">
                      <h4 className="fw-bolder fs-5 mt-4">SMART RO+UV+UF +ALKALINE</h4>
                      <span className="fw-bolder fs-4"> &#8377;599/-</span> 
                     
                    </div>
                    {/* <div className="d-inline-block mt-3">
                      <button className="btn btn-primary px-5" >Rent Now</button>
                    </div> */}
                  </div>
                
              </div>
            </div>
          </div>
        </div>

{/*- ############ three cards  ################## */}

<div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="card " style={{backgroundColor:"#04518c",marginBottom:"10px"}}>
                        <div class="card-body">
                            <h5 class="card-title text-light">Smart RO+UF</h5>
                            <br/>
                            <p class="card-text "><AiOutlineCheckCircle/><span style={{color:"white"}}>Monthly Rental:₹399</span></p>
                            <br/>
                            <button type="button" class="btn btn-warning al">Enquiry now</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card" style={{backgroundColor:"#04518c",marginBottom:"10px"}}>
                        <div class="card-body">
                        <div class="label">
                          POPULAR
                        </div>
                        <br/>
                            <h5 class="card-title text-light">SMART RO+UV+UF</h5>
                            <br/>
                            <p class="card-text"><AiOutlineCheckCircle/><span style={{color:"white"}}>Monthly Rental:₹499</span></p>
                            <br/>
                            <button type="button" class="btn btn-warning al">Enquiry now</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card" style={{backgroundColor:"#04518c",marginBottom:"10px"}}>
                        <div class="card-body">
                        <div class="label">
                          POPULAR
                        </div>
                        <br/>
                            <h5 class="card-title text-light">SMART RO+UV+ALKALINE</h5>
                            <br/>
                            <p class="card-text"><AiOutlineCheckCircle/><span style={{color:"white"}}>Monthly Rental:₹599</span></p>
                            <br/>
                            <button type="button" class="btn btn-warning al">Enquiry now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
{/*- ############ Choose the best... Ashva Experts! ################## */}

<div className="section-title row">
              <h2 className="fs-1 fw-bold">Choose the best... Ashva Experts!</h2>
              
            </div>

            <div className="container mb-4">
      <div className="row">
        <div className="col-md-3">
          <div className="card">
          <img src={img1} className="card-img-top" alt="placeholder" />
            <div className="card-body">
              <h5 className="card-title text-center">Water Can</h5>
              {/* <p className="card-text">Card content goes here</p> */}
              <table class="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td>Water Limit</td>
                    <td>Limited</td>
                  </tr>
                  <tr>
                    <td>Install Cost</td>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <td>Service Cost</td>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <td>Cost/litre</td>
                    <td>₹2 to ₹4</td>
                  </tr>
                  <tr>
                    <td>Water Quality</td>
                    <td>Average</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
          <img src={img2} className="card-img-top" alt="placeholder" />
            <div className="card-body">
              <h5 className="card-title text-center">Regular Purifiers</h5>
              {/* <p className="card-text">Card content goes here</p> */}
              <table class="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td>Water Limit</td>
                    <td>Limited</td>
                  </tr>
                  <tr>
                    <td>Install Cost</td>
                    <td>few ₹100s</td>
                  </tr>
                  <tr>
                    <td>Service Cost</td>
                    <td>few ₹1000s</td>
                  </tr>
                  <tr>
                    <td>Cost/litre</td>
                    <td>₹3 – ₹5</td>
                  </tr>
                  <tr>
                    <td>Water Quality</td>
                    <td>Good</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
          <img src={img3} className="card-img-top" alt="placeholder" />
            <div className="card-body">
              <h5 className="card-title text-center">Other Rentals</h5>
              <table class="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td>Water Limit</td>
                    <td>Limited</td>
                  </tr>
                  <tr>
                    <td>Install Cost</td>
                    <td>Zero</td>
                  </tr>
                  <tr>
                    <td>Service Cost</td>
                    <td>Zero</td>
                  </tr>
                  <tr>
                    <td>Cost/litre</td>
                    <td>₹1 – ₹10</td>
                  </tr>
                  <tr>
                    <td>Water Quality</td>
                    <td>Good</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
          <img src={img4} className="card-img-top" alt="placeholder" />
            <div className="card-body">
              <h5 className="card-title text-center">Ashva Experts</h5>
              {/* <p className="card-text">Card content goes here</p> */}
              <table class="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td>Water Limit</td>
                    <td>Unlimited</td>
                  </tr>
                  <tr>
                    <td>Install Cost</td>
                    <td>Zero</td>
                  </tr>
                  <tr>
                    <td>Service Cost</td>
                    <td>Zero</td>
                  </tr>
                  <tr>
                    <td>Cost/litre</td>
                    <td><small>(UnlimitedWater)</small></td>
                  </tr>
                  <tr>
                    <td>Water Quality</td>
                    <td>Good</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

        {/*- ############ Footer Starts Here ################## */}
       
        <Container fluid className="my-5">
        <footer
          className="text-center text-lg-start text-dark"
          style={{ backgroundColor: "#ECEFF1" }}
        >
          <div>
            <a href="tel:++91 8884567518" class="callme_float">
              <FaPhone />
            </a>
            <a
              href="https://wa.me/918495998156"
              class="message_float"
              target="_blank"
            >
              <FaWhatsapp />
            </a>
          </div>
          {/* Section: Social media */}
          <section
            className="d-flex justify-content-between p-4 text-white"
            style={{ backgroundColor: "#5CD2E6" }}
          >
            {/* Left */}
            <div className="me-5">
              
              <span ><Link to="/terms" >Terms and Conditions,</Link></span>
              <span ><Link to="/privacy" >Privacy Policy,</Link></span>
              <span ><Link to="/termuse" >Terms of use,</Link></span>
              <span ><Link to="/return" >Return & Refund Policy</Link></span>
            </div>
            {/* Left */}
            {/* Right */}
            <div>
            {/* <span>Get connected with us on social networks:</span> */}

              <a href="https://www.facebook.com/ashvaexpert" className="text-white me-4">
                <FaFacebook />
              </a>
              <a href="/" className="text-white me-4">
                <FaTwitter />
              </a>
              <a href="https://ashvaexperts.com/" className="text-white me-4">
                <FaGoogle />
              </a>
              <a href="https://www.instagram.com/ashvaexperts" className="text-white me-4">
                <FaInstagram />
              </a>
              <a href="/" className="text-white me-4">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww" className="text-white me-4">
                <FaYoutube />
              </a>
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
          {/* Section: Links */}
          <section>
            <Container className="text-center text-md-start mt-5">
              <Row className="mt-3">
                {/* Grid column */}
                <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI,
                    HOBLI, BANGALORE, KARNATAKA, INDIA -560060.
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Office timings</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      8AM-8PM MON-SAT
                    </a>
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={3} lg={2} xl={2} className="mx-auto mb-4 mr-2">
                  <h6 className="text-uppercase fw-bold">Email</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      <small>ASHVAEXPERTS.TEAM@GMAIL.COM</small>
                    </a>
                  </p>
                </Col>

                <Col
                  md={4}
                  lg={3}
                  xl={3}
                  className="mx-auto mb-md-0 mb-4 phone-column mr-10"
                  style={{ marginRight: "50px" }}
                >
                  <h6 className="text-uppercase fw-bold">Phone</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>8495998156</p>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Section: Links */}
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2" }}
          >
            © 2023 Copyright:
            <a className="text-dark" href="https://www.walkinsoftwares.com/">
              Designed and Developed by Walkin Software Technologies
            </a>
          </div>
          {/* Copyright */}
        </footer>
        {/* Footer */}
      </Container>
    
   
    </React.Fragment>
  )
}

export default Plans