import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Card,
  Table,
  Col,
  Row,
} from "react-bootstrap";
import logo from "../images/ASHVA_LOGO_HEADER.png";
import "./Contact.css";
import { FaPhone, FaWhatsapp, FaRegWindowClose } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import location from "../images/Bengaluru-Karnataka-Google-Maps.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaFacebook,
  FaTwitter,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";








const Contact = () => {
  const googlePlayUrl = "https://play.google.com/store/apps";
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const nav = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    // Form validation
    let errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.mobile) {
      errors.mobile = "Mobile number is required";
    } else if (!/^[0-9]+$/.test(formData.mobile)) {
      errors.mobile = "Mobile number is invalid";
    }
    if (!formData.message) {
      errors.message = "Message is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // POST request using Axios
    axios
      .post(
        "https://rowaterplant.cloudjiffy.net/PSANDSMSCUSTOMER/enquiry/v1/createNewInquiry",
        formData
      )
      .then((response) => {
        console.log(response);
        toast.success(" NewInquiry Successfully Created", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // alert("NewInquiry Successfully Created");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          message: "",
        });
        setErrors({
          name: "",
          email: "",
          mobile: "",
          message: "",
        });
      })
      .catch((error) => {
        console.log(error);
        alert("Error sending message");
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const login = (e) => {
    e.preventDefault();
    nav("/login");
  };

  return (
    <React.Fragment>
      {/*  ************************* navbar ************************** */}
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/about">About Us</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/products">Products</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/plan">Plans</Link>
              </Nav.Link>
              {/* <Nav.Link ><Link to='/blog'>Service</Link></Nav.Link> */}
              <Nav.Link>
                <Link to="/contact">Contact Us</Link>
              </Nav.Link>
              <Nav.Link href={googlePlayUrl}>Google Playstore</Nav.Link>
            </Nav>
            {/* <Nav className="justify-content-end">
              <Button variant="primary" onClick={login}>
                Sign In
              </Button>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/*  ************************* Page Title Starts Here ************************** */}
      <div className="page-nav no-margin row" style={{ paddingTop: "0px" }}>
        <div className="container">
          <div className="row">
            <h2 className="text-start"> Contact Us</h2>
            <ul>
              <li>
                {" "}
                <a href="#">
                  <i className="bi bi-house-door" /> Home
                </a>
              </li>
              <li>
                <i className="bi bi-chevron-double-right pe-2" /> Contact Us
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ##################### Our Products Starts Here #################### */}
      <div style={{ marginTop: "0px" }} className="row no-margin">
        {/* <iframe style={{width: '100%'}}  src="https://www.google.com/maps/embed?pb=!4v1681378988916!6m8!1m7!1spd09eHisg2uAvtA05nNckg!2m2!1d12.9010496921471!2d77.50925639773517!3f268.32!4f0!5f0.4000000000000002" height={450} frameBorder={0} allowFullScreen />
         */}
        <a href="https://www.google.com/maps/@12.9010897,77.5092545,0a,90y,268.32h,90t/data=!3m4!1e1!3m2!1spd09eHisg2uAvtA05nNckg!2e0">
          <img src={location} alt="" />
        </a>
      </div>
      <div className="row contact-rooo big-padding no-margin">
        <div className="container">
          <div className="row">
            <div style={{ padding: "20px" }} className="col-sm-7">
              <h2 className="fs-4 fw-bold">Contact Form</h2> <br />
              <div className="row cont-row">
                <div className="col-sm-3">
                  <label> Name </label>
                  <span>:</span>
                </div>
                <div className="col-sm-8">
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    className="form-control input-sm"
                    value={formData.name}
                    onChange={handleInputChange}
                  />

                  {errors.name && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.name}
                    </span>
                  )}
                </div>
              </div>
              <div className="row cont-row">
                <div className="col-sm-3">
                  <label>Email </label>
                  <span>:</span>
                </div>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter Email Address"
                    className="form-control input-sm"
                    value={formData.email}
                    onChange={handleInputChange}
                  />

                  {errors.email && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.email}
                    </span>
                  )}
                </div>
              </div>
              <div className="row cont-row">
                <div className="col-sm-3">
                  <label>Mobile Number</label>
                  <span>:</span>
                </div>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    className="form-control input-sm"
                    value={formData.mobile}
                    onChange={handleInputChange}
                  />

                  {errors.mobile && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.mobile}
                    </span>
                  )}
                </div>
              </div>
              <div className="row cont-row">
                <div className="col-sm-3">
                  <label> Message</label>
                  <span>:</span>
                </div>
                <div className="col-sm-8">
                  <textarea
                    rows={5}
                    placeholder="Enter Your Message"
                    className="form-control input-sm"
                    defaultValue={""}
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />

                  {errors.message && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.message}
                    </span>
                  )}
                </div>
              </div>
              <div style={{ marginTop: "10px" }} className="row">
                <div style={{ paddingTop: "10px" }} className="col-sm-3">
                  <label />
                </div>
                <div className="col-sm-8">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </button>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div style={{ margin: "50px" }} className="serv">
                <h2 className="fs-4 fw-bold" style={{ marginTop: "10px" }}>
                  Address
                </h2>
                220, 6TH CROSS, GANAKALLU,
                <br />
                SRINIVASAPURA COLONY KENGERI, HOBLI,
                <br />
                BANGALORE, KARNATAKA, INDIA -560060
                <br />
                Phone No:+91 9844338156
                <br />
                Email:ASHVAEXPERTS.TEAM@GMAIL.COM
                <br />
                Website:https://ashvaexperts.com/
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*- ############ Footer Starts Here ################## */}


      <Container fluid className="my-5">
        <footer
          className="text-center text-lg-start text-dark"
          style={{ backgroundColor: "#ECEFF1" }}
        >
          <div>
            <a href="tel:++91 8884567518" class="callme_float">
              <FaPhone />
            </a>
            <a
              href="https://wa.me/918495998156"
              class="message_float"
              target="_blank"
            >
              <FaWhatsapp />
            </a>
          </div>
          {/* Section: Social media */}
          <section
            className="d-flex justify-content-between p-4 text-white"
            style={{ backgroundColor: "#5CD2E6" }}
          >
            {/* Left */}
            <div className="me-5">
              
              <span ><Link to="/terms" >Terms and Conditions,</Link></span>
              <span ><Link to="/privacy" >Privacy Policy,</Link></span>
              <span ><Link to="/termuse" >Terms of use,</Link></span>
              <span ><Link to="/return" >Return & Refund Policy</Link></span>
            </div>
            {/* Left */}
            {/* Right */}
            <div>
            {/* <span>Get connected with us on social networks:</span> */}

              <a href="https://www.facebook.com/ashvaexpert" className="text-white me-4">
                <FaFacebook />
              </a>
              <a href="/" className="text-white me-4">
                <FaTwitter />
              </a>
              <a href="https://ashvaexperts.com/" className="text-white me-4">
                <FaGoogle />
              </a>
              <a href="https://www.instagram.com/ashvaexperts" className="text-white me-4">
                <FaInstagram />
              </a>
              <a href="/" className="text-white me-4">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww" className="text-white me-4">
                <FaYoutube />
              </a>
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
          {/* Section: Links */}
          <section>
            <Container className="text-center text-md-start mt-5">
              <Row className="mt-3">
                {/* Grid column */}
                <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI,
                    HOBLI, BANGALORE, KARNATAKA, INDIA -560060.
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Office timings</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      8AM-8PM MON-SAT
                    </a>
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={3} lg={2} xl={2} className="mx-auto mb-4 mr-2">
                  <h6 className="text-uppercase fw-bold">Email</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      <small>ASHVAEXPERTS.TEAM@GMAIL.COM</small>
                    </a>
                  </p>
                </Col>

                <Col
                  md={4}
                  lg={3}
                  xl={3}
                  className="mx-auto mb-md-0 mb-4 phone-column mr-10"
                  style={{ marginRight: "50px" }}
                >
                  <h6 className="text-uppercase fw-bold">Phone</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>8495998156</p>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Section: Links */}
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2" }}
          >
            © 2023 Copyright:
            <a className="text-dark" href="https://www.walkinsoftwares.com/">
              Designed and Developed by Walkin Software Technologies
            </a>
          </div>
          {/* Copyright */}
        </footer>
        {/* Footer */}
      </Container>


     



    </React.Fragment>
  );
};

export default Contact;
