import React from 'react';
import {
    Navbar,
    Nav,
    NavDropdown,
    Container,
    Button,
    Card,
    Table,
    Image,
    Col,
    Row,
  } from "react-bootstrap";
  import { Link, useNavigate } from "react-router-dom";
  import logo from "../images/ASHVA_LOGO_HEADER.png";
  import {
      FaFacebook,
      FaTwitter,
      FaGoogle,
      FaInstagram,
      FaLinkedin,
      FaYoutube,
    } from "react-icons/fa";
    import { FaPhone, FaWhatsapp, FaRegWindowClose } from "react-icons/fa";

const Useage = () => {

    const googlePlayUrl = "https://play.google.com/store/apps";

    const nav = useNavigate();
  
    const login = (e) => {
      e.preventDefault();
      nav("/login");
    };


  return (
    <>
    <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/about">About Us</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/products">Products</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/plan">Plans</Link>
              </Nav.Link>
             
              <Nav.Link>
                <Link to="/contact">Contact Us</Link>
              </Nav.Link>
              <Nav.Link href={googlePlayUrl}>Google Playstore</Nav.Link>
            </Nav>
          
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ backgroundColor: "#EBE3D5" }}>
        <p>
          <h4 style={{ color: "blueviolet", textAlign: "center" }}>
            TERMS OF USE
          </h4>
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>About Ashva Experts</h6>
        <p>
          Ashva Experts (&quot;Ashva Experts&quot;) is a company registered
          under the Companies Act, 1956. This website (&quot;Website&quot;) has
          been set up, and is operated and maintained by Ashva Experts to act as
          an electronic marketplace and an intermediary that provides an
          electronic venue for sale of goods and services (&quot;Products&quot;)
          by third party Vendors to the end customers. Ashva Experts may from
          time to time, also sell Products to end customers as a Vendor on this
          Website.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Important Terms</h6>
        <p>
          Please read this &quot;Terms of Use&quot; document carefully. This
          &quot;Terms of Use&quot; document is an electronic record in the form
          of an &quot;electronic contract&quot; in terms of the Information
          Technology Act, 2000 and the Rules thereunder as applicable and the
          amended provisions pertaining to electronic documents / records in
          various statutes as amended by the Information Technology Act, 2000,
          between Ashva Experts and the User of this Website. This electronic
          record is generated by a computer system and does not require any
          physical or digital signatures.
          <br />
          If You do not agree with the terms of this &quot;Terms of Use&quot;
          document please do not use this Website. By visiting this Website You
          unconditionally accept, and agree to be legally bound by the
          &quot;Terms of Use&quot; of the Website, read with the Privacy Policy
          (available here), Terms of Sale (available here) and / or any other
          document mentioned herein.
          <br />
          This document is published by Ashva Experts in accordance with the
          applicable provisions of the Information Technology Act, 2000 and
          Rules thereunder that require publishing of the Terms of Use for
          access or usage of a website. Your use of the Website and services and
          tools are governed by Terms of Use as applicable to the Website
          including the applicable policies which are incorporated herein by way
          of reference. If You transact on the Website, You shall be subject to
          the policies, including &quot;Terms Of Sale&quot; document that are
          applicable to the Website for such transaction. By mere use of the
          Website, You shall be contracting with Ashva Experts and these terms
          and conditions including the policies constitute Your binding
          obligations, with Ashva Experts. The terms &quot;We&quot; /
          &quot;Us&quot; / &quot;Our&quot; used in this Policy refer to Ashva
          Experts and the terms &quot;You&quot;/ &quot;Your&quot;/
          &quot;Yourself&quot; in this Policy refers to the users of the
          Website&quot;Users&quot; i.e. Third party Vendors of Products on the
          Website; Buyers of Products on the Website; or All persons who visit
          or access this Website, or avail any of its functionalities or use any
          data or information available on the Website in any manner. When You
          use any of the services provided by Us through the Website, including
          but not limited to, (e.g. Product Reviews, Seller Reviews), You will
          be subject to the rules, guidelines, policies, terms, and conditions
          applicable to such service, and they shall be deemed to be
          incorporated into this Terms of Use and shall be considered as part
          and parcel of this Terms of Use. We reserve the right, at Our sole
          discretion, to change, modify, add or remove portions of these Terms
          of Use, at any time without any prior written notice to You. It is
          Your responsibility to review these Terms of Use periodically for
          updates / changes. Your continued use of the Website following the
          posting of changes will mean that You accept and agree to the
          revisions. As long as You comply with these Terms of Use, We grant You
          a personal, non-exclusive, non-transferable, limited privilege to
          enter and use the Website. Accessing, browsing or otherwise using the
          site indicates your agreement to all the terms and conditions under
          these terms of use, so please read the terms of use carefully before
          proceeding. By implicitly or expressly accepting these Terms of Use,
          You also accept and agree to be bound by Ashva Experts Policies
          (including but not limited to Privacy Policy available on /s/privacy
          policy) as amended from time to time.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Services</h6>
        <p>
          The Website is an online platform in the form of an electronic
          marketplace and an intermediary that (a) provides a platform for Users
          (who are sellers) to advertise, exhibit, make available and offer to
          sell various Products to other Users (who are buyers/ customers), and
          (b) a platform for such other Users to accept the offer to sell of the
          Products made by the sellers on the Website and to make payments to
          the sellers for purchase of the Products, and (c) services to
          facilitate the engagement of buyers and sellers to undertake commerce
          on the Website, and (d) such other services as are incidental and
          ancillary thereto.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Membership Eligibility</h6>
        <p>
          Use of the Website is available only to persons who can form legally
          binding contracts under Indian Contract Act, 1872. Persons who are
          &quot;incompetent to contract&quot; within the meaning of the Indian
          Contract Act, 1872 including minors, undischarged insolvent etc. are
          not eligible to use the Website. If You are a minor i.e. under the age
          of 18 years, You shall not register as a User of the website and shall
          not transact on or use the website. As a minor if You wish to use or
          transact on a website, such use or transaction may be made by Your
          legal guardian or parents on the Website. Ashva Experts reserves the
          right to terminate Your membership and / or refuse to provide You with
          access to the Website if it is brought to Ashva Experts&#39;s notice
          or if it is discovered that You are under the age of 18 years.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Membership Eligibility</h6>
        <p>
          If You use the Website, You shall be responsible for maintaining the
          confidentiality of Your Display Name and Password and You shall be
          responsible for all activities that occur under Your Display Name and
          Password. You agree that if You provide any information that is
          untrue, inaccurate, not current or incomplete or We have reasonable
          grounds to suspect that such information is untrue, inaccurate, not
          current or incomplete, or not in accordance with this Terms of Use, We
          shall have the right to indefinitely suspend or terminate or block
          access of Your membership on the Website and refuse to provide You
          with access to the Website.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>
          Charges And Payment Related Information
        </h6>
        <p>
          Ashva Experts does not levy any fee for browsing the Website or buying
          the Products on the Website. Ashva Experts may charge Users certain
          fees for the use of specific services on the Website, or certain
          features of the Website. You agree to pay any such fees, as may be
          applicable to the services that You use. Ashva Experts will try to
          ensure that You are made aware of the applicability of any fees for a
          particular use of the Website, as well as the amount of fees payable
          by You for any such use of the Website. You agree that Ashva Experts
          may, at any time, charge, modify or waive fees required to use the
          Website. Your continued use of the Website after such change in the
          fees will be considered to be Your acceptance of such changes, and
          applicability of these Terms of Use to such change. You agree to
          provide correct and accurate financial information, such as
          credit/debit card details to the approved payment gateway or pre-paid
          payment instrument account details for availing Services on the
          Website. You shall not use the credit / debit card or pre-paid payment
          instrument which is not lawfully owned by You in any transaction, You
          must use Your own credit / debit card or pre-paid instrument account.
          The information provided by You will not be utilised or shared with
          any third party unless required in relation to fraud verifications or
          by law, regulation or court order or in accordance with the terms of
          the Privacy Policy. You will be solely responsible for the security
          and confidentiality of Your credit / debit card details or pre-paid
          instrument account. Ashva Experts expressly disclaims all liabilities
          that may arise as a consequence of any unauthorised use of Your
          credit/debit card or pre-paid instrument account. Ashva Experts may
          have existing arrangements with its banks, regarding limits on the
          amounts You can pay (if You are a buyer) or receive (if You are a
          seller) in the course of a single transaction. Ashva Experts will work
          towards ensuring that You are made aware of such limits if they may be
          applicable to You. However, Ashva Experts shall be under no liability
          whatsoever in respect of any loss or damage arising directly or
          indirectly out of the decline of authorization for any transaction, on
          account of You having exceeded the preset limit mutually agreed by
          Ashva Experts with its bank from time to time. Ashva Experts shall
          initiate the remittance of the payments made by You for Your purchase
          orders on the Website after the Products as delivered to You and the
          date of completion of transaction shall be after the Products are
          delivered to You and such other additional time as may be agreed
          between Ashva Experts and sellers.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Use of The Website</h6>
        <p>
          You agree, undertake and confirm that Your use of the Website shall be
          strictly governed by the following binding principles: You shall not
          host, display, upload, modify, publish, transmit, update or share any
          information which: belongs to another person and to which You do not
          have any right ; is grossly harmful, harassing, blasphemous,
          defamatory, obscene, pornographic, paedophilic, libellous, invasive of
          another’s privacy, hateful, or racially, ethnically objectionable,
          disparaging, relating or encouraging money laundering or gambling, or
          otherwise unlawful in any manner whatever; or unlawfully threatening
          or unlawfully harassing including but not limited to “indecent
          representation of women” within the meaning of the Indecent
          Representation of Women (Prohibition) Act, 1986; is misleading in any
          way; is patently offensive to the online community, such as sexually
          explicit content, or content that promotes obscenity, paedophilia,
          racism, bigotry, hatred or physical harm of any kind against any group
          or individual; harasses or advocates harassment of another person;
          involves the transmission of “junk mail”, “chain letters”, or
          unsolicited mass mailing or “spamming”; promotes illegal activities or
          conduct that is abusive, threatening, obscene, defamatory or
          libellous; infringes upon or violates any third party’s rights
          [including, but not limited to, intellectual property rights, rights
          of privacy (including without limitation unauthorised disclosure of a
          person’s name, email address, physical address or phone number) or
          rights of publicity]; promotes an illegal or unauthorised copy of
          another person’s copyrighted work (see “Copyright complaint” below for
          instructions on how to lodge a complaint about uploaded copyrighted
          material), such as providing pirated computer programs or links to
          them, providing information to circumvent manufacture-installed
          copy-protect devices, or providing pirated music or links to pirated
          music files; contains restricted or password-only access pages, or
          hidden pages or images (those not linked to or from another accessible
          page); provides material that exploits people in a sexual, violent or
          otherwise inappropriate manner or solicits personal information from
          anyone; provides instructional information about illegal activities
          such as making or buying illegal weapons, violating someone’s privacy,
          or providing or creating computer viruses; contains video,
          photographs, or images of another person (with a minor or an adult);
          tries to gain unauthorised access or exceeds the scope of authorised
          access to the Website or to profiles, blogs, communities, account
          information, bulletins, friend request, or other areas of the Website
          or solicits passwords or personal identifying information for
          commercial or unlawful purposes from other Users; engages in
          commercial activities and/or sales without Our prior written consent
          such as contests, sweepstakes, barter, advertising and pyramid
          schemes, or the buying or selling of “virtual” Products related to the
          Website. Throughout this Terms of Use, Ashva Experts’s prior written
          consent means a communication coming from Ashva Experts’s Legal
          Department, specifically in response to Your request, and specifically
          addressing the activity or conduct for which You seek authorization;
          solicits gambling or engages in any gambling activity which We, in Our
          sole discretion, believes is or could be construed as being illegal;
          interferes with another USER’s use and enjoyment of the Website or any
          other individual’s User and enjoyment of similar services; refers to
          any website or URL that, in Our sole discretion, contains material
          that is inappropriate for the Website or any other website, contains
          content that would be prohibited or violates the letter or spirit of
          these Terms of Use; harm minors in any way; infringes any patent,
          trademark, copyright or other proprietary rights or third party’s
          trade secrets or rights of publicity or privacy or shall not be
          fraudulent or involve the sale of counterfeit or stolen Products;
          violates any law for the time being in force; deceives or misleads the
          addressee/ Users about the origin of such messages or communicates any
          information which is grossly offensive or menacing in nature;
          impersonate another person; contains software viruses or any other
          computer code, files or programs designed to interrupt, destroy or
          limit the functionality of any computer resource; or contains any
          trojan horses, worms, time bombs, cancelbots, easter eggs or other
          computer programming routines that may damage, detrimentally interfere
          with, diminish value of, surreptitiously intercept or expropriate any
          system, data or personal information; threatens the unity, integrity,
          defence, security or sovereignty of India, friendly relations with
          foreign states, or public order or causes incitement to the commission
          of any cognizable offence or prevents investigation of any offence or
          is insulting any other nation; shall not be false, inaccurate or
          misleading; shall not, directly or indirectly, offer, attempt to
          offer, trade or attempt to trade in any item, the dealing of which is
          prohibited or restricted in any manner under the provisions of any
          applicable law, rule, regulation or guideline for the time being in
          force; shall not create liability for Us or cause Us to lose (in whole
          or in part) the services of Our internet service provider (“ISPs”) or
          other suppliers. You shall not use any “deep-link”, “page-scrape”,
          “robot”, “spider” or other automatic device, program, algorithm or
          methodology, or any similar or equivalent manual process, to access,
          acquire, copy or monitor any portion of the Website or any Content, or
          in any way reproduce or circumvent the navigational structure or
          presentation of the Website or any Content, to obtain or attempt to
          obtain any materials, documents or information through any means not
          purposely made available through the Website. We reserve Our right to
          bar any such activity. You shall not attempt to gain unauthorised
          access to any portion or feature of the Website, or any other systems
          or networks connected to the Website or to any server, computer,
          network, or to any of the services offered on or through the Website,
          by hacking, password “mining” or any other illegitimate means. You
          shall not probe, scan or test the vulnerability of the Website or any
          network connected to the Website nor breach the security or
          authentication measures on the Website or any network connected to the
          Website. You shall not reverse look-up, trace or seek to trace any
          information on any other User of or visitor to Website, or any other
          customer, including any account on the Website not owned by You, to
          its source, or exploit the Website or any service or information made
          available or offered by or through the Website, in any way where the
          purpose is to reveal any information, including but not limited to
          personal identification or information, other than Your own
          information, as provided for by the Website. You shall not make any
          negative, denigrating or defamatory statement(s) or comment(s) about
          Us or the brand name or domain name used by Us including the terms
          Ashva Experts, or otherwise engage in any conduct or action that might
          tarnish the image or reputation, of Ashva Experts or sellers on
          platform or otherwise tarnish or dilute any Ashva Experts’s trade or
          service marks, trade name and/or goodwill associated with such trade
          or service marks, trade name as may be owned or used by us. You agree
          that You will not take any action that imposes an unreasonable or
          disproportionately large load on the infrastructure of the Website or
          Ashva Experts’s systems or networks, or any systems or networks
          connected to Ashva Experts. You agree not to use any device, software
          or routine to interfere or attempt to interfere with the proper
          working of the Website or any transaction being conducted on the
          Website, or with any other person’s use of the Website. You agree not
          to use any device, software or routine to interfere or attempt to
          interfere with the proper working of the Website or any transaction
          being conducted on the Website, or with any other person’s use of the
          Website. You shall not use the Website or any content for any purpose
          that is unlawful or prohibited by these Terms of Use, or to solicit
          the performance of any illegal activity or other activity which
          infringes the rights of Ashva Experts and / or others. You shall at
          all times ensure full compliance with the applicable provisions of the
          Information Technology Act, 2000 and rules thereunder as applicable
          and as amended from time to time and also all applicable Domestic
          laws, rules and regulations (including the provisions of any
          applicable Exchange Control Laws or Regulations in Force) and
          International Laws, Foreign Exchange Laws, Statutes, Ordinances and
          Regulations (including, but not limited to Sales Tax/VAT, Income Tax,
          Octroi, Service Tax, Central Excise, Custom Duty, Local Levies)
          regarding Your use of Our service and Your listing, purchase,
          solicitation of offers to purchase, and sale of Products or services.
          You shall not engage in any transaction in an item or service, which
          is prohibited by the provisions of any applicable law including
          exchange control laws or regulations for the time being in force.
          Solely to enable Us to use the information You supply Us with, so that
          we are not violating any rights You might have in Your Information,
          You agree to grant Us a non-exclusive, worldwide, perpetual,
          irrevocable, royalty-free, sub-licensable (through multiple tiers)
          right to exercise the copyright, publicity, database rights or any
          other rights You have in Your Information, in any media now known or
          not currently known, with respect to Your Information. We will only
          use Your information in accordance with the Terms of Use and Privacy
          Policy applicable to use of the Website. It shall be a violation of
          these Terms of Use to use any information obtained from the Website in
          order to harass, abuse, or harm another person, or in order to
          contact, advertise to, solicit, or sell to another person other than
          Us without Our prior explicit consent. You understand that We have the
          right at all times to disclose any information (including the identity
          of the persons providing information or materials on the Website) as
          necessary to satisfy any law, regulation or valid governmental
          request. This may include, without limitation, disclosure of the
          information in connection with investigation of alleged illegal
          activity or solicitation of illegal activity or in response to a
          lawful court order or subpoena. In addition, We can (and You hereby
          expressly authorise Us to) disclose any information about You to law
          enforcement or other government officials, as we, in Our sole
          discretion, believe necessary or appropriate in connection with the
          investigation and/or resolution of possible crimes, especially those
          that may involve personal injury. We reserve the right, but have no
          obligation, to monitor the materials posted on the Website. Ashva
          Experts shall have the right to remove or edit any content that in its
          sole discretion violates, or is alleged to violate, any applicable law
          or either the spirit or letter of these Terms of Use. Notwithstanding
          this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE
          MATERIALS YOU POST ON THE WEBSITE AND IN YOUR PRIVATE MESSAGES. Please
          be advised that such Content posted does not necessarily reflect Ashva
          Experts views. In no event shall Ashva Experts assume or have any
          responsibility or liability for any content posted on the Website by
          the Users or for any claims, damages or losses resulting from use of
          such content and/or appearance of content on the Website. You hereby
          represent and warrant that You have all necessary rights in and to all
          content which You provide and all information it contains and that
          such content shall not infringe any proprietary or other rights of
          third parties or contain any libellous, tortious, or otherwise
          unlawful information. It is possible that other Users (including
          unauthorised Users or “hackers”) may post or transmit offensive or
          obscene materials on the Website and that You may be involuntarily
          exposed to such offensive and obscene materials. It also is possible
          for others to obtain personal information about You due to Your use of
          the Website, and that the recipient may use such information to harass
          or injure You. We do not approve of such unauthorised uses, but by
          using the Website You acknowledge and agree that We are not
          responsible for the use of any personal information that You publicly
          disclose or share with others on the Website. Please carefully select
          the type of information that You publicly disclose or share with
          others on the Website. Ashva Experts shall have all the rights to take
          necessary action and claim damages that may occur due to Your
          involvement/participation in any way on Your own or through group/s of
          people, intentionally or unintentionally in DoS/DDoS (Distributed
          Denial of Services).
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Intellectual Property Rights</h6>
        <p>
          The Website and the processes, and their selection and arrangement,
          including but not limited to all text, graphics, User interfaces,
          visual interfaces, sounds and music (if any), artwork and computer
          code (not including any content posted by any User or any Third Party
          Content) on the Website (collectively, &quot;the Content&quot;) is
          owned and controlled by Ashva Experts or its licensors and the design,
          structure, selection, coordination, expression, look and feel and
          arrangement of such Content is protected by copyright, patent and
          trademark laws, and various other intellectual property rights. No
          rights impliedly or expressly granted to You in respect of such
          Content through use of this Website. Ashva Experts reserves the right
          to change or modify the Content from time to time at its sole
          discretion. The trademark, logos and service marks displayed on the
          Website (&quot;Marks&quot;) are the property of Ashva Experts or their
          Vendors/Sellers or respective third parties. You are not permitted to
          use the Marks without the prior consent of Ashva Experts, the
          Vendor/Seller or the third party that may own the Marks. Unless
          otherwise indicated or anything contained to the contrary or any
          proprietary material owned by a third party and so expressly
          mentioned, Ashva Experts owns all intellectual property rights to and
          into the trademark &quot;Ashva Experts&quot;, and the Website,
          including, without limitation, any and all rights, title and interest
          in and to copyright, related rights, patents, utility models, designs,
          know-how, trade secrets and inventions (patent pending), goodwill,
          source code, meta tags, databases, text, content, graphics, icons, and
          hyperlinks. Except as expressly provided herein, You acknowledge and
          agree that You shall not copy, republish, post, display, translate,
          transmit, reproduce or distribute any Content through any medium
          without obtaining the necessary authorization from Ashva Experts or
          third party owner of such Content.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Third Party Content</h6>
        <p>
          General third party information such as, Product catalogues, Product
          description and specification, lists of dealers, reports on news,
          entertainment, technology and features, advertisements including
          videos, images and photographs of the Products, links to third party
          websites and other data from external sources is made available on the
          Website (&quot;Third Party Content&quot;). All Third Party Content is
          provided on an ’As Is&#39; basis. Ashva Experts may not own/ have the
          rights and tile to any such Third Party Content, or provide any
          guarantee with respect to the accuracy, title, merchantability,
          non-infringement or fitness for a particular purpose of any Third
          Party Content. Ashva Experts shall not be held liable for any loss
          suffered by You based on Your reliance on or use of such Third Party
          Content.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Disclaimer of Liability</h6>
        <p>
          Ashva Experts does not warrant that: This Website will be constantly
          available, or available at all times; or The information on this
          Website is complete, true, accurate or non-misleading. All the
          materials and Products (including but not limited to software) offered
          by the sellers thereof through this Website are provided on &quot;as
          is&quot; basis and Ashva Experts is not responsible for any defect or
          deficiency in quality or performance of the Products made available on
          the website. Ashva Experts will not be liable to You in any way or in
          relation to the Contents of, or use of, or otherwise in connection
          with, the Website. Ashva Experts does not warrant that this site;
          information, Content, materials, Product (including software) or
          services included on or otherwise made available to You through the
          Website; their servers; or electronic communication sent from Us are
          free of viruses or other harmful components. Nothing on the Website
          constitutes, or is meant to constitute, advice of any kind. All the
          Products sold on Website are governed by different state laws and if
          Seller is unable to deliver such Products due to implications of
          different state laws, Seller will return or will give credit for the
          amount (if any) received in advance by Seller from the sale of such
          Product that could not be delivered to You. You will be required to
          enter a valid phone number while placing an order on the Website. By
          registering Your phone number with us, You consent to be contacted by
          Us via phone calls and/or SMS notifications, in case of any order or
          shipment or delivery related updates. We will not use Your personal
          information to initiate any promotional phone calls or SMS.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Indemnity</h6>
        <p>
          You agree to indemnify and hold harmless Ashva Experts, its owner,
          licensees, affiliates, subsidiaries, group companies (as applicable)
          and their respective officers, directors, agents, and employees, from
          any claim or demand, or actions including reasonable attorneys&#39;
          fees, made by any third party or penalty imposed due to or arising out
          of Your breach of this Terms of Use, Privacy Policy and other
          Policies, or Your violation of any law, rules or regulations or the
          rights (including infringement of intellectual property rights) of a
          third party.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Limitation of Liability</h6>
        <p>
          IN NO EVENT SHALL Ashva Experts BE LIABLE FOR ANY SPECIAL, INCIDENTAL,
          INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE
          TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE
          POSSIBILITY OF SUCH DAMAGES.
        </p>{" "}
        <br />
        <h6 style={{ color: "blueviolet" }}>Governing Law and Jurisdiction</h6>
        <p>
          These Terms of Use and all transactions entered into on or through the
          Website and the relationship between You and Ashva Experts shall be
          governed in accordance with the laws of India. You agree that all
          claims, differences and disputes arising under or in connection with
          or in relation thereto the Website, these Terms of Use, the
          Agreement(s) or any transactions entered into on or through the
          Website or the relationship between You and Ashva Experts shall be
          subject to the exclusive jurisdiction of the courts at Delhi, India.
        </p>{" "}
        <br />
        <h6 style={{ color: "blueviolet" }}>Product Description</h6>
        <p>
          Ashva Experts does not warrant that Product description or other
          content of this Website is accurate, complete, reliable, current, or
          error-free and assumes no liability in this regard.
        </p>{" "}
        <br />
        <h6 style={{ color: "blueviolet" }}>Profanity Policy</h6>
        <p>
          In compliance with Information Technology Act, 2000 and rules made
          thereunder, if You find any discrepancies or have any grievances in
          relation to the collection, storage, use, disclosure and transfer of
          Your Personal Information, You may please contact Head Legal at
          support@ashvaexperts.com
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Grievance Officer</h6>
        <p>
          Ashva Experts prohibits the use of language that is racist, hateful,
          sexual or obscene in nature in a public area. If a feedback, comment
          or any communication made between Users on the Website; or email
          communication between Users in relation to transactions conducted on
          Website contains any profanity, please submit a request for action /
          removal of the same. Ashva Experts will consider the circumstances of
          an alleged policy violation and the User&#39;s trading records before
          taking action. Violations of this policy may result in a range of
          actions, including: Limits placed on account privileges; Loss of
          special status; Account suspension
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>
          Disputes Between Buyer and Seller
        </h6>
        <p>
          In case of a dispute where the Seller is unable to provide a refund or
          a replacement, Ashva Experts will proactively aid the parties towards
          reaching a resolution. The Buyer can write to Ashva Experts at
          wecare@Ashva Experts.in if any issue with the Seller is not resolved
          to the satisfaction of the Buyer. Ashva Experts&#39;s Customer Support
          team will look into the case and facilitate reaching a resolution.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Termination</h6>
        <p>
          This Agreement shall continue to apply until terminated by either You
          or Ashva Experts as set forth below. If You want to terminate Your
          agreement with Ashva Experts, You may do so (i) by not accessing the
          Website; or (ii) closing Your Account on the Website, where Ashva
          Experts has made this option available to You. Ashva Experts may, in
          its sole discretion and without prior notice, terminate Your access to
          the Website and block Your future access to the Website if Ashva
          Experts determines that You have violated the terms of these Terms of
          Use or any other policy or document mentioned in Clause 2.2 above. By
          use of this Website, You agree that any violation by You of the Terms
          of Use or the documents mentioned in Clause 2.2 above will cause
          irreparable harm to Ashva Experts, for which monetary damages shall
          not constitute adequate relief, and You consent to Ashva Experts
          obtaining any injunctive or equitable relief that Ashva Experts deems
          necessary or appropriate in such circumstances. These remedies are in
          addition to any other remedies that Ashva Experts may have at law or
          in equity. In addition to Clause 18.3 above, Ashva Experts may at any
          time, with or without notice, terminate these Terms of Use (or any
          portion thereof) with You if: Ashva Experts is required to do so by
          law or upon request by law enforcement or other government agencies;
          The provision of the services to You by Ashva Experts is in Ashva
          Experts&#39;s opinion, no longer commercially viable; Ashva Experts
          has elected to discontinue, with or without reason, access to the
          Website or any services provided through the Website; In the event
          Ashva Experts faces any expected technical issues or problems that
          prevent the Website from working. You agree that all terminations
          shall be made in Ashva Experts&#39;s sole discretion and that Ashva
          Experts shall not be liable to You or any third party for any
          termination of Your Account (and accompanying deletion of Your Account
          Information) or Your access to the Website or any services provided
          through the Website.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Notice</h6>
        <p>
          All notices with respect to these Terms of Use from Ashva Experts will
          be served to You by email or by general notification on the Website.
          Any notice provided to Ashva Experts pursuant to these Terms of Use
          should be sent to Grievance Officer at legal@sar-group.com.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>Feedback and Information</h6>
        <p>
          Any feedback You provide to this Website shall be deemed to be
          non-confidential. Ashva Experts shall be free to use such information
          on an unrestricted basis. Further, by submitting the feedback, You
          represent and warrant that Your feedback does not contain confidential
          or proprietary information of You or of third parties; Ashva Experts
          is not under any obligation of confidentiality, express or implied,
          with respect to the feedback; Ashva Experts may have something similar
          to the feedback already under consideration or in development; and You
          are not entitled to any compensation or reimbursement of any kind from
          Ashva Experts for the feedback under any circumstances. To clarify,
          Ashva Experts may at its discretion, make any modifications or changes
          to the Website, Content and /or Services on the basis of such
          feedback. However, Ashva Experts shall not be obliged to do so.
          Further, in the event that Ashva Experts makes any changes or
          modifications to the Website, Content and /or Services on the basis of
          any such feedback, You shall not have any rights or title (including
          any intellectual property rights) in such changes or modifications to
          the Website, Content and / or Services. You expressly waive any and
          all rights in such changes or modifications to the Website, Content
          and / or Services, and assign to Ashva Experts, all worldwide rights
          and title (including any intellectual property rights) to such changes
          or modifications to the Website, content and /or Services, in
          perpetuity. General Severability: If any provision of this Agreement
          is invalid, unenforceable or prohibited by law, this Agreement shall
          be considered divisible as to such provision and such provision shall
          be inoperative, and the remainder of this Agreement shall be valid,
          binding and of like effect as though such provision was not included
          herein. Ashva Experts will substitute for the invalid or unenforceable
          provision a valid and legally enforceable provision, which achieves to
          the greatest extent possible the economic, legal and commercial
          objectives of the provision, which is invalid or unenforceable.
          Waiver: No failure or delay by a party in exercising any right, power
          or remedy shall operate as a waiver thereof, nor shall any single or
          partial exercise of the same preclude any further exercise thereof or
          the exercise of any other right, power or remedy. Principal To
          Principal Relationship: You and Ashva Experts are independent parties,
          and nothing contained herein shall constitute or be deemed to
          constitute an agency or partnership or joint venture between the
          parties.
        </p>
        <br />
        <h6 style={{ color: "blueviolet" }}>DND T&amp;C*</h6>
        <p>
          We believe in bringing the best features, products and promotional
          offers for you from time to time. In order to make sure that you are
          aware of these offers and are availing their benefits, we prefer to
          reach out to you through personal communication via SMS and phone
          calls.
        </p>
      </div>
      <Container fluid className="my-5">
        <footer
          className="text-center text-lg-start text-dark"
          style={{ backgroundColor: "#ECEFF1" }}
        >
          <div>
            <a href="tel:++91 8884567518" class="callme_float">
              <FaPhone />
            </a>
            <a
              href="https://wa.me/918495998156"
              class="message_float"
              target="_blank"
            >
              <FaWhatsapp />
            </a>
          </div>
          {/* Section: Social media */}
          <section
            className="d-flex justify-content-between p-4 text-white"
            style={{ backgroundColor: "#5CD2E6" }}
          >
            {/* Left */}
            <div className="me-5">
              
              <span ><Link to="/terms" >Terms and Conditions,</Link></span>
              <span ><Link to="/privacy" >Privacy Policy,</Link></span>
              <span ><Link to="/termuse" >Terms of use,</Link></span>
              <span ><Link to="/return" >Return & Refund Policy</Link></span>
            </div>
            {/* Left */}
            {/* Right */}
            <div>
            {/* <span>Get connected with us on social networks:</span> */}

              <a href="https://www.facebook.com/ashvaexpert" className="text-white me-4">
                <FaFacebook />
              </a>
              <a href="/" className="text-white me-4">
                <FaTwitter />
              </a>
              <a href="https://ashvaexperts.com/" className="text-white me-4">
                <FaGoogle />
              </a>
              <a href="https://www.instagram.com/ashvaexperts" className="text-white me-4">
                <FaInstagram />
              </a>
              <a href="/" className="text-white me-4">
                <FaLinkedin />
              </a>
              <a href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww" className="text-white me-4">
                <FaYoutube />
              </a>
            </div>
            {/* Right */}
          </section>
          {/* Section: Social media */}
          {/* Section: Links */}
          <section>
            <Container className="text-center text-md-start mt-5">
              <Row className="mt-3">
                {/* Grid column */}
                <Col md={3} lg={4} xl={3} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    220, 6TH CROSS, GANAKALLU, SRINIVASAPURA COLONY KENGERI,
                    HOBLI, BANGALORE, KARNATAKA, INDIA -560060.
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={2} lg={2} xl={2} className="mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Office timings</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      8AM-8PM MON-SAT
                    </a>
                  </p>
                </Col>
                {/* Grid column */}
                {/* Grid column */}
                <Col md={3} lg={2} xl={2} className="mx-auto mb-4 mr-2">
                  <h6 className="text-uppercase fw-bold">Email</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <a href="#!" className="text-dark">
                      <small>ASHVAEXPERTS.TEAM@GMAIL.COM</small>
                    </a>
                  </p>
                </Col>

                <Col
                  md={4}
                  lg={3}
                  xl={3}
                  className="mx-auto mb-md-0 mb-4 phone-column mr-10"
                  style={{ marginRight: "50px" }}
                >
                  <h6 className="text-uppercase fw-bold">Phone</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>8495998156</p>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Section: Links */}
          {/* Copyright */}
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2" }}
          >
            © 2023 Copyright:
            <a className="text-dark" href="https://www.walkinsoftwares.com/">
              Designed and Developed by Walkin Software Technologies
            </a>
          </div>
          {/* Copyright */}
        </footer>
        {/* Footer */}
      </Container>
    </>
  )
}

export default Useage