import axios from "axios";

// Function to create an address
export const createAddress = async (formData, accessToken) => {
  try {
    // Include "customerDto" in the formData
    formData.customerDto = { customerId: 1 };

    const response = await axios.post(
      "https://virtullearning.cloudjiffy.net/ecommercecustomer/address/v1/createAddress",
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    throw error; // Throw an error if there's a problem
  }
};


//function to fetch the addresses customer Id
export const fetchAddressesByCustomerId = async (headers) => {
  return await axios({
  method:"GET",
  url:`https://virtullearning.cloudjiffy.net/ecommercecustomer/address/v1/getAddressesByCustomerId/{customerId}?customerId=1`,
  headers:headers
 })
};

//function to fetch the addresses by  Address ID 
export const getAddressById=async(addressId,headers)=>{
  return await axios({
    method: 'GET',
    url:`https://virtullearning.cloudjiffy.net/ecommercecustomer/address/v1/getAddressByAddressId/{addressId}?addressId=${addressId}`,
    headers:headers
  })
}


//update the form data
export const updateAddress = async (headers,updateddata) => {
  axios({
    method: 'PUT',
    url:"https://virtullearning.cloudjiffy.net/ecommercecustomer/address/v1/updateAddress",
    headers:headers,
    data:JSON.stringify(updateddata)
  }).then(function (res) {
    console.log(res);
  if (res.data.responseCode === 201) {
    alert(res.data.message);
  } else if (res.data.responseCode === 400) {
    alert(res.data.errorMessage);
  }
})
.catch(function (error) {
  console.log(error);
});
};


 //todo ==> DELETE  Address DATA
 export const deleteAddressById=(addressId,headers)=>{
  axios({
    method: 'DELETE',
    url:`https://virtullearning.cloudjiffy.net/ecommercecustomer/address/v1/deleteAddressByAddressId/${addressId}`,
    headers:headers
  }).then((res) => {
    if (res.data.responseCode === 200) {
      alert(res.data.message);
    } else if (res.data.responseCode === 400) {
      alert(res.data.errorMessage);
    }
  
  })
  .catch((err) => {
    console.log(err);
  });
 }