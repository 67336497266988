import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Card,
  Table,
  Row,
  Col,
  Image,
  Dropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/ASHVA_LOGO_HEADER.png";
import { FaPhone, FaWhatsapp, FaRegWindowClose, FaBell, FaUser } from "react-icons/fa";
import user from "../images/userimg.png";
import "./HomeDash.css";
import { BsFillPersonFill, BsGear, BsBoxArrowRight } from "react-icons/bs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import HomeDashForm from "./HomeDashForm";
import {AiFillHome} from "react-icons/ai"
import image1 from "../images/ROUF-360x360.png";
import image2 from "../images/ROUVUF-360x360.png";
import image3 from "../images/ROUVUFALKALINE-360x360.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const HomeDash = () => {
  // const googlePlayUrl = "https://play.google.com/store/apps";

  const nav = useNavigate();

  const handlenotification = () => {
    nav("/notification");
  };

  const handleoldprofile = () => {
    
    nav("/oldprofile");
    console.log("hi");
  };


  return (
    <>
      {/*- ############ Navbar Starts Here ################## */}
      <Navbar bg="info" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/homedash"><AiFillHome/></Link>
                
              </Nav.Link>
              {/* <Nav.Link>
                <Link to="/about">About Us</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/products">Products</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/plan">Contact Us</Link>
              </Nav.Link>
              <Nav.Link href={googlePlayUrl}>Google Playstore</Nav.Link> */}
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link>
                <FaBell onClick={handlenotification} />
              </Nav.Link>
              <Nav.Link>
                <FaUser onClick={handleoldprofile} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/*- ############ Breadcrumb Starts Here ################## */}
      <Breadcrumb>
        <Breadcrumb.Item active> Welcome to Dashboard!</Breadcrumb.Item>
      </Breadcrumb>

      {/*- ############ homedash carousel Starts Here ################## */}
      <Carousel
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={3000}
      transitionTime={500}
    >
      <div>
        <img src={image1} alt="Image 1" height={250} />
      </div>
      <div>
        <img src={image2} alt="Image 2" height={250}/>
      </div>
      <div>
        <img src={image3} alt="Image 3" height={250} />
      </div>
    </Carousel>
<br/>
{/*- ############ homedash products Starts Here ################## */}
<Container>
      <Row>
        <Col xs={12} md={4} className="mb-4">
          <div className="product bg-white p-4 text-center shadow-md">
            <img className="mah-150" src={image1} alt="" />
            <div className="d-inline-block">
              <h4 className="fw-bolder fs-5 mt-4 ms-3">SMART RO+UF</h4>
              <span className="fw-bolder fs-4">&#8377;399/-</span>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <div className="product bg-white p-4 text-center shadow-md">
            <img className="mah-150" src={image2} alt="" />
            <div className="d-inline-block">
              <h4 className="fw-bolder fs-5 mt-4">SMART RO+UV+UF</h4>
              <span className="fw-bolder fs-4">&#8377;499/-</span>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <div className="product bg-white p-4 text-center shadow-md">
            <img className="mah-150" src={image3} alt="" />
            <div className="d-inline-block">
              <h4 className="fw-bolder fs-5 mt-4">SMART RO+UV+UF+ALKALINE</h4>
              <span className="fw-bolder fs-4">&#8377;599/-</span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
      {/*- ############ Footer Starts Here ################## */}

      <footer>
        <div>
          <a href="tel:++91 8884567518" class="callme_float">
            <FaPhone />
          </a>
          <a
            href="https://wa.me/918495998156"
            class="message_float"
            target="_blank"
          >
            <FaWhatsapp />
          </a>
        </div>
        <div
          className="inner "
          style={{ backgroundColor: "#1E88E5", color: "#000000" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Contact Details
                </h6>
                <div>
                  <ul style={{ textAlign: "center" }}>
                    <li>220, 6TH CROSS, GANAKALLU,</li>
                    <li>SRINIVASAPURA COLONY KENGERI, HOBLI,</li>
                    <li>BANGALORE, KARNATAKA, INDIA -560060.</li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Phone</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>8495998156</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Email</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>
                      ASHVAEXPERTS.TEAM@GMAIL.COM
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Office timings
                </h6>
                <div>
                  <ul className="row">
                    <li style={{ textAlign: "center" }}>
                      OPEN 8AM-8PM:MONDAY - SATURDAY
                    </li>

                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <a
                        href="https://www.facebook.com/ashvaexpert"
                        style={{ color: "white" }}
                      >
                        <i className="bi bi-facebook" />
                      </a>
                    </li>
                    {/* <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-twitter" />
                    </li> */}
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <a
                        href="https://www.instagram.com/ashvaexperts/"
                        style={{ color: "white" }}
                      >
                        <i className="bi bi-instagram" />
                      </a>
                    </li>
                    {/* <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-linkedin" />
                    </li> */}
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <a
                        href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww"
                        style={{ color: "white" }}
                      >
                        <i className="bi bi-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy">
        <div className="container" style={{ textAlign: "center" }}>
          <a
            href="https://www.walkinsoftwares.com/"
            style={{ textDecorationLine: "none" }}
          >
            2022 © All Rights Reserved | Designed and Developed by Walkin
            Software Technologies
          </a>
        </div>
      </div>
    </>
  );
};

export default HomeDash;
