import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Card,
  Table,
  Row,
  Col,
  Image,
  Dropdown,
} from "react-bootstrap";
import { AiFillHome } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/ASHVA_LOGO_HEADER.png";
import { FaPhone, FaWhatsapp, FaRegWindowClose, FaBell } from "react-icons/fa";
import {
  FaUser,
  FaHistory,
  FaToolbox,
  FaMoneyBill,
  FaMapMarkerAlt,
  FaHome,
} from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import Myprofile from "./profile/Myprofile";
import RentalHistory from "./profile/RentalHistory";
import ServiceHistory from "./profile/ServiceHistory";
import PaymentHistory from "./profile/PaymentHistory";
import AddressHistory from "./profile/AddressHistory";
import { useEffect } from "react";
import axios from "axios";

const OldProfile = () => {
  // const googlePlayUrl = "https://play.google.com/store/apps";

  const [selectedSection, setSelectedSection] = useState("profile");
  const [userInfo, setUserInfo] = useState();
  const nav = useNavigate();




//!Tokens and Headers
const user = JSON.parse(sessionStorage.getItem("user"));
// console.log(user);
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + user.accessToken,
};





  const handlenotification = () => {
    nav("/notification");
  };
  const handleoldprofile = () => {
    // nav("/homedash");
    nav("/oldprofile");
    console.log("hi");
  };




//!to fetch user name 
const fetchUser=async()=>{
  return await axios({
    method:"GET",
    url:`https://virtullearning.cloudjiffy.net/ecommercecustomer/login/v1/queryMobileUserByUserName/${user.userName}`,
    headers,
    body: JSON.stringify(),
  }).then(function(res){
    console.log(res.data.userName)
    setUserInfo(res.data.userName)
  })
  .catch(function (error) {
    console.log(error);
  });
}


 //!USEEFFECT
 useEffect(() => {
  fetchUser();
  
}, []);







  const handleLogout = (e) => {
    e.preventDefault();
    //  logout logic here (e.g., clearing user session, cookies, etc.)

    // Redirect to the home page
    nav("/");

    sessionStorage.removeItem("user");

  };

  return (
    <>
      {/*- ############ Navbar Starts Here ################## */}
      <Navbar bg="info" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" width="200px" height="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to="/homedash">
                  <AiFillHome />
                </Link>
              </Nav.Link>
              {/* <Nav.Link>
                <Link to="/about">About Us</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/products">Products</Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/plan">Contact Us</Link>
              </Nav.Link>
              <Nav.Link href={googlePlayUrl}>Google Playstore</Nav.Link> */}
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link>
                <FaBell onClick={handlenotification} />
              </Nav.Link>
              <Nav.Link>
                <FaUser onClick={handleoldprofile} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
      {/*- ############ content of notification Starts Here ################## */}

      <Container>
        <Row>
          <Col md={4} className="mb-3 custom-card-col">
            {/* Left Sidebar */}
            <Card style={{ marginLeft: "20px", width: "300px" }}>
              <Card.Body>
                <div className="d-flex align-items-center">
                  <img
                    src="https://bootdey.com/img/Content/avatar/avatar7.png"
                    alt="Admin"
                    className="rounded-circle"
                    width="50"
                    style={{ marginRight: "10px" }}
                  />
                  <div>
                    <p style={{ margin: 0 }}>Hello</p>
                    <h5>{userInfo}</h5>
                  </div>
                </div>
                <hr />
                <Link onClick={() => setSelectedSection("homedash")}>
                  <h6>
                    <FaHome /> Dashboard
                  </h6>
                </Link>
                <hr />

                <Link onClick={() => setSelectedSection("profile")}>
                  <h6>
                    <FaUser /> My Profile
                  </h6>
                </Link>

                <hr />

                <div>
                  <Link onClick={() => setSelectedSection("rental")}>
                    <h6>
                      <FaHistory /> Rental History
                    </h6>
                  </Link>
                </div>

                <hr />

                <div>
                  <Link onClick={() => setSelectedSection("service")}>
                    <h6>
                      <FaToolbox /> Service History
                    </h6>
                  </Link>
                </div>

                <hr />

                <div>
                  <Link onClick={() => setSelectedSection("payments")}>
                    <h6>
                      <FaMoneyBill /> Payments
                    </h6>
                  </Link>
                </div>

                <hr />

                <div>
                  <Link onClick={() => setSelectedSection("address")}>
                    <h6>
                      <FaMapMarkerAlt /> My Address
                    </h6>
                  </Link>
                </div>

                <hr />

                <div>
                  <Link to="#logout" onClick={handleLogout}>
                    <h6>
                      <FiLogOut /> Logout
                    </h6>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={8} className="mb-3 custom-card-col custom-card2-col">
            {/* Right Content */}
            <Card style={{ marginRight: "1rem", marginLeft: "20px" }}>
              <Card.Body>
                {selectedSection === "profile" && <Myprofile />}
                {selectedSection === "rental" && <RentalHistory />}
                {selectedSection === "service" && <ServiceHistory />}
                {selectedSection === "payments" && <PaymentHistory />}
                {selectedSection === "address" && <AddressHistory />}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/*- ############ Footer Starts Here ################## */}

      <footer>
        <div>
          <a href="tel:++91 8884567518" class="callme_float">
            <FaPhone />
          </a>
          <a
            href="https://wa.me/918495998156"
            class="message_float"
            target="_blank"
          >
            <FaWhatsapp />
          </a>
        </div>
        <div
          className="inner "
          style={{ backgroundColor: "#1E88E5", color: "#000000" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Contact Details
                </h6>
                <div>
                  <ul style={{ textAlign: "center" }}>
                    <li>220, 6TH CROSS, GANAKALLU,</li>
                    <li>SRINIVASAPURA COLONY KENGERI, HOBLI,</li>
                    <li>BANGALORE, KARNATAKA, INDIA -560060.</li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Phone</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>8495998156</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>Email</h6>
                <div>
                  <ul>
                    <li style={{ textAlign: "center" }}>
                      ASHVAEXPERTS.TEAM@GMAIL.COM
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 foot-about" style={{ color: "#FFFFFF" }}>
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  Office timings
                </h6>
                <div>
                  <ul className="row">
                    <li style={{ textAlign: "center" }}>
                      OPEN 8AM-8PM:MONDAY - SATURDAY
                    </li>

                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <a
                        href="https://www.facebook.com/ashvaexpert"
                        style={{ color: "white" }}
                      >
                        <i className="bi bi-facebook" />
                      </a>
                    </li>
                    {/* <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-twitter" />
                    </li> */}
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <a
                        href="https://www.instagram.com/ashvaexperts/"
                        style={{ color: "white" }}
                      >
                        <i className="bi bi-instagram" />
                      </a>
                    </li>
                    {/* <li className="col-md-2" style={{ textAlign: "center" }}>
                      <i className="bi bi-linkedin" />
                    </li> */}
                    <li className="col-md-2" style={{ textAlign: "center" }}>
                      <a
                        href="https://www.youtube.com/channel/UCyBo3nzoM972B4McETFCHww"
                        style={{ color: "white" }}
                      >
                        <i className="bi bi-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy">
        <div className="container" style={{ textAlign: "center" }}>
          <a
            href="https://www.walkinsoftwares.com/"
            style={{ textDecorationLine: "none" }}
          >
            2022 © All Rights Reserved | Designed and Developed by Walkin
            Software Technologies
          </a>
        </div>
      </div>
    </>
  );
};

export default OldProfile;
